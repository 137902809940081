import React, { FC } from 'react'
import { TPopulatedStaking } from '../../../Constants/Types/staking'
import { useSortableData } from '../../../Utils/sorting'
import SortHeaderTable from '../../SortHeaderTable'
import ActivePoolsTableRow from './ActivePoolsTableRow'

import './styles.scss'

interface IProps {
  activePools: TPopulatedStaking[],
}

const ActivePools: FC<IProps> = ({ activePools }: IProps) => {
  const headers = [
    { name: 'title', label: 'Name', sort: false },
    { name: 'product.params.ticker', label: 'Ticker', sort: false },
    { name: 'params.mintedAmount', label: 'Minted nominal', class: 'minted-amount', sort: true },
    { name: 'id', label: 'Oracle', sort: false },
    { name: 'network', label: 'Chain', sort: false },
  ]

  const { items, requestSort, sortConfig } = useSortableData(activePools)

  return (
    <table className="transparent-table clickable-rows active-pools-table">
      <SortHeaderTable headers={headers} requestSort={requestSort} sortConfig={sortConfig} />

      <tbody>
        {items.map((activePool, index) => (
          <ActivePoolsTableRow key={index} activePool={activePool} />
        ))}
      </tbody>
    </table>
  )
}

export default ActivePools
