import { ETheme } from '@opiumteam/react-opium-components'
import React, { FC } from 'react'

type TProps = {}

const CashSecuredPutOption: FC<TProps> = () => {
  return (
    <>
      <div className={`KnowledgeBase__block-title bottom-line color-scheme-${ETheme.DARK}`}>
        Cash Secured Put
      </div>
      <div className="KnowledgeBase__block-content">
        Cash secured put is a combination of a short (sold) Put option, backed (collateralized) by cash (in crypto currencies “cash” is usually a stablecoin). By using this combined strategy investor receives an Option premium and is obliged to buy Underlying using his cash collateral at a Strike price (which is set to be significantly lower than current price). 
      </div>
      <div className="KnowledgeBase__block-content">
        Thus, it suits investors who are willing to buy Underlying when the market goes down below the Strike and want to enhance their investment returns by Premium received.
      </div>
      <div className="KnowledgeBase__block-content">
        This strategy is the basis for Put option selling Pools. 
      </div>
    </>
  )
}

export default CashSecuredPutOption
