import axios from 'axios'
import moment from 'moment'
import { ENetwork } from '@opiumteam/mobx-web3'
import {
  TAnalyticsChartPointResponse,
  TChartPoint,
  TProductChartData,
  TTokenAnalyticsChartResponse,
} from '../Constants/Types/product'

import EthLogo from '../Images/networks/eth-logo.svg'
import ArbitrumLogo from '../Images/networks/arbitrum.svg'
import PolygonIcon from '../Images/networks/polygon.svg'
import BInanceIcon from '../Images/networks/binance.svg'
import ethIcon from '../Images/ETH.svg'
import opiumIcon from '../Images/Opium.svg'
import usdtIcon from '../Images/USDT.svg'
import oneInchIcon from '../Images/1inch.svg'

import maticIcon from '../Images/matic-circle-icon.svg'
import aaveIcon from '../Images/aave-circle-icon.svg'
import bitcoinIcon from '../Images/bitcoin-circle-icon.svg'
import sushiIcon from '../Images/sushi-icon.svg'
import curveIcon from '../Images/curve-icon.svg'

export const fetchTheGraph = async (
  graphEndpoint: string,
  subgraphId: string,
  query: string
) => {
  const res: { data: any } = await axios({
    url: `${graphEndpoint}/${subgraphId}`,
    method: 'POST',
    data: {
      query,
    },
  })
  return res.data.data
}

export const formatDate = (date: number, format: string) => {
  return moment.unix(date).utc().format(format)
}

export const getScanLink = (network: ENetwork) => {
  let scanLink = 'https://etherscan.io'
  if (network === ENetwork.BINANCE) {
    scanLink = 'https://bscscan.com'
  }

  if (network === ENetwork.MATIC) {
    scanLink = 'https://polygonscan.com'
  }

  return scanLink
}

export const prepareProductChartData = (
  chartData: TChartPoint[]
): TProductChartData => {
  const preparedChartData = chartData.reduce(
    (acc, item) => {
      const mappingData = {
        data1: parseFloat((item.sellerPayout * 100).toFixed()),
        data2: parseFloat((item.buyerPayout * 100).toFixed()),
        price: item.price,
      }

      if (acc.minPrice === null || mappingData.price < acc.minPrice) {
        acc.minPrice = mappingData.price
      }
      if (acc.maxPrice === null || mappingData.price > acc.maxPrice) {
        acc.maxPrice = mappingData.price
      }
      if (acc.minData1 === null || mappingData.data1 < acc.minData1) {
        acc.minData1 = mappingData.data1
      }
      if (acc.maxData1 === null || mappingData.data1 > acc.maxData1) {
        acc.maxData1 = mappingData.data1
      }
      if (acc.minData2 === null || mappingData.data2 < acc.minData2) {
        acc.minData2 = mappingData.data2
      }
      if (acc.maxData2 === null || mappingData.data2 > acc.maxData2) {
        acc.maxData2 = mappingData.data2
      }

      acc.items.push(mappingData)

      return acc
    },
    {
      items: [] as TProductChartData['items'],
      minPrice: 0,
      maxPrice: 0,
      minData1: 0,
      maxData1: 0,
      minData2: 0,
      maxData2: 0,
    }
  )

  return preparedChartData
}

export const shortenAddress = (address: string) => {
  const start = address.slice(0, 6)
  const end = address.slice(address.length - 4, address.length)
  const result = start + '...' + end
  return result
}

export const convertDecimals = (number: number) => {
  if (number > 0 && number < 1) return Number(number.toFixed(2))
  return Number(number.toFixed())
}

export const prepareAnalyticsChartData = (
  chartData: TAnalyticsChartPointResponse[]
) => {
  return chartData.reduce(
    (acc: { [index: string]: any }, el) => {
      const mappingData = {
        label: el.timestamp * 1000,
        barData: parseFloat((el.performance * 100).toFixed(2)),
        lineData: parseFloat((el.linePerformance * 100).toFixed(2)),
      }

      if (acc.minBarData === null || mappingData.barData < acc.minBarData) {
        acc.minBarData = mappingData.barData
      }
      if (acc.maxBarData === null || mappingData.barData > acc.maxBarData) {
        acc.maxBarData = mappingData.barData
      }
      if (acc.minLineData === null || mappingData.lineData < acc.minLineData) {
        acc.minLineData = mappingData.lineData
      }
      if (acc.maxLineData === null || mappingData.lineData > acc.maxLineData) {
        acc.maxLineData = mappingData.lineData
      }

      acc.minYTotal = acc.minLineData
      if (acc.minBarData < acc.minLineData) {
        acc.minYTotal = acc.minBarData
      }

      acc.maxYTotal = acc.maxLineData
      if (acc.maxBarData > acc.maxLineData) {
        acc.maxYTotal = acc.maxBarData
      }

      acc.data.push(mappingData)
      return acc
    },
    {
      data: [],
      minBarData: null,
      maxBarData: null,
      minLineData: null,
      maxLineData: null,
      minYTotal: null,
      maxYTotal: null,
    }
  )
}

export const formatTokenName = (tokenName: string) => {
  switch (tokenName) {
    case 'ethereum':
      return 'ETH'
    case '1inch':
      return '1Inch'
    case 'matic':
      return 'MATIC'
    case 'usd-coin':
      return 'USDC'
    case 'opium':
      return 'Opium'
    default:
      return tokenName
  }
}

export const prepareTokenAnalyticsChartData = (
  tokenAnalyticsChartData: TTokenAnalyticsChartResponse
) => {
  const preparedChartData = tokenAnalyticsChartData.reduce(
    (acc, item) => {
      const mappingData = {
        data1: +item.tokenPrice,
        data2: +item.lpPrice,
        label: item.timestamp,
      }

      if (acc.minData1 === 0 || mappingData.data1 < acc.minData1) {
        acc.minData1 = mappingData.data1
      }
      if (acc.maxData1 === 0 || mappingData.data1 > acc.maxData1) {
        acc.maxData1 = mappingData.data1
      }
      if (acc.minData2 === 0 || mappingData.data2 < acc.minData2) {
        acc.minData2 = mappingData.data2
      }
      if (acc.maxData2 === 0 || mappingData.data2 > acc.maxData2) {
        acc.maxData2 = mappingData.data2
      }

      acc.minYTotal = acc.minData1
      if (acc.minData2 < acc.minData1) {
        acc.minYTotal = acc.minData2
      }

      acc.maxYTotal = acc.maxData1
      if (acc.maxData2 > acc.maxData1) {
        acc.maxYTotal = acc.maxData2
      }

      acc.items.push(mappingData)

      return acc
    },
    {
      items: [] as Array<{data1: number, data2: number, label: number}>,
      minPrice: 0,
      maxPrice: 0,
      minData1: 0,
      maxData1: 0,
      minData2: 0,
      maxData2: 0,
      minYTotal: 0,
      maxYTotal: 0
    }
  )

  return preparedChartData
}

export const networkLogos = (network?: string) => {
  switch (network) {
    case 'ethereum':
      return EthLogo
    case 'arbitrum':
      return ArbitrumLogo
    case 'Chainlink ETH/USD': 
      return ethIcon
    case 'Optimistic OPIUM/USD':
      return opiumIcon
    case 'Chainlink USDT/USDC': 
      return usdtIcon
    case 'Chainlink 1INCH/USD': 
      return oneInchIcon
    case 'polygon': 
      return PolygonIcon
    case 'bitcoin':
      return bitcoinIcon
    case 'sushi':
      return sushiIcon
    case 'crv':
      return curveIcon
    case '1INCH':
    case '1inch':
      return oneInchIcon
    case 'binance':
      return BInanceIcon
    default:
      return ethIcon
  }
}

export const camel2title = (camelCase: string) => {
  return camelCase.split(' ').map((el: string) => el.replace(/^./, (match) => match.toUpperCase())).join('')
}

export const renderOraclesIcon = (token: string) => {
  switch (token.toLowerCase()) {
    case 'matic-network':
      return maticIcon
    case 'tether':
    case 'usdt':
    case 'USDT':
      return usdtIcon
    case 'aave':
      return aaveIcon
    case 'bitcoin':
    case 'BTC':
      return bitcoinIcon
    case 'ethereum':
    case 'eth':
    case 'weth':
      return ethIcon
    case 'opium':
      return opiumIcon
    case '1inch':
      return oneInchIcon
    case 'sushi':
      return sushiIcon
    case 'curve-dao-token':
      return curveIcon
    default:
      return maticIcon
  }
}
