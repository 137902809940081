import web3Utils from 'web3-utils'
import { Decimal } from 'decimal.js'

export const convertToBN = (value: number, decimals: number): string => {
  const tenBN = web3Utils.toBN(10)
  // How current token's decimals differs from ether
  const baseDiff = 18 - decimals

  // More precisely handles .toFixed()
  const decimalValue = new Decimal(value)
  // Limit provided value by 18 decimals
  const stringValue = decimalValue.toFixed(18)
  const priceInWei = web3Utils.toBN(web3Utils.toWei(stringValue, 'ether'))
  let priceBN

  if (baseDiff >= 0) {
    priceBN = priceInWei.div(tenBN.pow(web3Utils.toBN(baseDiff)))
  } else {
    priceBN = priceInWei.mul(tenBN.pow(web3Utils.toBN(-baseDiff)))
  }
  return priceBN.toString()
}

export const convertFromBN = (value: string, decimals: number): string => {
  // How current token's decimals differs from ether
  const baseDiff = 18 - decimals

  const priceInEther = web3Utils.fromWei(value.toString(), 'ether')
  let priceNormal

  if (baseDiff >= 0) {
    priceNormal = +priceInEther * Math.pow(10, baseDiff)
  } else {
    priceNormal = +priceInEther / Math.pow(10, baseDiff)
  }
  return priceNormal.toString()
}
