import { ETheme } from '@opiumteam/react-opium-components'
import React, { FC } from 'react'
import { IStrategyDescription } from '../../../../Constants/Types/constructor' 
import { splitWords } from '../../Helpers/helpers'
import './styles.scss'

const StrategyDescription: FC<IStrategyDescription> = ({ header, positionClass, positionText, children }) => {

  const { firstWord, secondWord, lastWords } = splitWords(header)

  return (
    <div className="constructor-data-strategy ConstructorPage__right-block">
      <div className={`constructor-data-strategy-title block-title color-scheme-${ETheme.DARK}`}>
        {firstWord}{'\u00A0'}<span className="text-uppercase">{secondWord}{'\u00A0'}</span>{lastWords}
      </div>
      <div className={`${positionClass} constructor-data-strategy-position`}>
        {positionText}
      </div>
      <div className="constructor-data-strategy-content">
        {children}
      </div>
    </div>
  )
}

export default StrategyDescription
