import { ETheme } from '@opiumteam/react-opium-components'
import React, { FC } from 'react'

type TProps = {}

const Straddle: FC<TProps> = () => {
  return (
    <>
      <div className={`KnowledgeBase__block-title bottom-line color-scheme-${ETheme.DARK}`}>
        Straddle
      </div>
      <div className="KnowledgeBase__block-content">
        Long straddle is a combination of long Put option and long Call option with the same Strike. Investor has to pay premium for both Options but will gain from price movements of the Underlying irrespective of direction (up or down). This strategy is used to gain exposure to volatility of the Underlying.
      </div>
      <div className="KnowledgeBase__block-content">
        Short straddle is a combination of short Put option and short Call option with the same Strike. Investor receives premium for both Options, but any price movements of the Underlying irrespective of direction (up or down) will reduce his payoff. This strategy is used for yield enhancement in a situation when investor is ready to bet on stability of the Underlying.
      </div>

    </>
  )
}

export default Straddle
