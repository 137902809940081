export const shortenDerivativeHash = (hash: string, startQuantity: number, endQuantity: number) => {
  if (hash.length <= 12) return hash
  
  const start = hash.slice(0, startQuantity)
  const end = hash.slice(hash.length - endQuantity, hash.length)
  const result = start + '...' + end
  return result
}

export const splitWords = (str: string) => {
  const arrWords = str && str.split(' ')
  const firstWord = arrWords[0]
  const secondWord = arrWords[1]
  let lastWords = ''
  for (let i = 2; i < arrWords.length; i++) {
    lastWords = lastWords + ' ' + arrWords[i]
  }
  return { firstWord, secondWord, lastWords }
}

export const onCopyClick = (text: string) => {
  navigator.clipboard.writeText(text)
}

export const prepareIdentifierText = (s: string) => {
  const str: string = s.split('_').join(' ')
  return str[0].toUpperCase() + str.substring(1).toLowerCase()
}
