import { observable, action, computed } from 'mobx'
import { ENetwork } from '@opiumteam/mobx-web3'
import moment from 'moment'

import deribitApi from '../Services/deribitApi'
import opiumApi from '../Services/opiumApi'
import coingeckoApi from '../Services/coingeckoApi'

import { TVolumesChartDataItem, TOpiumVolume } from '../Constants/Types/volumes'
import { TStakingResponse, TPopulatedStaking, EStakingType } from '../Constants/Types/staking'
import {
  TProduct,
  EPeer2PoolInsuranceProductParamsSubtype,
  TProductChartData,
  TTokenAnalyticsChartResponse,
} from '../Constants/Types/product'
import { ELoadingStatuses } from '../Constants/Types'
import { EAvgCostFrequency } from '../Constants/Types/component'

import {
  prepareProductChartData,
  prepareAnalyticsChartData,
  prepareTokenAnalyticsChartData
} from '../Utils/helpers'

export class VolumesStore {
  @observable volumes: {
    dailyData: TVolumesChartDataItem[]
    weeklyData: TVolumesChartDataItem[]
    monthlyData: TVolumesChartDataItem[]
  } = {
    dailyData: [],
    weeklyData: [],
    monthlyData: [],
  }

  @observable volumesLoading: ELoadingStatuses = ELoadingStatuses.IDLE

  @observable opiumVolumes: TOpiumVolume[] = []

  @observable opiumVolumesLoading: ELoadingStatuses = ELoadingStatuses.IDLE

  @observable populatedStakings: TPopulatedStaking[] = []

  @observable populatedStakingsLoading: ELoadingStatuses = ELoadingStatuses.IDLE

  @observable productChart: {
    id: string
    data: TProductChartData | null
  } = {
    id: '',
    data: null,
  }

  @observable productChartLoading: ELoadingStatuses = ELoadingStatuses.IDLE

  @observable pastPerfomanceChart: {
    data: any
    minYTotal: number
    maxYTotal: number
  } = {
    data: [],
    minYTotal: 0,
    maxYTotal: 0,
  }

  @observable pastPerfomanceChartLoading: ELoadingStatuses = ELoadingStatuses.IDLE

  @observable riskDislosureChart = {} // TODO: typing

  @observable riskDislosureChartLoading: ELoadingStatuses = ELoadingStatuses.IDLE

  @observable tokenAnalyticsChartData: {
    items: Array<{data1: number, data2: number, label: number}>,
    minData1: number,
    maxData1: number,
    minData2: number,
    maxData2: number,
    minYTotal: number,
    maxYTotal: number,
  } = {
    items: [],
    minData1: 0,
    maxData1: 0,
    minData2: 0,
    maxData2: 0, 
    minYTotal: 0,
    maxYTotal: 0
  }

  @observable tokenAnalyticsChartDataLoading = ELoadingStatuses.IDLE

  @action getVolumes = async (): Promise<void> => {
    if (
      this.volumesLoading === ELoadingStatuses.PENDING ||
      this.volumesLoading === ELoadingStatuses.SUCCEEDED
    ) {
      return
    }

    try {
      this.volumesLoading = ELoadingStatuses.PENDING
      const volumes = await deribitApi.getTradeVolumes()

      this.volumesLoading = ELoadingStatuses.SUCCEEDED
      this.volumes = {
        dailyData: volumes.chartDataDaily as any,
        weeklyData: volumes.chartDataWeekly as any,
        monthlyData: volumes.chartDataMonthly as any,
      }
    } catch (err) {
      console.error(err)
      this.volumesLoading = ELoadingStatuses.FAILED
    }
  }

  @action getOpiumVolumes = async (): Promise<void> => {
    if (this.opiumVolumesLoading === ELoadingStatuses.SUCCEEDED) {
      return
    }


    try {
      this.opiumVolumesLoading = ELoadingStatuses.PENDING

      this.opiumVolumes = await opiumApi.getOpiumVolumes()

      if (this.opiumVolumes.length) {
        this.opiumVolumesLoading = ELoadingStatuses.SUCCEEDED
      }
    } catch (err) {
      console.error(err)
      this.opiumVolumesLoading = ELoadingStatuses.FAILED
    }
  }

  @action getPopulatedStakings = async (): Promise<void> => {
    if (
      this.populatedStakingsLoading === ELoadingStatuses.PENDING ||
      this.populatedStakingsLoading === ELoadingStatuses.SUCCEEDED
    ) {
      return
    }

    this.populatedStakingsLoading = ELoadingStatuses.PENDING
    const stakings: TStakingResponse[] = await opiumApi.getStakings()
    const products: TProduct[] = await opiumApi.getProducts()

    const populatedStakings: TPopulatedStaking[] = []

    stakings.forEach((staking) => {
      const product: TProduct | undefined = products.find(
        (product) => product.id === staking.productId
      )

      if (product) {
        populatedStakings.push({ ...staking, product })
      }
    })

    this.populatedStakingsLoading = ELoadingStatuses.SUCCEEDED
    this.populatedStakings = populatedStakings as any
  }

  private _filterAndSortPopulatedStakings = (
    stakings: TPopulatedStaking[],
    isCoveredCalls: boolean = false
  ): TPopulatedStaking[] => {
    return stakings
      .filter((staking: TPopulatedStaking) => {
        if (staking.isSuspended) return false

        switch (staking.type) {
          case EStakingType.PEER_2_POOL_POOLED:
          case EStakingType.PEER_2_POOL_POOLED_V2:
            switch (staking.product.params.subtype) {
              case EPeer2PoolInsuranceProductParamsSubtype.OPTION_CALL:
              case EPeer2PoolInsuranceProductParamsSubtype.OPTION_CALL_REAL:
              case EPeer2PoolInsuranceProductParamsSubtype.OPTION_PUT_REAL:
                return true
              case EPeer2PoolInsuranceProductParamsSubtype.STABLECOIN:
                return isCoveredCalls
              default:
                return false
            }
          default:
            return false
        }
      })
      .sort(
        (a: TPopulatedStaking, b: TPopulatedStaking) =>
          b.params.mintedAmount - a.params.mintedAmount
      )
  }

  @computed get activePools(): TPopulatedStaking[] {
    return this._filterAndSortPopulatedStakings(this.populatedStakings)
  }

  private _convertAvgCost = (avgCost: number, avgCostFrequency: EAvgCostFrequency | null, collateralization: number) => {
    let pow = 1

    switch (avgCostFrequency) {
      case EAvgCostFrequency.ANNUAL:
        pow = 1
        break
      case EAvgCostFrequency.DAILY:
        pow = 365
        break
      case EAvgCostFrequency.MONTHLY:
        pow = 12
        break
      case EAvgCostFrequency.PER_3_DAYS:
        pow = 120
        break
      case EAvgCostFrequency.WEEKLY:
        pow = 52
        break
    }

    return (((1 + (avgCost / 100 / collateralization)) ** pow - 1) * 100)

  }

  private _sortByReturn = (a: TPopulatedStaking, b: TPopulatedStaking) => {
    const aPeerPoolParams = a.params
    const bPeerPoolParams = b.params
    return (
      (bPeerPoolParams.yieldToDateAnnualized ||
        this._convertAvgCost(b.product.avgCost, b.product.avgCostFrequency, b.product.params.collateralization)) -
      (aPeerPoolParams.yieldToDateAnnualized ||
        this._convertAvgCost(a.product.avgCost, a.product.avgCostFrequency, a.product.params.collateralization))
    )
  }

  @computed get coveredCalls(): TPopulatedStaking[] {
    return this._filterAndSortPopulatedStakings(this.populatedStakings, true).sort(
      this._sortByReturn
    )
  }

  @action getProductChartData = async (productId: string, network: ENetwork): Promise<void> => {
    if (productId === this.productChart.id) return

    try {
      this.productChartLoading = ELoadingStatuses.PENDING
      const chartData = await opiumApi.getProductChartDataById(productId, network)

      this.productChart = {
        id: productId,
        data: prepareProductChartData(chartData) as any,
      }
      this.productChartLoading = ELoadingStatuses.SUCCEEDED
    } catch (err) {
      this.productChartLoading = ELoadingStatuses.FAILED
    }
  }

  @action getPastPerfomanceChartData = async (poolAddress: string, network: ENetwork): Promise<void> => {
    if (this.pastPerfomanceChartLoading === ELoadingStatuses.PENDING) return

    this.pastPerfomanceChartLoading = ELoadingStatuses.PENDING

    try {
      const chartData = await opiumApi.getAnalyticsChart(poolAddress, network)
      this.pastPerfomanceChart = prepareAnalyticsChartData(chartData) as any
      this.pastPerfomanceChartLoading = ELoadingStatuses.SUCCEEDED
    } catch (err) {
      this.pastPerfomanceChartLoading = ELoadingStatuses.FAILED
    }
  }

  @action getTokenAnalyticsChartData = async (
    poolAddress: string,
    marginTitle: string,
    network: ENetwork
  ): Promise<void> => {
    if (this.tokenAnalyticsChartDataLoading === ELoadingStatuses.PENDING) return

    try {
      const data = await opiumApi.getTokenAnalytics(poolAddress, marginTitle, network)
      this.tokenAnalyticsChartData = prepareTokenAnalyticsChartData(data)
      this.tokenAnalyticsChartDataLoading = ELoadingStatuses.SUCCEEDED
    } catch (err) {
      this.tokenAnalyticsChartDataLoading = ELoadingStatuses.FAILED
    }
  }
}

export default new VolumesStore()
