import axios, { AxiosInstance } from 'axios'
import config from '../Constants/Config/opiumApi'
import { TAuctionsResponse } from '../Constants/Types/auctions'

const opiumWatchEndpoint = `${config.opiumWatchEndpoint}`

export class auctionApi {
  private readonly _opiumWatchClient: AxiosInstance

  public constructor() {
    this._opiumWatchClient = axios.create({
      baseURL: opiumWatchEndpoint,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  getAuctionsData = async (): Promise<TAuctionsResponse> => {
    const response = await this._opiumWatchClient.get('/auctions?network=ARBITRUM')
    return response.data
  }
}

export default new auctionApi()
