import React, { useState, FC } from 'react'
import moment from 'moment'
import { Tooltip, ETheme, Popup } from '@opiumteam/react-opium-components'

import { TPopulatedStaking } from '../../../Constants/Types/staking'
import { EPeer2PoolInsuranceProductParamsSubtype } from '../../../Constants/Types/product'
import { convertAvgCost, getPoolPeriod } from '../../../Utils/staking'
import CoveredCallPopupContent from './CoveredCallPopupContent'

import InfoLogo from '../../../Images/Info.svg'

interface IProps {
  coveredCall: TPopulatedStaking,
}

const CoveredCallTableRow: FC<IProps> = ({ coveredCall }: IProps) => {
  const {
    title,
    params: {
      currentEpochTimestamp,
      yieldToDate,
      yieldToDateAnnualized,
      epochLength,
    },
    product: {
      avgCost,
      avgCostFrequency,
      params: {
        ticker,
        underlyingTitle,
        subtype,
        inverseTrigger,
        collateralization
      }
    }
  } = coveredCall

  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const togglePopup = () => setIsPopupOpen(!isPopupOpen)

  const isOptionPutReal = subtype === EPeer2PoolInsuranceProductParamsSubtype.OPTION_PUT_REAL
  const isStableCoin = subtype === EPeer2PoolInsuranceProductParamsSubtype.STABLECOIN

  const poolPeriod = getPoolPeriod(epochLength)
  const subtitle = `${poolPeriod}, ${isStableCoin ? 'Protection' : isOptionPutReal ? 'Outright Put' : 'Covered call'}`

  const maturity = moment(currentEpochTimestamp * 1000).format('DD MMM YY')

  const benchmarkReturn = convertAvgCost(avgCost, avgCostFrequency, collateralization)

  const renderAprTooltip = (apr: string) => (
    <div>
      <div>Annualized return based on current performance. Return since inception is {apr}%</div>
      <br />
      <div className="text-bold">Past performance does not guarantee future results</div>
    </div>
  )

  const projectedApr = (
    <div>
      {yieldToDate === 0 ? benchmarkReturn : `${yieldToDateAnnualized.toFixed(1)}%`}
      <Tooltip
        theme={ETheme.DARK}
        label={'!'}
        trigger='hover'
        placement='bottom'
        content={renderAprTooltip(yieldToDate === 0 ? avgCost.toFixed(1) : yieldToDate.toFixed(1))}
        component={(
          <img
            style={{ marginLeft: '0.5rem', marginBottom: '-2px', cursor: 'pointer' }}
            src={InfoLogo} 
          />
        )}
      />
    </div>
  )

  const components = [
    `Sell ${ticker}`,
    `Hold ${underlyingTitle}`
  ]

  const profitSign = (isOptionPutReal || isStableCoin) ? '<' : '>'
  const lossSign = (isOptionPutReal || isStableCoin) ? '>' : '<'

  const profitLoss = [
    `${underlyingTitle} ${profitSign} $${
      isStableCoin ? (1-inverseTrigger).toFixed(2) : inverseTrigger.toFixed(2)
    }`,
    `${underlyingTitle} ${lossSign} $${
      isStableCoin ? (1-inverseTrigger).toFixed(2) : inverseTrigger.toFixed(2)
    }`,
  ]

  const risk = `${
    subtype === EPeer2PoolInsuranceProductParamsSubtype.OPTION_PUT_REAL ? 'Buying' : 'Selling'
  } ${
    underlyingTitle
  } ${isStableCoin ? 'bellow' : 'at'} $${
    isStableCoin
      ? (1 - inverseTrigger).toFixed(2)
      : inverseTrigger.toFixed(2)
  } on ${
    maturity
  }`

  return (
    <>
      <tr onClick={togglePopup}>
        <td>
          <div className="title">{title}</div>
          <div className="maturity nowrap">{subtitle}</div>
        </td>

        <td>
          <div className="nowrap dotted">&#9679; {components[0]}</div>
          <div className="nowrap dotted">&#9679; {components[1]}</div>
        </td>

        <td>
          <div className="nowrap">Profit: {profitLoss[1]}</div>
          <div className="nowrap">Loss: {profitLoss[0]}</div>
        </td>

        <td className="apr nowrap">{projectedApr}</td>

        <td className='risk'>
          <div>{risk}</div>
        </td>
      </tr>

      <Popup
        theme={ETheme.DARK}
        title={title}
        subtitle={subtitle}
        component={<CoveredCallPopupContent coveredCall={coveredCall} />}
        closePopup={togglePopup}
        popupIsOpen={isPopupOpen}
        className="covered-call-popup"
        size="xl"
      />
    </>
  )
}

export default CoveredCallTableRow
