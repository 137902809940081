import { ENetwork } from '@opiumteam/mobx-web3'
import { EAvgCostFrequency } from './component'

export enum EChartEnum {
  ANALYTICS_CHART = 'ANALYTICS_CHART',
  PNL_CHART = 'PNL_CHART',
}

export type TChartLabels = {
  title: string
  xLabel: string
  yLabel: string
}

export enum EProductType {
  PEER_2_POOL_INSURANCE = 'PEER_2_POOL_INSURANCE',
  PEER_2_POOL_INSURANCE_V2 = 'PEER_2_POOL_INSURANCE_V2',
  STAKING_POOL = 'STAKING_POOL',
  STAKING_POOL_FARMING = 'STAKING_POOL_FARMING',
  GOVERNANCE_WRAPPER = 'GOVERNANCE_WRAPPER',
  PEER_2_POOL_COMPOUND_FIXED_RATE = 'PEER_2_POOL_COMPOUND_FIXED_RATE',
}

export enum EPeer2PoolInsuranceProductParamsSubtype {
  STABLECOIN = 'STABLECOIN',
  SMART_CONTRACT = 'SMART_CONTRACT',
  OPTION_CALL = 'OPTION_CALL',
  OPTION_CALL_REAL = 'OPTION_CALL_REAL',
  BOND = 'BOND',
  OPTION_PUT_REAL = 'OPTION_PUT_REAL',
  SINGLE_EPOCH = 'SINGLE_EPOCH',
  OPTION_PUT_REALT = 'OPTION_PUT_REALT',
}

export type TProductParams = {
  marginAddress: string
  marginTitle: string
  marginDecimals: number
  poolAddress: string
  protectionLogo: string
  rewardPoolAddress: string | null
  subgraphEntity: string
  rewardsPoolProductId: string | null
  hardcap: number
  withdrawalFee: number
  depositDisabled: boolean
  nominal: number
  title: string
  underlyingTitle: string
  referenceTitle: string
  inverseTrigger: number // Inverse value of CDS trigger. i.e. if trigger is 0.95 -> inverse is 0.05
  subtype: EPeer2PoolInsuranceProductParamsSubtype
  secondaryMarketPoolAddress: string
  isSecondaryAvailable: boolean
  ticker?: string
  chartInfo: { [key in EChartEnum]?: TChartLabels } | null
  collateralization: number
}

export type TProduct = {
  id: string
  type: EProductType
  params: TProductParams
  isHedging: boolean
  isTurbo: boolean
  isFirstEpochExpired?: boolean
  title: string
  avgCost: number
  avgCostFrequency: EAvgCostFrequency | null
  expiry?: number
  expiryDate?: number
  network?: ENetwork
  state?: {
    currentEpochTimestamp?: number
    epochLength?: number
    stakingPhaseLength?: number
    tradingPhaseLength?: number
    idleStakingTimeLimit: number
    isWithdrawalsEnabled: boolean
  }
}

export type TChartPoint = {
  buyerPayout: number
  sellerPayout: number
  price: number
}

export type TProductChartData = {
  items: {
    data1: number
    data2: number
    price: number
  }[]
  maxData1: number
  maxData2: number
  minData1: number
  minData2: number
  maxPrice: number
  minPrice: number
}

export type TAnalyticsChartPointResponse = {
  timestamp: number
  performance: number
  linePerformance: number
}

export type TTokenAnalyticsChartResponse = Array<{  
  timestamp: number
  date: string
  price: number
  tokenPrice: number
  lpPrice: number
}>
