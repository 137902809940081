import React, { FC } from 'react'
import { EPeer2PoolInsuranceProductParamsSubtype } from '../../../Constants/Types/product'

interface IProps {
  subtype: EPeer2PoolInsuranceProductParamsSubtype,
}

const OptionType: FC<IProps> = ({ subtype }: IProps) => {
  switch (subtype) {
    case EPeer2PoolInsuranceProductParamsSubtype.OPTION_CALL:
    case EPeer2PoolInsuranceProductParamsSubtype.OPTION_CALL_REAL:
      return <div>OPTION CALL</div>
    case EPeer2PoolInsuranceProductParamsSubtype.OPTION_PUT_REAL:
      return <div>OPTION PUT</div>
    default:
      return null
  }
}

export default OptionType
