import { useEffect, useMemo, useState } from 'react'
import { ELoadingStatuses } from '../Constants/Types'

export const useMobile = () => {
  const [isMobile, setMobile] = useState(false)
  const [mobileOrientation, setMobileOrientation] = useState<number | null | string>(0)
  const [dimension, setDimension] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  })

  const checkMobileOrientation = () => {
    let orientation =
    // @ts-ignore
      (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation

    if (orientation === 'landscape-primary') {
      // horizontal
      setMobileOrientation(90)
    } else if (orientation === 'landscape-secondary') {
      // upsidedown
      setMobileOrientation(180)
    } else if (orientation === 'portrait-secondary' || orientation === 'portrait-primary') {
      // portrait
      setMobileOrientation(0)
    } else if (orientation === undefined) {
      // undefined
      setMobileOrientation(null)
    }
  }

  const orientationChangeListener = (event: any) => {
    setMobileOrientation(event.target.screen.orientation.angle)
  }

  const checkDeviceDimensions = () => {
    setDimension({
      width: window.innerWidth > 0 ? window.innerWidth : screen.width,
      height: window.innerHeight > 0 ? window.innerHeight : screen.height,
    })
  }

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent

    const rgx = /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i

    const deviceIsMobile = Boolean(userAgent.match(rgx))

    setMobile(deviceIsMobile)

    window.addEventListener('resize', checkDeviceDimensions)
    window.addEventListener('orientationchange', orientationChangeListener)

    checkDeviceDimensions()
    checkMobileOrientation()

    return () => {
      window.removeEventListener('resize', checkDeviceDimensions)
      window.removeEventListener('orientationchange', orientationChangeListener)
    }
  }, [])

  return { isMobile, mobileOrientation, ...dimension }
}

export const useTablet = () => {
  const [isTablet, setTablet] = useState(false)

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase()
    const tablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
      )

    setTablet(tablet)
  }, [])

  return { isTablet }
}

interface ISize {
  width: number
  height: number
}

export const useWindowSize = (): ISize => {
  const [windowSize, setWindowSize] = useState<ISize>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowSize
}

export const useRefreshAnimation = (fetchData: Function, setLoading: Function, loading: ELoadingStatuses, animationTime: number = 15000) => {
  const [animation, setAnimation] = useState<boolean>(false)
  const [updateTable, setUpdateTable] = useState<boolean>(true)

  const refreshDataTable = () => {
    setAnimation(false)
    setUpdateTable(true)
    setLoading(ELoadingStatuses.PENDING)
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    let timeout: any = null

    loading === ELoadingStatuses.SUCCEEDED ? setUpdateTable(false) : setUpdateTable(true)

    if (!updateTable) {
      if (loading === ELoadingStatuses.PENDING) {
        animation && setAnimation(false)
      } else {
        !animation && setAnimation(true)
        timeout = setTimeout(() => {
          setAnimation(false)
          setLoading(ELoadingStatuses.PENDING)
          fetchData()
        }, animationTime)
      }
    } else {
      clearTimeout(timeout)
    }

    return () => clearTimeout(timeout)
  }, [animation, loading, updateTable])

  return { animation, updateTable, refreshDataTable }
}

export const useTable = (data: any[], page: number, rowsPerPage: number) => {
  const [tableRange, setTableRange] = useState<any[]>([])
  const [slice, setSlice] = useState<any[]>([])

  useEffect(() => {
    const range = calculateRange(data, rowsPerPage)
    setTableRange([...range])

    const slice = sliceData(data, page, rowsPerPage)
    setSlice([...slice])
  }, [data, setTableRange, page, setSlice])

  const calculateRange = (data: any[], rowsPerPage: number) => {
    const range = []
    const num = Math.ceil(data.length / rowsPerPage)
    for (let i = 1; i <= num; i++) {
      range.push(i)
    }
    return range
  }

  const sliceData = (data: any[], page: number, rowsPerPage: number) => {
    return data.slice((page - 1) * rowsPerPage, page * rowsPerPage)
  }

  return { slice, range: tableRange }
}

export const useDataTable = (data: any[], pageSize: number, currentPage: number) => {
  const slice = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return data.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, data])

  return { slice }
}
