import React, { FC, useEffect, useState } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { ETheme, Popup } from '@opiumteam/react-opium-components'

import Sidebar from './Components/Sidebar'
import ScrollArea from './Components/ScrollArea'
import Prices from './Components/Prices'
import Options from './Components/Options'
import Header from './Components/Header'
import Footer from './Components/Footer'
import TermsPopup from './Components/TermsPopup'

import Volumes from './Components/Volumes'
import Oracles from './Components/Oracles'
import Derivative from './Components/DerivativeConstructor'
import KnowledgeBase from './Components/KnowledgeBase'
import DefiRFQ from './Components/DefiRFQ'
import AuctionData from './Components/AuctionData'

import { getScrollConfig } from './Constants/Pages/scroll'

const App: FC = () => {
  const [termsPopupIsOpen, setTermsPopupIsOpen] = useState(false)

  useEffect(() => {
    const isAgreeWithTermsConditions = localStorage.getItem('isAgreeWithTermsConditions')
    setTermsPopupIsOpen(isAgreeWithTermsConditions !== 'true')
  }, [])

  const handleCancelTerms = () => {
    window.open('https://google.com')
  }

  const scrollConfig = getScrollConfig(ETheme.DARK)

  return (
    <div className="app">
      <Router>
        <Popup
          theme={ETheme.DARK}
          component={(
            <TermsPopup
              onAcceptClick={() => setTermsPopupIsOpen(false)}
              onCancelClick={handleCancelTerms}
            />
          )}
          popupIsOpen={termsPopupIsOpen}
          closePopup={() => {}}
          showCross={false}
          showWarningIcon={true}
        />

        <div className={`${termsPopupIsOpen && 'blurred'}`} style={{
          minHeight: 'inherit',
          display: 'flex',
          flexDirection: 'column',
        }}>

          <Header />

          <div style={{
            background: '#0A0A1E',
            height: '5rem',
            width: '100%',
            content: '',
          }}/>
          <div style={{
            background: '#0A0A1E',
            height: '5rem',
            width: '100%',
            content: '',
            zIndex: 100,
            position: 'fixed',
          }}/>

          <div className={`main color-scheme-${ETheme.DARK}`}>
            <div className='page-container'>
              <ScrollArea 
                disableTracksWidthCompensation
                trackYProps={scrollConfig.trackYProps}
                thumbYProps={scrollConfig.thumbYProps}
                autoHide={true}
              >
                <div className='main-content'>
                  <Switch>
                    <Route exact path='/overview' component={Volumes} />
                    <Route exact path='/prices' component={Prices} />
                    <Route exact path='/oracles' component={Oracles} />
                    <Route exact path='/implied-volatility' component={Options} />
                    <Route exact path='/derivative/:identifier?' component={Derivative} />
                    <Route exact path='/knowledge-base' component={KnowledgeBase} />
                    <Route exact path='/rfq' component={DefiRFQ} />
                    <Route exact path='/auction-data' component={AuctionData} />
                    <Redirect to='/overview' />
                  </Switch>

                  <Footer />
                </div>
              </ScrollArea>
            </div>
            <Sidebar />
          </div>

        </div>
      </Router>
    </div>
  )
}

export default App
