import React, { FC, FormEvent, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { withRouter, Link, useLocation } from 'react-router-dom'
import { ETheme, Tooltip } from '@opiumteam/react-opium-components'

import { useMobile } from '../../Utils/hooks'
import { IPage } from '../../Constants/Types/pages'
import constructorStore from '../../Stores/ConstructorStore'

import optionsIcon from '../../Images/Icons/options.svg'
import oraclesIcon from '../../Images/Icons/oracles.svg'
import knowledgeBaseIcon from '../../Images/Icons/knowledgeBase.svg'
import overviewIcon from '../../Images/Icons/overview.svg'
import explorerIcon from '../../Images/Icons/explorer.svg'
import auctionIcon from '../../Images/Icons/auction.svg'
import ConstructorDarkIcon from '../../Images/Icons/constructor-dark.svg'
import rfqIcon from '../../Images/Icons/rfq.svg'

import './styles.scss'

const pages: Array<IPage> = [
  {
    classSelector: 'volumes',
    idLabel: 'volumes',
    title: 'Overview',
    to: '/overview',
    icon: overviewIcon,
    disabled: false
  },
  // {
  //   classSelector: 'prices',
  //   idLabel: 'prices',
  //   title: 'Prices',
  //   to: '/prices',
  //   icon: Icon,
  //   disabled: false
  // },
  {
    classSelector: 'explorer',
    idLabel: 'explorer',
    title: 'Explorer',
    to: '/derivative',
    icon: explorerIcon,
    disabled: false
  },
  {
    classSelector: 'options',
    idLabel: 'options',
    title: 'Implied volatility',
    to: '/implied-volatility',
    icon: optionsIcon,
    disabled: false
  },
  {
    classSelector: 'oracles',
    idLabel: 'oracles',
    title: 'Oracles',
    to: '/oracles',
    icon: oraclesIcon,
    disabled: false
  },
  {
    classSelector: 'defi-rfq',
    idLabel: 'defi-rfq',
    title: 'DeFi RFQ',
    to: '/rfq',
    icon: rfqIcon,
    disabled: false,
  },
  {
    classSelector: 'auction',
    idLabel: 'auction',
    title: 'Auction data',
    to: '/auction-data',
    icon: auctionIcon,
    disabled: false,
  },
  {
    classSelector: 'knowledge',
    idLabel: 'knowledge',
    title: 'Knowledge base',
    to: '/knowledge-base',
    icon: knowledgeBaseIcon,
    disabled: false,
    // tooltip: 'knowledge'
  },
] 


const Sidebar: FC<{}> = () => {

  const location = useLocation()
  const { width: deviceWidth } = useMobile()

  const [activeElement, setActiveElement] = useState(0)
  const [nextElement, setNextElement] = useState(-1)

  const [nextElementHeight, setNextElementHeight] = useState(0)

  const [showItemDecoration, setShowItemDecoration] = useState(true)

  const [openItemWithChilds, setOpenItemWithChilds] = useState(-1)

  const [navbarListItemsHeight, setNavbarListItemsHeight] = useState<{
    offset: number
    height: number
  }[]>([])

  const sidebarRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {

    // HIDE ITEM DECORATION IF PAGES
    setShowItemDecoration(!(
      (location.pathname.includes('/positions'))
    ))

    calculateSidebarItemsHeight()

    let activeIndex = -1
    let i = 0

    for (let item of pages) {

      // CHECK CHILDS
      if (
        item.childLinks &&
        deviceWidth > 1200
      ) {

        // find child that === location.pathname
        for (let childLink of item.childLinks) {
          if (childLink.to === location.pathname) {
            activeIndex = i
            break
          }
        }

        // if child found - break loop and set its parent as active
        if (activeIndex >= 0) {
          setOpenItemWithChilds(activeIndex)
          calculateSidebarItemsHeight()

          if (navbarListItemsHeight[activeIndex]) {
            setNextElementHeight(navbarListItemsHeight[activeIndex].height)
          }
          break
        }

      } else {

        // Set Active item with no child and === location.pathname
        if (item.to === location.pathname) {
          activeIndex = i
          break
        }
      }

      // next item
      i++
    }

    const elemIndex = nextElement < 0 ? activeIndex : nextElement

    setActiveElement(activeIndex)
    setNextElement(elemIndex)

    return () => {
      setActiveElement(0)
      setNextElement(0)
    }
  }, [location, pages])

  useEffect(() => {
    if (nextElement > -1) {
      setNextElementHeight(navbarListItemsHeight[nextElement].height)
    }
  }, [navbarListItemsHeight])

  const calculateSidebarItemsHeight = (i?: number) => {

    // GET ALL ITEMS in SIDEBAR & PUSH THEM IN THE ARRAY
    let nodes: HTMLDivElement[] = []
    if (sidebarRef && sidebarRef.current?.children) {
      for (let i = 0; i < sidebarRef.current?.children.length; i++) {
        if (sidebarRef.current.children[i].className.includes('module-sidebar-wrap')) {
          nodes.push(sidebarRef.current.childNodes[i] as HTMLDivElement)
        }
      }
    }

    // CONFIGURE SIDEBAR ARRAY WITH OFFSET AND HEIGHT OF EACH ITEM
    const heightsArr: {offset: number, height: number}[] = []
    let heightSum = 0
    nodes.map(el => {
      const elHeight = el.offsetHeight
      const itemInfo = {
        offset: heightSum,
        height: elHeight
      }
      heightSum += elHeight
      heightsArr.push(itemInfo)
    })

    setNavbarListItemsHeight(heightsArr)

    // IF METHOD USED
    if (i) {
      setNextElement(i)
      setNextElementHeight(heightsArr[i].height)
    }

  }

  const onLinkClick = (to: string, i: number) => {
    setActiveElement(i)
  }

  const onMouseEnterSubmenu = (i: number, e: FormEvent) => {
    e.persist()

    setNextElement(i)
    setShowItemDecoration(true)

    if (navbarListItemsHeight[i]) setNextElementHeight(navbarListItemsHeight[i].height)
  }

  const onMouseLeaveSubmenu = () => {
    setNextElement(activeElement)

    if (activeElement >= 0) {
      setNextElementHeight(navbarListItemsHeight[activeElement].height)
    } else {
      setShowItemDecoration(false)
      setNextElementHeight(0)
    }
  }

  useEffect(() => {
    openItemWithChilds > -1 ? calculateSidebarItemsHeight(openItemWithChilds) : calculateSidebarItemsHeight()
  }, [openItemWithChilds])


  const renderTooltip = (component: JSX.Element, tooltip?: string) => {
    return tooltip ? 
      <Tooltip
        theme={ETheme.DARK}
        label={'NFT'}
        disabledBtn
        trigger='hover'
        placement='right'
        content={'Coming soon'}
        component={component}
      />
      : component
  }

  const returnMenuItem = (i: number, to: string, title: string, idLabel: string, disabled: boolean, icon: string, childLinks: IPage[] | undefined, tooltip?: string) => {
    return (
      <div
        key={idLabel + '-' + i}
        className={`module-sidebar-wrap color-scheme-${ETheme.DARK} ${location.pathname === to ? 'active' : ''} ${childLinks ? 'withChilds':''} ${openItemWithChilds === i ? 'active2':''}`}
        onMouseEnter={(e: FormEvent) => onMouseEnterSubmenu(i, e)}
        onMouseLeave={() => onMouseLeaveSubmenu()}
        onClick={(e) => {
          e.preventDefault()

          if (childLinks) {
            setOpenItemWithChilds(openItemWithChilds === -1 ? i : -1)
          }
        }}
      >


        {renderTooltip(
          <Link
            to={{ pathname: to === '/derivative' ? `${to}${constructorStore.identifier ? '/' + constructorStore.identifier : ''}` : to }}
            onClick={(e) => {
              if (childLinks && deviceWidth > 1200) {
                e.preventDefault()
              } else {
                onLinkClick(to, i)
              }
            }}
            className={`navigation-item ${to === location.pathname ? 'navigation-item-active' : ''} ${tooltip ? 'navigation-item-disabled' : ''}`}
          >
            <img src={icon} />
            {(idLabel === 'explorer') && <div className='constructor-label-new sidebar-new'>new</div>}
            {(idLabel === 'defi-rfq') && <div className='constructor-label-new sidebar-new wide-label-soon'>soon</div>}
            <span>{title}</span>
          </Link>,
          tooltip
        )}
        {
          childLinks && deviceWidth > 1200 && <div className={`navigation-item__arrow ${openItemWithChilds === i ? 'active' : ''}`}>
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM9.35355 1.35355C9.54882 1.15829 9.54882 0.841709 9.35355 0.646447C9.15829 0.451184 8.84171 0.451184 8.64645 0.646447L9.35355 1.35355ZM0.646447 1.35355L4.29289 5L5 4.29289L1.35355 0.646447L0.646447 1.35355ZM5.70711 5L9.35355 1.35355L8.64645 0.646447L5 4.29289L5.70711 5ZM4.29289 5C4.68342 5.39053 5.31658 5.39052 5.70711 5L5 4.29289L5 4.29289L4.29289 5Z" fill='white' />
            </svg>
          </div>
        }
        {
          childLinks && childLinks.map((el, innerI) => (
            <Link
              key={`page-${el.title}`}
              to={{ pathname: el.to === '/derivative' ? `${el.to}${constructorStore.identifier ? '/' + constructorStore.identifier : '' }` : el.to }}
              onClick={() => onLinkClick(el.to, innerI)}
              className={`navigation-item navigation-item__sub ${el.to === location.pathname ? 'navigation-item-active' : ''}`}
            >
              {/* <img src={icon} /> */}
              <span>{el.title}</span>
            </Link>
          ))
        }
      </div>
    )
  }

  if (location.pathname === '/') return (<></>)

  return (
    <div className="sidebar" ref={sidebarRef}>
      <div className={`active-block ${showItemDecoration ? ' fade-in':'fade-out'}`}
        style={{
          // opacity: showItemDecoration ? '1':'0',
          transition: 'min-height .5s ease, transform .5s ease',
          minHeight: nextElementHeight + 'px',
          transform: `translateY(${(nextElement >= 0) ? navbarListItemsHeight[nextElement].offset : '0'}px)`,
        }}>
        <p></p>
      </div>

      {
        pages
          .map(({ to, title, idLabel, icon, disabled, childLinks, tooltip }, i) => {
            return returnMenuItem(i, to, title, idLabel, disabled, icon, childLinks, tooltip)
          })
      }

      {<div className="sidebar__bottom">
      designed by community ❤️
      </div>}
    </div>
  )
}

export default withRouter(observer(Sidebar))
