import React, { ElementType, FC, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import appStore from '../../Stores/AppStore'

import './styles.scss'
import MenuArticles from '../MenuArticles'
import BurgerMenu from '../../Images/Icons/burger-menu.svg'
import CloseMenu from '../../Images/Icons/close-menu.svg'

import CoveredCallOption from './Articles/CoveredCallOption'
import Straddle from './Articles/Straddle'
import Strangle from './Articles/Strangle'
import CashSecuredPutOption from './Articles/CashSecuredPutOption'
import Collar from './Articles/Collar'
import PutOption from './Articles/PutOption'
import CallOption from './Articles/CallOption'

import { camel2title } from '../../Utils/helpers'
import { ETheme, StaticChart } from '@opiumteam/react-opium-components'

interface IProps {}
type TArticlesComponents = {
  [key: string]: FC
}

const KnowledgeBase: FC<IProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<string>('CallOption')

  useEffect(() => {
    (window.innerWidth <= 1024) && setIsOpen(true)
  }, [])

  const ToggleSidebar = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true)
  }

  const handleClick = (item: string) => {
    setActiveItem(item)
  }

  const dataChart: any = [
    { data1: 0.5, data2: 0, price: 2000 },
    { data1: 0.5, data2: 0, price: 2175 },
    { data1: 0.5, data2: 0, price: 2350 },
    { data1: 0.49, data2: 0.01, price: 2525 },
    { data1: 0.43, data2: 0.07, price: 2700 },
    { data1: 0.37, data2: 0.13, price: 2875 },
    { data1: 0.32, data2: 0.18, price: 3050 },
    { data1: 0.28, data2: 0.22, price: 3225 },
    { data1: 0.24, data2: 0.26, price: 3400 },
    { data1: 0.2, data2: 0.3, price: 3575 },
    { data1: 0.17, data2: 0.33, price: 3750 },
    { data1: 0.14, data2: 0.36, price: 3925 },
  ]

  const content = useMemo(() => {
    const components: TArticlesComponents = {
      CoveredCallOption,
      Straddle,
      Strangle,
      CashSecuredPutOption,
      Collar,
      PutOption,
      CallOption
    }

    const componentName = camel2title(activeItem)
    const DynamicComponent = components[componentName]
    
    return (
      <>
        <DynamicComponent />
      </>
    )
  }, [activeItem])

  const listArticles = () => {
    return (
      <>
        <div className="KnowledgeBase__block">
          <div className={`KnowledgeBase__block-title color-scheme-${ETheme.DARK}`}>Payout chart</div>
          <div className="KnowledgeBase__block-charts-wrapper">
            <StaticChart
              theme={ETheme.DARK}
              height={'286px'}
              width={'100%'}
              chartData2={{ tooltipTitle: 'Profit', tooltipSuffix: '%' }}
              type={activeItem}
              chartData1={{ tooltipTitle: 'Loss', tooltipSuffix: '%' }}
              domainY={[-1, 1]}
              increaseDomainY={0.3}
            />
          </div>
          {content}
        </div>
      </>
    )
  }

  return (
    <div className="KnowledgeBase__wrapper">
      <div className="KnowledgeBase__burger-menu">
        {!appStore.isOpenMobileMenu && <img src={BurgerMenu} alt='knowledge-base' onClick={ToggleSidebar} /> }
      </div>

      <div className={`KnowledgeBase__menu-wrapper ${isOpen == true ? 'active' : ''}`}>
        <img src={CloseMenu} alt='knowledge-base' onClick={ToggleSidebar} />
        <MenuArticles onClick={handleClick} />
      </div>
      <div className="KnowledgeBase__main-content">
        <div className="KnowledgeBase__part-left">
          {listArticles()}
        </div>
        <div className="KnowledgeBase__part-right">
          <MenuArticles onClick={handleClick} />
        </div>
      </div>
    </div>
  )
}

export default observer(KnowledgeBase)
