import { ETheme } from '@opiumteam/react-opium-components'
import React, { FC } from 'react'

type TProps = {}

const Strangle: FC<TProps> = () => {
  return (
    <>
      <div className={`KnowledgeBase__block-title bottom-line color-scheme-${ETheme.DARK}`}>
        Strangle
      </div>
      <div className="KnowledgeBase__block-content">
        Long strangle is a combination of long Put option with lower Strike and long Call option with higher Strike. Investor has to pay premium for both Options but will gain if price of the Underlying moves outside of the Strikes range (irrespective of direction). Similar to Straddle, this strategy is used to gain exposure to volatility of the Underlying, and is cheaper than comparable Straddle, but has a “buffer zone” in terms of Underlying price movement. 
      </div>
      <div className="KnowledgeBase__block-content">
        Short strangle is a combination of short Put option with lower Strike and short Call option with higher Strike. Investor receives premium for both Options, but price movements of the Underlying outside of the Strikes will reduce his payoff. Similar to Straddle, this strategy is used for yield enhancement in a situation when investor is ready to bet on stability of the Underlying, but provides less Premium as there is a “buffer zone” in terms of Underlying price movement.
      </div>

    </>
  )
}

export default Strangle
