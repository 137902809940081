import axios, { AxiosInstance } from 'axios'

const coingeckoEndpoint = 'https://api.coingecko.com/api/v3/'

export class CoingeckoApi {
  private readonly _client: AxiosInstance

  public constructor() {
    this._client = axios.create({
      baseURL: coingeckoEndpoint,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  getPrices = async () => {
    const prices = await this._client.get(
      '/simple/price?ids=aave,matic-network,opium,bitcoin,tether,ethereum,1inch,curve-dao-token,sushi&vs_currencies=usd'
    )
    return prices.data
  }

  getPriceByAsset = async (assets: string) => {
    const prices = await this._client.get(`/simple/price?ids=${assets}&vs_currencies=usd`)
    return prices.data
  }
}

export default new CoingeckoApi()
