import { observable, action, computed, autorun, reaction } from 'mobx'
import dataStore from '../Services/dataStore'
import { TOracle, TNetworkData, EOraclesFilterTab, EOraclesNetwork, TOraclesEntity, TOraclesCheckbox, EOraclesId, TOraclesFilterButton, EOraclesFilterType } from '../Constants/Types/oracles'
import { ELoadingStatuses } from '../Constants/Types'
import { TSortConfig } from '../Utils/sorting'

class OraclesStore {
  @observable oracles: TOracle[] = []

  @observable tokenPrices: { [x in string]: { usd: number } } = {}

  @observable tokenPricesLoading: ELoadingStatuses = ELoadingStatuses.IDLE

  @observable oraclesLoading: ELoadingStatuses = ELoadingStatuses.IDLE

  @observable activeTabOraclesId: EOraclesFilterTab = EOraclesFilterTab.All

  @observable sourceFilters: string[] = []
  @observable assetFilters: string[] = []
  @observable chainFilters: string[] = []
  @observable arrayAllFilters: TOraclesFilterButton[] = []

  @observable sourceCheckboxList: TOraclesCheckbox[] = []
  @observable assetCheckboxList: TOraclesCheckbox[] = []
  @observable chainCheckboxList: TOraclesCheckbox[] = []

  @observable filtersList: { asset: string[]; source: string[]; chain: string[] } | null = null
  @observable totalCount: number = 0
  @observable currentPage: number = 1

  @observable sortConfig: TSortConfig | null = null

  constructor () {
    reaction(() => this.filtersList, this._generateCheckboxList)
    autorun(this._checkFilters)
  }

  // autoruns

  private _generateCheckboxList = () => {
    this.chainCheckboxList = this.filtersList ? this.filtersList.chain.map((item: string) => ({ title: item, value: false })) : []
    this.sourceCheckboxList = this.filtersList ? this.filtersList.source.map((item: string) => ({ title: item, value: false })) : []
    this.assetCheckboxList = this.filtersList ? this.filtersList.asset.map((item: string) => ({ title: item, value: false })) : []
  }

  private _checkFilters = () => {
    this.sourceFilters?.forEach((title) => {
      this.sourceCheckboxList.forEach((item: TOraclesCheckbox) => ((item.title?.toLowerCase() === title.toLowerCase() ? item.value = true : false )))
    })
    this.assetFilters?.forEach((title) => {
      this.assetCheckboxList.forEach((item: TOraclesCheckbox) => ((item.title?.toLowerCase() === title.toLowerCase() ? item.value = true : false )))
    })
    this.chainFilters?.forEach((title) => {
      this.chainCheckboxList.forEach((item: TOraclesCheckbox) => ((item.title?.toLowerCase() === title.toLowerCase() ? item.value = true : false )))
    })
  }

  // actions 

  @action getNewOracles: (currentPage: number) => void = async(currentPage): Promise<void> => {
   
    try {
      const asset = this.assetFilters.join(',')
      const source = this.sourceFilters.join(',')
      const chain = this.chainFilters.join(',')

      const oracles = await dataStore.getOracles(currentPage, asset, source, chain, this.sortConfig?.key, this.sortConfig?.direction)
      this.oracles = oracles.slice
      this.filtersList = oracles.filters
      this.totalCount = oracles.totalCount
      this.oraclesLoading = ELoadingStatuses.SUCCEEDED
    } catch (err) {
      console.error(err)
      this.oraclesLoading = ELoadingStatuses.FAILED
    }
  }


  @action getTokenPrices: () => void = async (): Promise<void> => {
    if (
      this.tokenPricesLoading === ELoadingStatuses.PENDING ||
      this.tokenPricesLoading === ELoadingStatuses.SUCCEEDED
    ) {
      return
    }

    try {
      this.tokenPricesLoading = ELoadingStatuses.PENDING
      const tokenPrices = await dataStore.getTokenPrices()
      this.tokenPrices = tokenPrices
      this.tokenPricesLoading = ELoadingStatuses.SUCCEEDED
    } catch (err) {
      console.error(err)
      this.tokenPricesLoading = ELoadingStatuses.FAILED
    }
  }

  @action filteredOraclesList = (flag: EOraclesFilterTab) => {
    this.activeTabOraclesId = flag
  }

  @action setCurrentPage = (page: number) => {
    this.currentPage = page
  }

  @action
  setAllFilters = (item: TOraclesFilterButton) => {
    const { title, type } = item
    const newTitle = type === EOraclesFilterType.Chain ? title[0] + title.slice(1).toLowerCase() : title
    const i = this.arrayAllFilters.findIndex((item: TOraclesFilterButton) => (item.title === newTitle && item.type === type))
    if (i !== -1) {
      this.arrayAllFilters.splice(i, 1)
    } else {
      this.arrayAllFilters.push(item)
    }
  }

  @action
  setSourceFilters = (item: string) => {
    const i = this.sourceFilters.indexOf(item)
    if (i !== -1) {
      this.sourceFilters.splice(i, 1)
    } else {
      this.sourceFilters.push(item)
    }
    this.setAllFilters({ title: item, type: EOraclesFilterType.Source })
    this.setCheckedValueOfOracles(this.sourceCheckboxList, item)
    this.getNewOracles(this.currentPage)
  }

  @action
  setAssetFilters = (item: string) => {
    const i = this.assetFilters.indexOf(item)
    if (i !== -1) {
      this.assetFilters.splice(i, 1)
    } else {
      this.assetFilters.push(item)
    }
    this.setAllFilters({ title: item, type: EOraclesFilterType.Asset })
    this.setCheckedValueOfOracles(this.assetCheckboxList, item)
    this.getNewOracles(this.currentPage)
  }

  @action
  setChainFilters = (item: string) => {
    const i = this.chainFilters.indexOf(item)
    if (i !== -1) {
      this.chainFilters.splice(i, 1)
    } else {
      this.chainFilters.push(item)
    }
    this.setAllFilters({ title: item, type: EOraclesFilterType.Chain })
    this.setCheckedValueOfOracles(this.chainCheckboxList, item)
    this.getNewOracles(this.currentPage)
  }

  @action
  setCheckedValueOfOracles = (array: TOraclesCheckbox[], title: string) => {
    array.forEach((item: TOraclesCheckbox) => ((item.title?.toLowerCase() === title.toLowerCase() ? item.value = !item.value : item.value )))
  }

  @action setSortConfigOracles = (sortConfig: TSortConfig) => {
    this.sortConfig = sortConfig
  }
}

export default new OraclesStore()
