import React, { FC } from 'react'
import { ETheme, SidebarMenu } from '@opiumteam/react-opium-components'
import { observer } from 'mobx-react'

import './styles.scss'
type TProps = {
  onClick: Function
}

const data = ['Call Option', 'Covered Call Option', 'Put Option', 'Cash Secured Put Option', 'Collar', 'Straddle', 'Strangle']

const MenuArticles: FC<TProps> = ({ onClick }: TProps) => {
  return (
    <SidebarMenu 
      theme={ETheme.DARK}
      items={data}
      activeItem={'Call Option'}
      onClick={onClick}
    />
  )
}

export default observer(MenuArticles)
