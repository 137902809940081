import React, { FC, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets'
import { Button, ETheme, Tabs, Tooltip } from '@opiumteam/react-opium-components'

import DataStore from '../../Services/dataStore'
import InfoLogo from '../../Images/Info.svg'



import './styles.scss'

const Prices: FC<{}> = () => {

  const renderChart = (chart: JSX.Element, title: string, source: JSX.Element) => {
    return (
      <div className='prices-chart-item-wrapper'>
        <div className='prices-chart-upper-section'>
          <div className='prices-chart-title'>
            {title}
            <Tooltip
              theme={ETheme.DARK}
              label={'!'}
              trigger='hover'
              placement='bottom'
              content={'Historical price of the asset in US dollars'}
              html={true}
              component={<img style={{ marginLeft: '0.5rem', marginBottom: '-2px', cursor: 'pointer' }} src={InfoLogo} />}
            />
          </div>
          <div className='prices-chart-source'>Source: {source}</div>
        </div>
        <div className='prices-chart-chart-wrapper'>
          {chart}
        </div>
      </div>
    )
  }


  return (
    <div className='prices-wrapper'>
      <div className='prices-charts-wrapper'>

        <div className='prices-chart'>
          {renderChart(
            <AdvancedRealTimeChart theme="dark" autosize toolbar_bg='#0A0A1E' symbol='ETHUSD'></AdvancedRealTimeChart>,
            'Ethereum price (ETH/USD)',
            <a href='https://www.tradingview.com/' target='_blank' rel='noreferrer'>Trading view</a>
          )}
        </div>

        <div className='prices-chart'>
          {renderChart(
            <AdvancedRealTimeChart theme="dark" autosize toolbar_bg='#0A0A1E' symbol='BTCUSD'></AdvancedRealTimeChart>,
            'Bitcoin price (BTC/USD)',
            <a href='https://www.tradingview.com/' target='_blank' rel='noreferrer'>Trading view</a>
          )}
        </div>

        <div className='prices-chart'>
          {renderChart(
            <AdvancedRealTimeChart theme="dark" autosize toolbar_bg='#0A0A1E' symbol='AAVEUSD'></AdvancedRealTimeChart>,
            'AAVE price (AAVE/USD)',
            <a href='https://www.tradingview.com/' target='_blank' rel='noreferrer'>Trading view</a>
          )}
        </div>

        <div className='prices-chart'>
          {renderChart(
            <AdvancedRealTimeChart theme="dark" autosize toolbar_bg='#0A0A1E' symbol='1INCHUSD'></AdvancedRealTimeChart>,
            '1inch price (1INCH/USD)',
            <a href='https://www.tradingview.com/' target='_blank' rel='noreferrer'>Trading view</a>
          )}
        </div>

        <div className='prices-chart'>
          {renderChart(
            <AdvancedRealTimeChart theme="dark" autosize toolbar_bg='#0A0A1E' symbol='USDTUSD'></AdvancedRealTimeChart>,
            'USDT price (USDT/USD)',
            <a href='https://www.tradingview.com/' target='_blank' rel='noreferrer'>Trading view</a>
          )}
        </div>

        <div className='prices-chart'>
          {renderChart(
            <div dangerouslySetInnerHTML={{ __html: '<coingecko-coin-compare-chart-widget  coin-ids="opium" currency="usd" locale="en"></coingecko-coin-compare-chart-widget>' }}></div>,
            'OPIUM price (OPIUM/USD)',
            <a href='https://www.coingecko.com/en' target='_blank' rel='noreferrer'>Coingecko</a>
          )}
        </div>

      </div>
    </div>
  )
}

export default observer(Prices)
