import React, { FC, ReactNode, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, ETheme, Tooltip } from '@opiumteam/react-opium-components'
import { useMobile, useRefreshAnimation } from '../../Utils/hooks'
import info from '../../Images/Info.svg'
import constructorStore from '../../Stores/ConstructorStore' 

import './styles.scss'
import { networkLogos } from '../../Utils/helpers'
import DerivativeBidAskPopup from '../DerivativeConstructor/Components/DerivativeBidAskPopup'
import defiRFQStore from '../../Stores/DefiRFQStore'
import { TRFQComponent, TRFQ, ERFQComponentType } from '../../Constants/Types/defiRFQ'
import numeral from 'numeral'

const DefiRFQ: FC<{}> = ({}) => {
  const { isMobile } = useMobile()
  const [activePremiumText, setActivePremiumText] = useState<ReactNode>(null)

  const { animation, updateTable, refreshDataTable } = useRefreshAnimation(defiRFQStore.fetchRFQDataForTable, defiRFQStore.setRFQDataLoading, defiRFQStore.rfqTableDataLoading)

  const determinePosition = (item: TRFQComponent) => {
    let typeEl: JSX.Element = <span />
    switch (item.type) {
      case ERFQComponentType.LONG:
        typeEl = <span className='green bold'>LONG</span>
        break
      case ERFQComponentType.SHORT:
        typeEl = <span className='red bold'>SHORT</span>
        break
      default:
        break
    }
    return typeEl
  }

  const renderBidPrice = (item: TRFQ[0]) => {



    let toPay: JSX.Element[] = item.requestedComponents.map((component, i) => <div className='tooltip-red-border' key={i}>{component.quantity} {determinePosition(component)} {component.title}</div>)
    let toReceive: JSX.Element[] = item.offeredComponents.map((component, i) => <div className='tooltip-green-border' key={i}>{component.quantity} {determinePosition(component)} {component.title}</div>)
    

    if (item.bid) {
      if (item.bid > 0) {
        toReceive.push(<div className='tooltip-green-border-bottom' key={item.token.title}>{Math.abs(+numeral(item.bid).format('0[.]00'))} {item.token.title}</div>)
      }
      if (item.bid < 0) {
        toPay.push(<div className='tooltip-green-border-bottom' key={item.token.title}>{Math.abs(+numeral(item.bid).format('0[.]00'))} {item.token.title}</div>)
      }
    }
    
    return (
      <>
        <p className='tooltip-bold red-box'>{'To pay:'}</p>
        {toPay.length ? toPay.map(item => item) : '-'}
        <p className='tooltip-bold green-box tooltip-indent'>{'To receive:'}</p>
        {toReceive.length ? toReceive.map(item => item) : '-'}
      </>
    )
  }


  const renderAskPrice = (item: TRFQ[0]) => {
    let toPay: JSX.Element[] = item.offeredComponents.map((component, i) => <div className='tooltip-red-border' key={i}>{component.quantity} {determinePosition(component)} {component.title}</div>)
    let toReceive: JSX.Element[] = item.requestedComponents.map((component, i) => <div className='tooltip-green-border' key={i}>{component.quantity} {determinePosition(component)} {component.title}</div>)


    if (item.ask) {
      if (item.ask < 0) {
        toReceive.push(<div className='tooltip-green-border-bottom' key={item.token.title}>{Math.abs(+numeral(item.ask).format('0[.]00'))} {item.token.title}</div>)
      }
      if (item.ask > 0) {
        toPay.push(<div className='tooltip-red-border' key={item.token.title}>{Math.abs(+numeral(item.ask).format('0[.]00'))} {item.token.title}</div>)
      }
    }
    
    return (
      <>
        <p className='tooltip-bold red-box'>{'To pay:'}</p>
        {toPay.length ? toPay.map(item => item) : '-'}
        <p className='tooltip-bold green-box tooltip-indent'>{'To receive:'}</p>
        {toReceive.length ? toReceive.map(item => item) : '-'}
      </>
    )
  }


  // const openBidPopup = (e: any, link: string, item: any) => {
  //   e.stopPropagation()
  //   setActivePremiumText(renderLongPrice(item))
  //   constructorStore.setShowBidPopup(true)
  // }

  // const openAskPopup = (e: any, link: string, item: any) => {
  //   e.stopPropagation()
  //   setActivePremiumText(renderShortPrice(item))
  //   constructorStore.setShowAskPopup(true)
  // }

  const renderPrice = (item: TRFQComponent) => {
    return (
      <div className='rfq-td-content' key={item.title}><span className='bold'>{item.quantity} {determinePosition(item)}</span> {item.title}</div>
    )
  }

  return (
    <div className='page-wrapper RfqPage__wrapper'>
      <DerivativeBidAskPopup priceText={activePremiumText}/>

      <div className="page-title title-label-new">Request for Quote</div>
      <div className="derivatives-tab-section rfq-refresh-section">
        <button className='derivatives-tabs-anim-svg' onClick={refreshDataTable}>
          {updateTable ? 'Refreshing...' : 'Refresh'}
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ visibility: !updateTable ? 'visible': 'hidden' }}>
            <circle cx="7.5" cy="7.5" r="6.5" stroke="#5D5F7C" strokeWidth="1.5"/>
            <circle cx="7.5" cy="7.5" r="6.5" stroke="white" strokeWidth="1.5" className={`${animation ? 'derivatives-dash': undefined}`}/>
          </svg>

          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ visibility: updateTable ? 'visible': 'hidden' }} className="circle-svg">
            <circle cx="7.5" cy="7.5" r="6.5" stroke="#5D5F7C" strokeWidth="1.5"/>
            <circle cx="7.5" cy="7.5" r="6.5" stroke="white" strokeWidth="1.5" className={ `${updateTable ? 'derivatives-circle': undefined}`} />
          </svg>
        </button>
      </div>

      {(() => {
        return (
          <>
            <div className='DerivativeTable'>
              <table className='transparent-table rfq-table'>
                <thead>
                  <tr>
                    <th>To pay / Requested</th>
                    <th>To receive / Offered</th>
                    <th>Bid size</th>
                    <th>
                      <div className='rfq-table-content'>Bid 
                        {/* <Tooltip
                          theme={ETheme.DARK}
                          label={''}
                          trigger='hover'
                          placement={isMobile ? 'top' : 'left'}
                          content="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ducimus, dolore?"
                          component={<img src={info} alt="derivative" />}
                        /> */}
                      </div>
                    </th>
                    <th>
                      <div className='rfq-table-content'>Ask
                        {/* <Tooltip
                          theme={ETheme.DARK}
                          label={''}
                          trigger='hover'
                          placement={isMobile ? 'top' : 'left'}
                          content="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ducimus, dolore?"
                          component={<img src={info} alt="derivative" />}
                        /> */}
                      </div>
                    </th>
                    <th>Ask size</th>
                    <th>Chain</th>
                  </tr>
                </thead>

                <tbody>
                  {defiRFQStore.rfqTableData.map((item, i) => (
                    <tr className='rfq-item-wrapper' key={item.token + '-' + i} >
                      <td>{item.requestedComponents.length ? item.requestedComponents?.map((el: TRFQComponent) => {
                        return renderPrice(el)
                      }) : '-'}</td>
                      <td>
                        {item.offeredComponents.length ? item.offeredComponents?.map((el: TRFQComponent) => {
                          return renderPrice(el)
                        }) : '-'}
                      </td>
                      <td>{parseFloat(numeral(item.bidSize).format('0[.]000000')) || '-'}</td>
                      <td>
                        <Tooltip
                          theme={ETheme.DARK}
                          label={''}
                          trigger='hover'
                          placement='bottom'
                          className='derivatives'
                          content={
                            <div className={`rfq-btn-tooltip color-scheme-${ETheme.DARK}`}>
                              <div className='tooltip-title green'>Bid</div>
                              <div className='tooltip-content'>{renderBidPrice(item)}</div>
                            </div>
                          }
                          component={
                            <Button
                              theme={ETheme.DARK}
                              variant={'secondary'}
                              label={item.bid !== null ? `${numeral(item.bid).format('0[.]00')} ${item.token.title}` : '-'}
                              className="item-box item-green green"
                              onClick={(e: any ) => {}}
                              // onClick={(e: any ) => openBidPopup(e, '', item) }
                              size="sm"
                              newTab
                              disabled={item.bid === null}
                            />
                          }
                        />
                      </td>
                      <td>
                        <Tooltip
                          theme={ETheme.DARK}
                          label={''}
                          trigger='hover'
                          placement='bottom'
                          className='derivatives'
                          content={
                            <div className={`rfq-btn-tooltip color-scheme-${ETheme.DARK}`}>
                              <div className='tooltip-title red'>Ask</div>
                              <div className='tooltip-content'>{renderAskPrice(item)}</div>
                            </div>
                          }
                          component={
                            <Button
                              theme={ETheme.DARK}
                              variant={'secondary'}
                              label={item.ask !== null ? `${numeral(item.ask).format('0[.]00')} ${item.token.title}` : '-'}
                              className="item-box item-red"
                              // onClick={(e: any) => openAskPopup(e, '', item)}
                              onClick={() => {}}
                              disabled={item.ask === null}
                              size="sm"
                              newTab
                            />
                          }
                        />
                      </td>
                      <td>{parseFloat(numeral(item.askSize).format('0[.]000000'))|| '-'}</td>
                      <td>
                        <Tooltip
                          theme={ETheme.DARK}
                          label={''}
                          trigger='hover'
                          placement='top'
                          content={item.chain && (item.chain[0].toUpperCase() + item.chain.slice(1))}
                          html={true}
                          component={<img className="rfq-item-icon" src={networkLogos(item.chain)}/>}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )
      })()}

    </div>
  )
}

export default observer(DefiRFQ)
