import { ReactNode } from 'react'

export enum EAvgCostFrequency {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
  SINGLE_EPOCH = 'SINGLE_EPOCH',
  PER_3_DAYS = 'PER_3_DAYS',
}

export interface ISelectWithLabel {
  children?: ReactNode
  text?: string,
  direction?: string
}

export enum EDirectionType {
  ROW = 'row',
  COLUMN = 'column'
}
