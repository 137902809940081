import React, { FC, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Chart, OpiumLink, ETheme, Loading } from '@opiumteam/react-opium-components'
import moment from 'moment'

import volumesStore from '../../../Stores/VolumesStore'
import { shortenAddress, getScanLink } from '../../../Utils/helpers'

import { TPopulatedStaking } from '../../../Constants/Types/staking'
import { ELoadingStatuses } from '../../../Constants/Types'
import { useWindowSize } from '../../../Utils/hooks'

import OptionType from './OptionType'

interface IProps {
  activePool: TPopulatedStaking,
}

const ActivePoolPopupContent: FC<IProps> = ({ activePool }: IProps) => {
  const {
    id,
    network,
    params: {
      currentEpochTimestamp,
    },
    product: {
      params: {
        underlyingTitle,
        inverseTrigger,
        subtype,
        poolAddress,
        chartInfo,
        collateralization,
        marginTitle
      },
    }
  } = activePool

  const { width } = useWindowSize()

  useEffect(() => {
    volumesStore.getProductChartData(id, network)
  }, [])

  const isCurrentProductChart = volumesStore.productChart.id === id

  const maturity = moment(currentEpochTimestamp * 1000).format('DD.MM.YYYY HH:mm')

  const strikePrice = inverseTrigger.toLocaleString(
    'en',
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  )

  const collateralizationPercent = `${collateralization * 100}%`

  const addressUrl = `${getScanLink(network)}/address/${poolAddress}`

  let chartWidth
  let chartHeight

  if (width >= 1200) {
    chartWidth = '760px'
    chartHeight = '300px'
  } else if (width < 1200 && width >= 991) {
    chartWidth = '420px'
    chartHeight = '300px'
  } else if (width >= 425) {
    chartWidth = '400px'
    chartHeight = '250px'
  } else {
    chartWidth = '300px'
    chartHeight = '200px'
  }

  return (
    <div className='popup-wrapper'>
      <div className='info'>
        <ul>
          <li>
            <div className="info-label">Type</div>
            <div className="info-value">
              <OptionType subtype={subtype} />
            </div>
          </li>
          <li>
            <div className="info-label">Underlying</div>
            <div className="info-value">{underlyingTitle}</div>
          </li>
          <li>
            <div className="info-label">Maturity</div>
            <div className="info-value">{maturity}</div>
          </li>
          <li>
            <div className="info-label">Strike price</div>
            <div className="info-value">${strikePrice}</div>
          </li>
          <li>
            <div className="info-label">Collateral</div>
            <div className="info-value">{marginTitle}</div>
          </li>
          <li>
            <div className="info-label">Collateralization ratio</div>
            <div className="info-value">{collateralizationPercent}</div>
          </li>
          <li>
            <div className="info-label">Oracle</div>
            <div className="info-value">
              {id === 'PRODUCT_PEER_2_POOL_OPTION_OPIUM_V1' ? 'Optimistic' :'ChainLink'}
            </div>
          </li>
          <li>
            <div className="info-label">Address</div>
            <div className="info-value">
              <OpiumLink
                theme={ETheme.DARK}
                label={shortenAddress(poolAddress)}
                href={addressUrl}
                newTab={true}
                className='info-value'
              />
            </div>
          </li>
        </ul>
      </div>

      <div className='chart-wrapper'>
        <div className='chart-title'>Risk analytics</div>
        {(() => {
          switch (volumesStore.productChartLoading) {
            case ELoadingStatuses.PENDING:
              return (
                <div className='chart-loading-wrapper'>
                  <Loading />
                </div>
              )
            case ELoadingStatuses.FAILED:
              return (
                <div className="error chart-loading-error">
                  Something went wrong, please refresh the page
                </div>
              )
            case ELoadingStatuses.SUCCEEDED:
              if (!isCurrentProductChart || !volumesStore.productChart.data) return null
              return (
                <Chart
                  theme={ETheme.DARK}
                  width={chartWidth}
                  height={chartHeight}
                  data={volumesStore.productChart.data.items}
                  domainX={[
                    volumesStore.productChart.data.minPrice,
                    volumesStore.productChart.data.maxPrice,
                  ]}
                  domainY={[
                    volumesStore.productChart.data.minData2,
                    volumesStore.productChart.data.maxData2,
                  ]}
                  labelX={{
                    value: chartInfo?.PNL_CHART?.xLabel,
                    position: 'insideBottom',
                  }}
                  labelY={{
                    value: chartInfo?.PNL_CHART?.yLabel,
                    position: 'insideLeft',
                    angle: -90,
                  }} 
                  chartData2={{ tooltipSuffix: '%', tooltipTitle: 'Buyer' }}
                  increaseDomainY={0.1}
                />
              )
            default:
              return null
          }
        })()}
      </div>
    </div>
  )
}

export default observer(ActivePoolPopupContent)
