import React, { FC, useEffect } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'

import { formatDate } from '../../Utils/helpers'
import { useMobile } from '../../Utils/hooks'
import optionsStore from '../../Stores/OptionsStore'
import LineChart from '../LineChart'
import './styles.scss'

interface IProps {}

const Options: FC<IProps> = ({}: IProps) => {
  const { isMobile } = useMobile()
 
  useEffect(() => {
    optionsStore.getPriceForSkewsForStrikePrice()
    optionsStore.getEthVolatility()
    optionsStore.getSkewsForDate()
  }, [])

  useEffect(() => {
    optionsStore.getSkewsForStrikePrice()
  }, [optionsStore.skewStrikePrices])

  return (
    <div className='options-wrapper page-wrapper'>
      <div className='page-title'>Skew, Implied Volatility and Historical Volatility</div>
      <div className='options-charts-wrapper'>

        <div className='options-skew-charts-wrapper'>
          {(Object.keys(optionsStore.skewsForDate) as Array<keyof typeof optionsStore.skewsForDate>).map(
            (dateKey, index) => {
              const { chartData, domainY } = optionsStore.skewsForDate[dateKey]
              if (chartData.length) {
                return (
                  <LineChart
                    key={index}
                    chartData={chartData}
                    title={`Maturity ${formatDate(optionsStore.skewDates[dateKey], 'DD MMM YYYY')}`}
                    domainY={domainY}
                    height='250px'
                    intervalX={1}
                    tooltipText='The volatility skew is the difference in implied volatility (IV) between out-of-the-money options, at-the-money options, and in-the-money options.'
                  />
                )
              }
            }
          )}
        </div>

        <div className='options-skew-charts-wrapper'>
          {(Object.keys(optionsStore.skewsForStrikePrice) as Array<keyof typeof optionsStore.skewsForStrikePrice>).map(
            (strikePriceKey, index) => {
              const { chartData, domainY } = optionsStore.skewsForStrikePrice[strikePriceKey]
              if (chartData.length) {
                return (
                  <LineChart
                    key={index}
                    chartData={chartData}
                    title={`Strike price $${optionsStore.skewStrikePrices[strikePriceKey]}`}
                    domainY={domainY}
                    height='250px'
                    intervalX={1}
                    tooltipText='The volatility skew is the difference in implied volatility (IV) between out-of-the-money options, at-the-money options, and in-the-money options.'
                    tickFormatterX={(value) => moment(value).format('DD MMM')}
                  />
                )
              }
            }
          )}
        </div>

        {optionsStore.ethVolatility.chartData.length && (
          <LineChart
            chartData={optionsStore.ethVolatility.chartData}
            title='Ethereum historical volatility'
            domainY={optionsStore.ethVolatility.domainY}
            tickFormatterX={(label) => moment(label).format('DD MMM')}
            intervalX={isMobile ? 100 : 25}
            height='350px'
            tooltipText='Historical Volatility is a measure of how much price deviates from its average'
          />
        )}
      </div>
    </div>
  )
}

export default observer(Options)
