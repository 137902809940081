import { ETheme } from '@opiumteam/react-opium-components'
import React, { FC } from 'react'

type TProps = {}

const CoveredCallOption: FC<TProps> = () => {
  return (
    <>
      <div className={`KnowledgeBase__block-title bottom-line color-scheme-${ETheme.DARK}`}>
        Covered Call 
      </div>
      <div className="KnowledgeBase__block-content">
        Covered call is a combination of a short (sold) Call option and a long position in Underlying. By using this combined strategy investor receives an Option premium and is obliged to sell Underlying from his portfolio at a Strike price (which is set to be significantly higher than current price). 
      </div>
      <div className="KnowledgeBase__block-content">
        Thus, it suits investors who are willing to sell Underlying when the market goes up above the Strike and want to enhance their investment returns by Premium received.
      </div>
      <div className="KnowledgeBase__block-content">
        This strategy is the basis for Call option selling Pools. 
      </div>
    </>
  )
}

export default CoveredCallOption
