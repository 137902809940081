export type TOracle = {
  expiry?: string
  price: string
  reportedTimestamp: string,
  source?: string
  chain?: string
  protocol?: string
  asset?: string,
  iconAsset?: string
}

export type TNetworkData = {
  data: string
  timestamp: string
}

export enum EOraclesFilterTab {
 All = 'All',
 Opium = 'Opium',
 Opyn = 'Opyn'
}

export enum EOraclesNetwork {
  matic = 'MATIC',
  ethereum = 'ETHEREUM'
}

export type TOraclesEntity = {
  title: string,
  data: Array<TNetworkData>
}

export type TOraclesCheckbox = {
  title: string,
  value: boolean
}

export enum EOraclesId {
  eth = '0xAF5F031b8D5F12AD80d5E5f13C99249d82AfFfe2',
  bitcoin = '0x3b9c117f0057bcf102729b51464c708f29971980',
  sushi = '0xb38e08796239bd466044530597feb06169aa97db',
  crv = '0x4f6f12b85f565df3ca9029911709c0b5d63c9e5d'
}

export type TOraclesFilterButton = {
  title: string,
  type: EOraclesFilterType
}

export enum EOraclesFilterType {
  Asset = 'Asset',
  Source = 'Source',
  Chain = 'Chain'
 }
