import { observable, action } from 'mobx'
import moment from 'moment'
import { ELoadingStatuses } from '../Constants/Types'
import { TAuctionsResponse } from '../Constants/Types/auctions'
import auctionApi from '../Services/auctionApi'

export class AuctionStore {

    @observable public auctionTableDataLoading: ELoadingStatuses = ELoadingStatuses.IDLE
    @observable public auctionTableData: TAuctionsResponse = []

    // actions 

    @action fetchAuctionsDataForTable: () => Promise<void> = async (): Promise<void> => {
      try {
        const auctions = await auctionApi.getAuctionsData()
        const data = auctions?.map((el: TAuctionsResponse[0]) => ({ ...el, phase: this._getPhaseAuction(el.startDate, el.endDate), asset: el.ticker.split('/')[0].split('-')[3] }))
        this.auctionTableData = this._customSort(data)
        this.auctionTableDataLoading = ELoadingStatuses.SUCCEEDED
      } catch (err: any) {
        console.error(err)
        this.auctionTableDataLoading = ELoadingStatuses.FAILED
      }
    }

    @action setAuctionsDataLoading = (status: ELoadingStatuses) => {
      this.auctionTableDataLoading = status
    }

    private _customSort = (sortableItems: (TAuctionsResponse[0] & {phase: string, asset: string})[]): (TAuctionsResponse[0] & {phase: string, asset: string})[] => {
      const sortOrder = ['Upcoming', 'Active', 'Finished']
      const finished = sortableItems.filter(f => f.phase === 'Finished').sort((a, b) => {
        return b.endDate - a.endDate
      })
      const noFinished = sortableItems.filter(f => f.phase !== 'Finished')
      const sorted = [...noFinished, ...finished]
      return sorted.sort((a, b) => { 
        if (a.phase == b.phase) { 
          return a.phase.localeCompare(b.phase)
        } else { 
          return sortOrder.indexOf(a.phase) - sortOrder.indexOf(b.phase) 
        }
      })
    }

    private _getPhaseAuction = (date1: number, date2: number) => {
      const date = new Date()
      const today = moment(date).unix()
      if (date1 === 0) return 'Upcoming'
      if (date1 < today && date2 === 0) return 'Active'
      return date1 > today ? 'Upcoming' : date2 < today ? 'Finished' : 'Active'
    }

}

export default new AuctionStore()

