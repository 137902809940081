import moment from 'moment'
import DeribitApi from './deribitApi'
import CoingeckoApi from './coingeckoApi'
import OpiumApi from './opiumApi'
import { EDirection } from '../Utils/sorting'

export class DataStore {
  getSkewDates = () => {
    const tomorrow = moment().add(1, 'days')
    const dayAfterTomorrow = moment().add(2, 'days')
    const todayIso = moment().isoWeekday()
    const dayAfterTomorrowIso = dayAfterTomorrow.isoWeekday()
    const tomorrowIso = tomorrow.isoWeekday()
    let nextFriday = moment().isoWeekday(5)
    if (todayIso > 4 || dayAfterTomorrowIso > 4 || tomorrowIso > 4) {
      nextFriday = moment().add(1, 'week').isoWeekday(5)
    }
    return {
      tomorrow: tomorrow.unix(),
      dayAfterTomorrow: dayAfterTomorrow.unix(),
      nextFriday: nextFriday.unix(),
    }
  }

  getHistoricalVolatility = async (currency: string) => {
    const volatilityData = await DeribitApi.getHistoricalVolatility(currency)
    return volatilityData
  }

  getTokenPrices = async () => {
    const data = await CoingeckoApi.getPrices()
    return data
  }

  getOracles = async (page: number, asset?: string, source?: string, chain?: string, sortKey?: string, direction?: EDirection) => {
    return await OpiumApi.getOracles(page, asset, source, chain, sortKey, direction)
  }
}

export default new DataStore()
