import React, { useMemo, useState } from 'react'
import { convertFromBN } from './bn'

export enum EDirection {
    ascending = 'ascending',
    descending = 'descending'
  }

export type TSortConfig = {
    key: string, direction: EDirection
}

// obj,['1','2','3'] -> ((obj['1'])['2'])['3']
export const getValue = (obj: {}, arrPath: string[]) => (
  arrPath.reduce((x: any, y: any) => {
    if (y in x) return x[y]
    return {}
  }, obj)
)
  
export const useSortableData = (items: any[], isCustomSort: {key: string, customSort: Function } | null = null, config = null) => {
  const [sortConfig, setSortConfig] = useState<TSortConfig | null>(config)
  
  const sortedItems = useMemo(() => {
    let sortableItems = [...items]
    if (sortConfig !== null) {
      if (isCustomSort && isCustomSort.key === sortConfig.key) {
        sortableItems = isCustomSort.customSort(sortableItems, sortConfig)
      } else {
        sortableItems.sort((a, b) => {
          const keyA = sortConfig.key.includes('.') ? getValue(a, sortConfig.key.split('.')) : a[sortConfig.key]
          const keyB = sortConfig.key.includes('.') ? getValue(b, sortConfig.key.split('.')) : b[sortConfig.key]
    
          if (keyA < keyB) {
            return sortConfig.direction === EDirection.ascending ? -1 : 1
          }
          if (keyA > keyB) {
            return sortConfig.direction === EDirection.ascending ? 1 : -1
          }
          return 0
        })
      }
    }
    return sortableItems
  }, [items, sortConfig])
  
  const requestSort = (key: string) => {
    let direction = EDirection.ascending
    if (
      sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === EDirection.ascending
    ) {
      direction = EDirection.descending
    }
    setSortConfig({ key, direction })
  }
  
  return { items: sortedItems, requestSort, sortConfig }
}
