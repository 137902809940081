import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Loading, Button } from '@opiumteam/react-opium-components'
import moment from 'moment'
import numeral from 'numeral'
import { useHistory } from 'react-router-dom'

import { useMobile, useTablet } from '../../Utils/hooks'
import { formatTokenName } from '../../Utils/helpers'
import { TVolumesChartDataItem } from '../../Constants/Types/volumes'
import { ELoadingStatuses } from '../../Constants/Types'
import volumesStore from '../../Stores/VolumesStore'

import ActivePoolsTable from './ActivePools/ActivePoolsTable'
import CoveredCallTable from './CoveredCallStrategy/CoveredCallTable'
import BarChart from '../BarChart'

import './styles.scss'

type TInterval = '24h' | '7d' | '30d'

interface IProps {}

const Volumes: FC<IProps> = ({}: IProps) => {
  const { isMobile } = useMobile()
  const { isTablet } = useTablet()
  const history = useHistory()

  const [activeTabId, setActiveTabId] = useState<string>('24h')

  useEffect(() => {
    volumesStore.getVolumes()
    volumesStore.getPopulatedStakings()
  }, [])

  useEffect(() => {
    volumesStore.getOpiumVolumes()
  }, [])

  const tabs: {
    id: TInterval
    title: string
  }[] = [
    { id: '24h', title: '24H' },
    { id: '7d', title: '7D' },
    { id: '30d', title: '30D' },
  ]

  const switchTab = (tab: string): void => setActiveTabId(tab)

  const chartData: {
    interval: TInterval
    data: TVolumesChartDataItem[]
    title: string
  }[] = [
    {
      interval: '24h',
      data: volumesStore.volumes.dailyData || [],
      title: '24h centralized trading volumes',
    },
    {
      interval: '7d',
      data: volumesStore.volumes.weeklyData || [],
      title: '7d centralized trading volumes',
    },
    {
      interval: '30d',
      data: volumesStore.volumes.monthlyData || [],
      title: '30d centralized trading volumes',
    },
  ]

  const activeChart = chartData.find((item) => item.interval === activeTabId)

  const opiumVolumesChartData = volumesStore.opiumVolumes.map(item => {
    const formattedDate = moment.unix(+item.date).format('MMM Y')

    return {
      label: formattedDate,
      barData0: item.totalVolume,
      barData1: item.projectedVolume,
      barLabel0: formattedDate,
      barLabel1: 'Projected',
      customTooltip: (
        <div className="volumes-chart-tooltip">
          <div className="volumes-chart-tooltip-date">{formattedDate}</div>

          <ul>
            {Object.keys(item.tokenVolumesByDate).map((tokenId, index) => (
              <li key={index} className="tooltip-field">
                <span className="tooltip-label">{formatTokenName(tokenId)}</span>
                <span className="tooltip-value">
                  ${numeral(item.tokenVolumesByDate[tokenId]).format('0[.]0a')}
                </span>
              </li>
            ))}
          </ul>

          <div className="volumes-chart-tooltip-total tooltip-field">
            <span className="tooltip-label">Total</span>
            <span className="tooltip-value">
              ${numeral(item.totalVolume).format('0[.]0a')}
            </span>
          </div>

          {item.projectedVolume !== 0 && (
            <div className="volumes-chart-tooltip-projected tooltip-field">
              <span className="tooltip-label">Projected</span>
              <span className="tooltip-value">
                ${numeral(item.projectedVolume).format('0[.]0a')}
              </span>
            </div>
          )}
        </div>
      )
    }
  })

  return (
    <div className='volumes page-wrapper'>
      <div className="page-title">Comparing DeFi and CeFi trading volumes</div>

      <div className="page-text">
        CeFi data comes from Deribit. DeFi data is on-chain aggregation from Opium Protocol contracts; more data is coming soon. If you would like to include your project’s data, please apply at the community{' '}
        <a href='https://forum.opium.network/' target='__blank'>forum</a>
      </div>

      <div className='volumes-charts-wrapper'>
        {volumesStore.opiumVolumesLoading === ELoadingStatuses.PENDING ? (
          <div className="defi-volumes-loading">
            <Loading />
          </div>
        ) : (
          volumesStore.opiumVolumesLoading === ELoadingStatuses.FAILED ? (
            <div className="error">Something went wrong, please refresh the page</div>
          ) : (
            <BarChart
              chartData={opiumVolumesChartData}
              title='Monthly volumes, Opium Protocol'
              hideSecondBar
              barSize={(isMobile || isTablet) ? 20 : 30}
              source={{ 
                title: 'Opium Finance',
                link: 'https://opium.finance'
              }}
            />
          )
        )}

        {(() => {
          if (volumesStore.volumesLoading === ELoadingStatuses.FAILED) {
            return (
              <div className="error">Something went wrong, please refresh the page</div>
            )
          }
          if (activeChart) {
            return (
              <BarChart
                chartData={activeChart.data}
                title={activeChart.title}
                tabs={tabs}
                activeTabId={activeTabId}
                switchTab={switchTab}
                barSize={(isMobile || isTablet) ? 30 : 50}
                source={{ 
                  title: 'Deribit',
                  link: 'https://www.deribit.com/'
                }}
              />
            )
          }
        })()}
      </div>

      <div className="page-title">Live contracts on the Opium Protocol</div>

      {(() => {
        if (volumesStore.populatedStakingsLoading === ELoadingStatuses.PENDING) {
          return (
            <div className="active-pools-loading-wrapper">
              <Loading />
            </div>
          )
        }
        if (volumesStore.populatedStakingsLoading !== ELoadingStatuses.SUCCEEDED) {
          return null
        }
        if (!volumesStore.activePools.length) {
          return <div className="message">No data</div>
        }

        return (
          <>
            <ActivePoolsTable activePools={volumesStore.activePools} />
            <div className='view-more-on-explorer-wrapper'>
              <Button label='view more on explorer' onClick={() => history.push('/derivative')} variant='secondary'/>
            </div>
            <div className="page-title">Combined trading strategies</div>

            <div className="page-text">
              Combined strategies earn yield on staked tokens by running a weekly/monthly automated trading strategy, for example issuing options. At rebalancing points yield is reinvested back into the strategy.
              Such strategies are normally called “structured products” allowing to earn extra yield with a specific market view. They are not risk free, you should read and fully understand associated risks.
            </div>
    
            <CoveredCallTable coveredCalls={volumesStore.coveredCalls} />
          </>
        )
      })()}
    </div>
  )
}

export default observer(Volumes)
