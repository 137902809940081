import { Button, ETheme } from '@opiumteam/react-opium-components'
import React, { FC } from 'react'
import { TSortConfig } from '../../Utils/sorting'

import './styles.scss'

type THeader = {
    name: string,
    label: string,
    sort?: boolean,
    class?: string
}
interface IProps {
    headers: THeader[],
    requestSort: Function,
    sortConfig: TSortConfig | null
  }

const SortHeaderTable: FC<IProps> = ({ headers, requestSort, sortConfig }: IProps) => {

  const getClassNames = (name: string) => {
    if (!sortConfig) return
    return sortConfig.key === name ? sortConfig.direction : undefined
  }

  return (
    <thead>
      <tr>
        {headers.map(header => (
          <th key={header.name + '-' + header.label} className={`${header.class ? header.class : ''}`}>
            {header.sort ? (<Button
              theme={ETheme.DARK}
              variant={'minimal'}
              className="btn-sort"
              onClick={() => requestSort(header.name)}
              label={<div className='btn-sort-label'>{header.label}
                <svg className={`${getClassNames(header.name)}`} width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 5L0.535899 0.499999L7.4641 0.5L4 5Z" fill="#999BBC"/>
                </svg></div>}
            />)
              : header.label }
          </th>
        ))}</tr>
    </thead>
  )
}

export default SortHeaderTable
