import React, { FC, ReactNode } from 'react'
import { observer } from 'mobx-react'

import { Popup, Button, ETheme } from '@opiumteam/react-opium-components'

import './styles.scss'
import constructorStore from '../../../../Stores/ConstructorStore'

interface IProps {
    priceText: ReactNode
  }

const DerivativeBidAskPopup: FC<IProps> = ({ priceText }) => {
    
  return (
    <Popup 
      closePopup={() => constructorStore.showBidPopup ? constructorStore.setShowBidPopup(false) : constructorStore.setShowAskPopup(false)}
      popupIsOpen={constructorStore.showBidPopup || constructorStore.showAskPopup}
      title={`Buy ${constructorStore.showBidPopup ? 'Short position ' : 'Long position '} on 1inch`}
      className={'bid-ask-popup'}
      component={
        <div className={`bid-ask-popup-wrapper color-scheme-${ETheme.DARK}`}>
          {/* <div className='bid-ask-title'><span>{constructorStore.showBidPopup ? 'Short position ' : 'Long position '}</span> on 1inch</div> */}
          <div className='bid-ask-content'>{priceText}</div>
          <div className="actions-button">
            <Button 
              className="green"
              size="sm"
              href={'https://app.opium.finance/arb/derivative'}
              variant={'secondary'}
              style={{ marginTop: '1rem', width: 'calc(50% - 16px)', marginRight: '3rem' }}
              label='Buy on Opium Finance'
              newTab
              onClick={() => {}}
            />
            <Button 
              size="sm"
              variant={'secondary'}
              style={{ marginTop: '1rem', width: 'calc(50% - 16px)' }}
              label='close'
              onClick={() => constructorStore.showBidPopup ? constructorStore.setShowBidPopup(false) : constructorStore.setShowAskPopup(false)}
            />
          </div>
        </div>
      }
    />
  )
}

export default observer(DerivativeBidAskPopup)
