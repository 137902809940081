import React, { FC, Fragment, ReactNode, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, ETheme, Loading, Tooltip, Pagination, SelectMultiple, SearchBox } from '@opiumteam/react-opium-components'
import numeral from 'numeral'
import moment from 'moment'
import constructorStore from '../../../../Stores/ConstructorStore'
import { EDerivativesFilterType, TAllDerivativesResponse, TDerivativesFilterButton, EPosition } from '../../../../Constants/Types/constructor'

import './styles.scss'

import { useDataTable, useRefreshAnimation } from '../../../../Utils/hooks'
import { ELoadingStatuses } from '../../../../Constants/Types'
import { networkLogos } from '../../../../Utils/helpers'
import DerivativeBidAskPopup from '../DerivativeBidAskPopup'
import { useSortableData } from '../../../../Utils/sorting'
import SortHeaderTable from '../../../SortHeaderTable'

type TProps = {
  handlerSearchIdentifier: Function
}

const DerivativeTable: FC<TProps> = ({ handlerSearchIdentifier }) => {
  const [activePremiumText, setActivePremiumText] = useState<ReactNode>(null)
  const [removedItem, setRemovedItem] = useState<TDerivativesFilterButton | null>(null)
  const pageSize: number = 11
  const [currentPage, setCurrentPage] = useState<number>(1)
  const { items, requestSort, sortConfig } = useSortableData(constructorStore.derivativesList)
  const { slice } = useDataTable(items, pageSize, currentPage)

  const { animation, updateTable, refreshDataTable } = useRefreshAnimation(constructorStore.fetchDerivativesData, constructorStore.setDerivativesDataLoading, constructorStore.derivativesDataLoading)

  const headers = [
    { name: 'item.underlying.title', label: 'Underlying', sort: false },
    { name: 'protocol', label: 'Protocol', sort: false },
    { name: 'ticker', label: 'Ticker', sort: false },
    { name: 'derivativeType', label: 'Derivative type', sort: false },
    { name: 'collateralization', label: 'Collateral', sort: true, class: 'oracles-th' },
    { name: 'totalSupply', label: 'Total supply', sort: true, class: 'oracles-th' },
    { name: 'bidSize', label: 'Bid size', sort: false },
    { name: 'bid', label: 'Bid', sort: false },
    { name: 'ask', label: 'Ask', sort: false },
    { name: 'askSize', label: 'Ask size', sort: false },
    { name: 'chain', label: 'Chain', sort: false },
  ]

  const now = moment().unix()
  const [height, setHeight] = useState<number>(0)
  const tdRef = useRef<HTMLTableCellElement>(null)

  const tdHeight = () => {
    setHeight(tdRef.current?.clientHeight || 60)
  }

  useEffect(() => {
    window.addEventListener('resize', tdHeight)
    tdHeight()

    return () => {
      window.removeEventListener('resize', tdHeight)
    }
  })

  useEffect(() => {
    (!slice.length && constructorStore.derivativesList.length) && setCurrentPage(currentPage - 1)
  }, [slice])

  const countItems = slice.filter((item: TAllDerivativesResponse[0]) => item.endTime > now).length

  const getTextDerivativeType = (type: string) => {
    const table: any = {
      OPTION_CALL: 'Option call',
      OPTION_PUT: 'Option put',
      SYNTH: 'Synthetic',
      CALL_SPREAD: 'Call spread',
      PUT_SPREAD: 'Put spread'
    }
    return table[type]
  }

  const removeFilter = (filter: TDerivativesFilterButton) => {
    filter.type === EDerivativesFilterType.Times 
      ? constructorStore.setTimesFilters(filter.title ? filter.title : '')
      : constructorStore.setOptionsFilters(filter.title ? filter.title : '') 
    setRemovedItem(filter)
  }

  const openBidPopup = (e: any, item: TAllDerivativesResponse[0]) => {
    e.stopPropagation()
    setActivePremiumText(renderBidPrice(item))
    constructorStore.setShowBidPopup(true)
  }

  const openAskPopup = (e: any, item: TAllDerivativesResponse[0]) => {
    e.stopPropagation()
    setActivePremiumText(renderAskPrice(item))
    constructorStore.setShowAskPopup(true)
  }

  const formatPrice = (price: number) => {
    return parseFloat(numeral((price)).format('0[.]00'))
  } 

  const renderAskPrice = (item: TAllDerivativesResponse[0]) => {
    const difference: number = (item.ask ? item.ask : 0) - item.longPosition.collateral

    if ( item.longPosition.collateral === 0 ) {
      return (
        <>
          {item.ask ? <>
            <p className={`tooltip-bold ${difference > 0 ? 'red-box' : 'green-box'}`}>{difference > 0 ? 'To pay:' : 'To receive:'}</p>
            <div className={`${difference > 0 ? 'tooltip-red-border' : 'tooltip-green-border'}`}>{formatPrice(difference)} {item.token.title}</div>
            <div className={'tooltip-total tooltip-indent'}><span>{difference > 0 ? 'Total to pay' : 'Total to receive'}</span> <span className={difference > 0 ? 'red' : 'green'}>{formatPrice(item.ask)} {item.token.title}</span></div>
          </> : ''} 
        </>
      )
    }

    return (
      <>
        {item.ask ? <>
          <p className='tooltip-bold red-box'>To pay:</p>
          <div className='tooltip-red-border'>{formatPrice(item.longPosition.collateral)} {item.token.title} Locked collateral*</div>
          <div className='tooltip-red-border'>{formatPrice(item.ask)} {item.token.title} Locked collateral*</div>
          {difference > 0 && <div className='tooltip-red-border'>{formatPrice(difference)} {item.token.title}</div>}

          <p className='tooltip-bold green-box tooltip-indent'>To receive:</p>
          {difference < 0 && <div className='tooltip-green-border'>{formatPrice(difference)} {item.token.title}</div>}

          <div className={'tooltip-total tooltip-indent'}><span>{difference < 0 ? 'Total to pay' : 'Total to receive'}</span> <span className={difference < 0 ? 'red' : 'green'}>{formatPrice(item.ask)} {item.token.title}</span></div>
          {item.longPosition.collateral !== 0 && <p><p className='tooltip-note'>*with this position you lock collateral until maturity <br/> on the maturity you will receive unlocked collateral ± profit <br/> and loss on the position</p></p>}
        </> : ''} 
      </>
    )
  }

  const renderBidPrice = (item: TAllDerivativesResponse[0]) => {
    const difference: number = item.shortPosition.collateral - (item.bid ? item.bid : 0)

    if ( item.shortPosition.collateral === 0 ) {
      return (
        <>
          {item.bid ? <>
            <p className={`tooltip-bold ${difference < 0 ? 'red-box' : 'green-box'}`}>{difference < 0 ? 'To pay:' : 'To receive:'}</p>
            <div className={`${difference > 0 ? 'tooltip-red-border' : 'tooltip-green-border'}`}>{formatPrice(difference)} {item.token.title}</div>
            <div className={'tooltip-total tooltip-indent'}><span>{difference > 0 ? 'Total to pay' : 'Total to receive'}</span> <span className={difference > 0 ? 'red' : 'green'}>{formatPrice(item.bid)} {item.token.title}</span></div>
          </> : ''} 
        </>
      )
    }

    return (
      <>
        {item.bid ? <>
          <p className='tooltip-bold red-box'>To pay:</p>
          {difference < 0 && <div className='tooltip-red-border'>{formatPrice(item.bid)} {item.token.title}</div>}
          <div className='tooltip-red-border'>{formatPrice(item.shortPosition.collateral)} {item.token.title} Locked collateral*</div>

          <p className='tooltip-bold green-box tooltip-indent'>To receive:</p>
          {difference > 0 && <div className='tooltip-green-border'>{formatPrice(difference)} {item.token.title}</div>}

          <div className={'tooltip-total tooltip-indent'}><span>{difference > 0 ? 'Total to pay' : 'Total to receive'}</span> <span className={difference > 0 ? 'red' : 'green'}>{formatPrice(item.bid)} {item.token.title}</span></div>
          {item.shortPosition.collateral !== 0 && <p><p className='tooltip-note'>*with this position you lock collateral until maturity <br/> on the maturity you will receive unlocked collateral ± profit <br/> and loss on the position</p></p>}
        </> : ''} 
      </>
    )
  }

  return (
    <>
      <DerivativeBidAskPopup priceText={activePremiumText}/>

      <div className='oracles-filters-buttons'>
        {constructorStore.arrayAllFilters?.map((filter: TDerivativesFilterButton, key: number) => {
          return (
            <div className='oracles-button' key={filter.title + key}>
              <Button 
                theme={ETheme.DARK}
                variant={'minimal'}
                label={<div className='oracles-remove-btn'><div className='label'>{filter.title}</div>
                  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="1.06066" y1="1.05609" x2="14.9438" y2="14.9393" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                    <line x1="1.43799" y1="14.8832" x2="15.3212" y2="1.00001" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                  </svg></div>
                }
                className="filters-remove-button"
                onClick={() => removeFilter(filter)}
              />
            </div>
          )
        })}
      </div>

      <div className='oracles-filters-wrapper derivatives-filters-wrapper'>
        {(constructorStore.timesCheckboxList.length && constructorStore.optionsCheckboxList.length) && (
          <>
            <div className='filter-wrapper'>
              <SelectMultiple 
                items={constructorStore.timesCheckboxList}
                onSelect={(title: string) => constructorStore.setTimesFilters(title ? title : '')}
                placeholder={`${EDerivativesFilterType.Times}`}
                removedItem={removedItem?.type === EDerivativesFilterType.Times ? removedItem.title : null}
              />
            </div>

            <div className='filter-wrapper'>
              <SelectMultiple 
                items={constructorStore.optionsCheckboxList}
                onSelect={(title: string) => constructorStore.setOptionsFilters(title ? title : '')}
                placeholder={`${EDerivativesFilterType.Options}`}
                removedItem={removedItem?.type === EDerivativesFilterType.Options ? removedItem.title : null}
              />
            </div>

            <div className='block-search'>
              <SearchBox
                theme={ETheme.DARK}
                onClick={(e) => constructorStore.setFilterSearch(e)}
                onChange={(e) => constructorStore.setFilterSearch(e)}
                value={constructorStore.filter}
                placeholder="Search"
              /> 
            </div>

            <div className="derivatives-tab-section derivatives-animation-block">

              <button className='derivatives-tabs-anim-svg' onClick={refreshDataTable}>
                {updateTable ? 'Refreshing...' : 'Refresh'}
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ visibility: !updateTable ? 'visible': 'hidden' }}>
                  <circle cx="7.5" cy="7.5" r="6.5" stroke="#5D5F7C" strokeWidth="1.5"/>
                  <circle cx="7.5" cy="7.5" r="6.5" stroke="white" strokeWidth="1.5" className={`${animation ? 'derivatives-dash': undefined}`}/>
                </svg>

                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ visibility: updateTable ? 'visible': 'hidden' }} className="circle-svg">
                  <circle cx="7.5" cy="7.5" r="6.5" stroke="#5D5F7C" strokeWidth="1.5"/>
                  <circle cx="7.5" cy="7.5" r="6.5" stroke="white" strokeWidth="1.5" className={ `${updateTable ? 'derivatives-circle': undefined}`} />
                </svg>
              </button>

            </div>
          </>
        )}
      </div>

      {(() => {
        if (constructorStore.derivativesDataLoading === ELoadingStatuses.IDLE) {
          return (
            <div className="active-pools-loading-wrapper">
              <Loading />
            </div>
          )
        }

        if (!slice.length) {
          return <div className="message">There are no Derivatives based on the selected filters</div>
        }
        return (
          <>
            <div className='DerivativeTable'>
              <table className='transparent-table derivative-table'>
                <SortHeaderTable headers={headers} requestSort={requestSort} sortConfig={sortConfig} />

                <tbody>
                  {slice?.map((item: TAllDerivativesResponse[0], i: number) => (
                    <Fragment key={i}><tr className='derivative-item-wrapper' >
                      <td>
                        <Tooltip
                          theme={ETheme.DARK}
                          label={''}
                          trigger='hover'
                          placement='top'
                          content={item.underlying.title}
                          html={true}
                          component={<img className="derivative-item-icon" src={item.underlying.image} />}
                        />
                      </td>
                      <td>{item.protocol}</td>
                      <td><div className="derivative-item-actions" onClick={() => handlerSearchIdentifier(item.derivativeHash)}>{item.ticker}</div></td>
                      <td>{getTextDerivativeType(item.derivativeType)}</td>
                      <td>{parseFloat((item.collateralization * 100).toFixed(2))}%, {item.token.title}</td>
                      <td>{item.totalSupply}</td>
                      <td>{item.endTime < now ? '-' : item.bidSize ? +item.bidSize.toFixed(5) : '-'}</td>
                      <td colSpan={item.endTime < now ? 2 : 1} className={`${item.endTime < now ? 'derivative-join-column' : ''}`}>
                        { item.endTime < now ? <div className='derivative-expired'>expired</div>
                          : <><Tooltip
                            theme={ETheme.DARK}
                            label={''}
                            trigger='hover'
                            placement='top'
                            className='derivatives'
                            content={
                              <div className={`derivatives-btn-tooltip color-scheme-${ETheme.DARK}`}>
                                <div className='tooltip-title'>Short position</div>
                                <div className='tooltip-content'>{renderBidPrice(item)}</div>
                              </div>
                            }
                            component={
                              <Button
                                theme={ETheme.DARK}
                                variant={'secondary'}
                                label={item.bid !== null ? `${formatPrice(item.bidPosition === EPosition.LONG ? item.bid - item.longPosition.collateral : item.shortPosition.collateral - item.bid)} ${item.token.title}` : '-'}
                                className="item-box item-green green"
                                onClick={(e: any ) => openBidPopup(e, item) }
                                size="sm"
                                newTab
                              />
                            }
                          />
                          <div className="derivative-item-collateral">
                            {item.shortPosition.collateral ? <><p>Locked:</p>&nbsp;<p>{+item.shortPosition.collateral.toFixed(2)} {item.token.title}</p></> : ''}
                          </div></>}
                      </td>
                      {item.endTime > now && <td>
                        <Tooltip
                          theme={ETheme.DARK}
                          label={''}
                          trigger='hover'
                          placement='top'
                          className='derivatives'
                          content={
                            <div className={`derivatives-btn-tooltip color-scheme-${ETheme.DARK}`}>
                              <div className='tooltip-title'>Long position</div>
                              <div className='tooltip-content'>{renderAskPrice(item)}</div>
                            </div>
                          }
                          component={
                            <Button
                              theme={ETheme.DARK}
                              variant={'secondary'}
                              label={item.ask !== null ? `${formatPrice(item.askPosition === EPosition.LONG ? item.ask - item.longPosition.collateral : item.shortPosition.collateral - item.ask)} ${item.token.title}` : '-'}
                              className="item-box item-red"
                              onClick={(e: any) => openAskPopup(e, item)}
                              size="sm"
                              newTab
                            />
                          }
                        />
                        <div className="derivative-item-collateral">
                          {item.longPosition.collateral ? <><p>Locked:</p>&nbsp;<p>{+item.longPosition.collateral.toFixed(2)} {item.token.title}</p></> : ''}
                        </div>
                      </td>}
                      <td className={`${item.endTime > now ? 'bg-column' : '' } `}>{item.askSize || '-'}</td>
                      <td ref={tdRef}>
                        <Tooltip
                          theme={ETheme.DARK}
                          label={''}
                          trigger='hover'
                          placement='top'
                          content={item.chain && (item.chain[0].toUpperCase() + item.chain.slice(1))}
                          html={true}
                          component={<img className="derivative-item-icon" src={networkLogos(item.chain)}/>}
                        /></td>
                    </tr>
                    {(item.endTime > now && i === (countItems - 1)) && <tr style={{ height }}><td colSpan={11}></td></tr>}</Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )
      })()}
      <Pagination 
        theme={ETheme.DARK}
        currentPage={currentPage}
        totalCount={constructorStore.derivativesList.length}
        siblingCount={1}
        pageSize={pageSize}
        onPageChange={(page: number) => setCurrentPage(page)}
      />
    </>
  )
}

export default observer(DerivativeTable)
