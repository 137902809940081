import { observable, action } from 'mobx'

class AppStore {
    @observable isOpenMobileMenu: boolean = false

    @action setStateMobileMenu: (value: boolean) => void = (value: boolean) => {
      this.isOpenMobileMenu = value
    }
}

export default new AppStore()
