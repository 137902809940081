const baseEndpoints = {
  development: {
    eth: 'api-rinkeby.opium.finance',
    bsc: 'api-bsc-testnet.opium.finance',
    matic: 'api-mumbai.opium.finance',
    arbitrum: 'api-arbitrum-testnet.opium.finance'
  },
  production: {
    eth: 'api.opium.finance',
    bsc: 'api-bsc.opium.finance',
    matic: 'api-matic.opium.finance',
    arbitrum: 'api-arbitrum.opium.finance'
  },
  stage: {
    eth: 'api-stage.opium.finance',
    bsc: 'api-bsc-stage.opium.finance',
    matic: 'api-matic-stage.opium.finance',
    arbitrum: 'api-arbitrum-stage.opium.finance'
  },
}

// Api Env
const apiVersion = process.env.REACT_APP_API_VERSION || 'v1'
const https = process.env.REACT_APP_SSL_ENABLED === 'false' ? 'http' : 'https'
const wss = process.env.REACT_APP_SSL_ENABLED === 'false' ? 'ws' : 'wss'

const ethApiBase = process.env.REACT_APP_API_ENDPOINT || baseEndpoints.production.eth
const binanceApiBase = process.env.REACT_APP_BINANCE_API_ENDPOINT || baseEndpoints.production.bsc
const maticApiBase = process.env.REACT_APP_MATIC_API_ENDPOINT || baseEndpoints.production.matic
const opiumWatchApiBase = process.env.REACT_APP_OPIUM_WATCH_API_ENDPOINT || 'api.opium.watch'
const arbitrumApiBase = process.env.REACT_APP_ARBITRUM_API_ENDPOINT || baseEndpoints.stage.arbitrum

const ethEndpoint = `${https}://${ethApiBase}/${apiVersion}`
const binanceEndpoint = `${https}://${binanceApiBase}/${apiVersion}`
const maticEndpoint = `${https}://${maticApiBase}/${apiVersion}`
const arbitrumEndpoint = `${https}://${arbitrumApiBase}/${apiVersion}`
const opiumWatchEndpoint = `${https}://${opiumWatchApiBase}/${apiVersion}`

export default {
  apiVersion,
  https,
  wss,
  ethEndpoint,
  binanceEndpoint,
  maticEndpoint,
  opiumWatchEndpoint,
  arbitrumEndpoint
}
