import { observable, action } from 'mobx'
import dataStore from '../Services/dataStore'
import deribitApi from '../Services/deribitApi'

type TChartDataItem = {
  label: string
  lineData: number
}

type TSkewData = {
  chartData: TChartDataItem[]
  domainY: number[]
}

enum ECurrencies {
  ETH = 'ETH',
}

const defaultSkewData = {
  chartData: [],
  domainY: [],
}

class OptionsStore {
  currency: ECurrencies = ECurrencies.ETH
  interval: number = 200

  skewDates: {
    tomorrow: number
    dayAfterTomorrow: number
    nextFriday: number
  } = dataStore.getSkewDates()

   skewStrikePrices: any = {}

  @observable ethVolatility: TSkewData = defaultSkewData

  @observable skewsForDate: {
    tomorrow: TSkewData
    dayAfterTomorrow: TSkewData
    nextFriday: TSkewData
  } = {
    tomorrow: defaultSkewData,
    dayAfterTomorrow: defaultSkewData,
    nextFriday: defaultSkewData,
  }

  @observable skewsForStrikePrice: {
    [key: string]: TSkewData
  } = {}

  @action getPriceForSkewsForStrikePrice: () => void = async (): Promise<void> => {
    try {
      const price = await deribitApi.getIndexPrice(`${this.currency.toLocaleLowerCase()}_usd`)
      this.skewStrikePrices = {
        [Math.round((price - this.interval)/100)*100]: Math.round((price - this.interval)/100)*100,
        [Math.round((price)/100)*100]: Math.round((price)/100)*100,
        [Math.round((price + this.interval)/100)*100]: Math.round((price + this.interval)/100)*100
      }
    } catch (err) {
      console.error(err)
    }
  }

  @action getEthVolatility: () => void = async (): Promise<void> => {
    if (this.ethVolatility.chartData.length) return

    try {
      const response = await dataStore.getHistoricalVolatility(this.currency)
      this.ethVolatility = {
        chartData: response.data,
        domainY: response.domainY as any,
      }
    } catch (err) {
      console.error(err)
    }
  }

  @action getSkewsForDate: () => void = async () => {
    const dateKeys = Object.keys(this.skewDates) as Array<keyof typeof this.skewDates>

    for (let dateKey of dateKeys) {
      if (this.skewsForDate[dateKey].chartData.length) return

      try {
        const result = await deribitApi.getSkewForDate({
          currency: this.currency,
          date: this.skewDates[dateKey],
        })
        
        this.skewsForDate[dateKey] = {
          chartData: result.data as any,
          domainY: result.domainY as any,
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  @action getSkewsForStrikePrice: () => void = async () => {
    const strikePriceKeys = Object.keys(this.skewStrikePrices) as Array<
      keyof typeof this.skewsForStrikePrice
    >

    for (let strikePriceKey of strikePriceKeys) {
      this.skewsForStrikePrice[strikePriceKey] = defaultSkewData 
      if (this.skewsForStrikePrice[strikePriceKey].chartData.length) return

      try {
        const result = await deribitApi.getSkewForStrikePrice({
          currency: this.currency,
          strikePrice: this.skewStrikePrices[strikePriceKey],
        })

        this.skewsForStrikePrice[strikePriceKey] = {
          chartData: result.data as any,
          domainY: result.domainY as any,
        }
      } catch (err) {
        console.error(err)
      }
    }
  }
}

export default new OptionsStore()
