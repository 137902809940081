import React, { useState, useEffect, useMemo, useCallback, useRef, CSSProperties } from 'react'
import { Scrollbar, ScrollbarProps } from 'react-scrollbars-custom'
import './styles.scss'

interface IPropsType {
    autoHide?: boolean
    hideTimeout?: number,
    trackYProps: {style: CSSProperties},
    thumbYProps: {style: CSSProperties},
}

const ScrollArea = (props: IPropsType & ScrollbarProps) => {
  const {
    autoHide,
    children,
    hideTimeout = 500,
    trackYProps,
    thumbYProps,
  } = props

  const [isScrolling, setIsScrolling] = useState<boolean>()
  const [isMouseOver, setIsMouseOver] = useState<boolean>()

  const trackStyle = useMemo(
    () => ({
      opacity: autoHide && !isScrolling ? 0 : 1,
      transition: 'opacity 0.4s ease-in-out',
      background: 'none',
      ...trackYProps.style,
    } as React.CSSProperties),
    [autoHide, isScrolling]
  )

  const stopTimer = useRef<any>()

  const showTrack = useCallback(() => {
    clearTimeout(stopTimer.current)
    setIsScrolling(true)
  }, [stopTimer])

  const hideTrack = useCallback(() => {
    stopTimer.current = setTimeout(() => {
      setIsScrolling(false)
    }, hideTimeout)
  }, [stopTimer, hideTimeout])

  const thumbYStyle = useMemo(
    () => ({
      position: 'relative',
      ...thumbYProps.style
    } as React.CSSProperties),
    []
  )

  const wrapperStyle = useMemo(
    () => ({
      right: 0,
      bottom: 0
    } as React.CSSProperties),
    []
  )

  const thumbXStyle = useMemo(
    () => ({
      position: 'relative'
    } as React.CSSProperties),
    []
  )

  const onScrollStart = useCallback(() => {
    if (autoHide) {
      showTrack()
    }
  }, [autoHide, showTrack])

  const onScrollStop = useCallback(() => {
    if (autoHide && !isMouseOver) {
      hideTrack()
    }
  }, [autoHide, hideTrack, isMouseOver])

  const onMouseEnter = useCallback(() => {
    if (autoHide) {
      showTrack()
      setIsMouseOver(true)
    }
  }, [autoHide, showTrack])

  const onMouseLeave = useCallback(() => {
    if (autoHide) {
      hideTrack()
      setIsMouseOver(false)
    }
  }, [autoHide, hideTrack])

  useEffect(() => {
    return () => clearTimeout(stopTimer.current)
  }, [])

  return (
    <Scrollbar
      thumbYProps={{ style: thumbYStyle }}
      thumbXProps={{ style: thumbXStyle }}
      wrapperProps={{ style: wrapperStyle }}
      trackXProps={{ style: trackStyle, onMouseEnter, onMouseLeave }}
      trackYProps={{ style: trackStyle, onMouseEnter, onMouseLeave }}
      onScrollStop={onScrollStop}
      onScrollStart={onScrollStart}

    >
      {children}
    </Scrollbar>
  )
}

export default ScrollArea
