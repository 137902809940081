import React, { FC, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { Link, useLocation } from 'react-router-dom'
import { ETheme, colors as _, widgetThemes } from '@opiumteam/react-opium-components'
import appStore from '../../Stores/AppStore'

import './styles.scss'
import MiniLogo from '../../Images/mini-logo.svg'
import constructorStore from '../../Stores/ConstructorStore'

type TProps = {
    theme: ETheme,
  menuItems: {
    title: string
    to: string
    href?: string
    as?: ReactNode
    idLabel?: string
    classSelector?: string
    icon?: string
    onClick?: (event: MouseEvent | TouchEvent) => void
    tooltip?: string
  }[],
  isLinkEnabled: boolean
  onLinkClick: Function
}

const MobileMenu: FC<TProps> = (props: TProps) => {
  const location = useLocation()

  const { menuItems } = props

  /** Styles BEGIN */
  const getStyled = (theme: ETheme) => {
    return {
      backgroundColor: widgetThemes[theme].backgroundColor.secondary.value,
      borderColor: widgetThemes[theme].borderColor.secondary.value
    }
  }

  const styleColor = (theme: ETheme, tooltip?: string) => {
    return {
      color: theme === ETheme.DARK ? _.gray6 : _.gray3,
      opacity: tooltip ? 0.5 : 1,
    }
  }

  const activeMenuItem = menuItems.find(elem => elem.to === location.pathname || location.pathname.includes(elem.to))

  return (<div className={'mobile-menu'}>
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <div className='mini-logo-wrap'>
        <a href='https://opium.finance' target='_blank' rel="noreferrer">
          <img src={MiniLogo} alt='mini-logo' />
        </a>
      </div>
      <div className={'active-element'} onClick={() => appStore.setStateMobileMenu(!appStore.isOpenMobileMenu)}>
        {
          activeMenuItem && activeMenuItem.icon && <img
            style={{ width: '21px', marginRight: '19px' }}
            src={activeMenuItem.icon}
          />
        }
        {
          activeMenuItem && <span style={{ color: _.white0 }}>{activeMenuItem.title}</span>
        }
        <div
          className={`mobile-menu__toggler ${appStore.isOpenMobileMenu ? 'active' : ''}`}
        >
          <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM17.3536 1.35355C17.5488 1.15829 17.5488 0.841709 17.3536 0.646447C17.1583 0.451184 16.8417 0.451184 16.6464 0.646447L17.3536 1.35355ZM9.35355 8.64645L9.70711 9L9.35355 8.64645ZM0.646447 1.35355L8.29289 9L9 8.29289L1.35355 0.646447L0.646447 1.35355ZM9.70711 9L17.3536 1.35355L16.6464 0.646447L9 8.29289L9.70711 9ZM8.29289 9C8.68342 9.39053 9.31658 9.39052 9.70711 9L9 8.29289L9 8.29289L8.29289 9Z" fill='white'/>
          </svg>
        </div>
      </div>
      {location.pathname.includes('/knowledge-base') && <div className="menu-articles-block"></div>}
    </div>
    <div
      style={getStyled(ETheme.DARK)}
      className={`menu trans ${appStore.isOpenMobileMenu ? 'active' : ''}`}
      onClick={() => appStore.setStateMobileMenu(false)}
    >
      <ul>
        {
          menuItems.map((item) => {
            const { title, classSelector, onClick, to, icon, tooltip } = item
            return (
              <li key={`menu-item-${title}`} className={`${location.pathname === to ? 'active-block': ''}`}>
                {
                  <Link
                    style={styleColor(ETheme.DARK, tooltip)}
                    to={{ pathname: to === '/derivative' ? `${to}${constructorStore.identifier ? '/' + constructorStore.identifier : ''}` : to }}
                    onClick={() => {
                      props.onLinkClick(to)
                    }}>
                    {icon && <img src={icon} /> }
                    {title}
                  </Link>
                }
              </li>
            )
          })
        }
      </ul>
    </div>
  </div>)
}

export default observer(MobileMenu)
