import axios, { AxiosInstance } from 'axios'
import { TDerivativeExplorerResponse, TDerivative, TPayoutChartPoint, TAllDerivativesResponse } from '../Constants/Types/constructor'
import config from '../Constants/Config/opiumApi'
const constructorEndpoint = `${config.arbitrumEndpoint}/constructor`

export class ConstructorApi {
  private readonly _client: AxiosInstance

  public constructor() {
    this._client = axios.create({
      baseURL: constructorEndpoint,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  getConstructorData = async (identifier: string): Promise<TDerivativeExplorerResponse> => { 
    const data = await this._client.get(
      `/derivative/${identifier}`
    )
    return data.data
  }

  getPayoutChart = async (derivative: TDerivative): Promise<Array<TPayoutChartPoint>> => {
    const response = await this._client.post('/payoutChart', { derivative })
    return response.data
  }

  getDerivativesData = async (): Promise<TAllDerivativesResponse> => { 
    const data = await this._client.get(
      '/derivatives/'
    )
    return data.data
  }
}

export default new ConstructorApi()
