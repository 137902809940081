import React, { useState, FC } from 'react'
import moment from 'moment'
import { ETheme, Popup } from '@opiumteam/react-opium-components'
import { ENetwork } from '@opiumteam/mobx-web3'

import { TPopulatedStaking } from '../../../Constants/Types/staking'
import { getPoolPeriod, getStakingPhase } from '../../../Utils/staking'
import ActivePoolPopupContent from './ActivePoolPopupContent'

import BscLogo from '../../../Images/Icons/bsc-logo.svg'
import MaticLogo from '../../../Images/Icons/matic-logo.svg'
import EthLogo from '../../../Images/Icons/eth-logo.svg'

interface IProps {
  activePool: TPopulatedStaking,
}

const ActivePoolsTableRow: FC<IProps> = ({ activePool }: IProps) => {
  const {
    id,
    title,
    network,
    params: {
      currentEpochTimestamp,
      epochLength,
      mintedAmount,
      stakingPhaseLength,
      tradingPhaseLength,
    },
    product: {
      params: {
        marginTitle,
        ticker
      },
    }
  } = activePool

  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const togglePopup = () => setIsPopupOpen(!isPopupOpen)

  const renderNetworkIcon = (network?: ENetwork) => {
    switch (network) {
      case ENetwork.ETHEREUM:
        return EthLogo
      case ENetwork.MATIC:
        return MaticLogo
      case ENetwork.BINANCE:
        return BscLogo
    }
  }

  const { stakingPhaseStart } = getStakingPhase({
    epochLength,
    stakingPhaseLength,
    tradingPhaseLength,
    maturity: currentEpochTimestamp,
  })

  const periodStart = moment(stakingPhaseStart * 1000).format('D MMM')
  const periodEnd = moment(currentEpochTimestamp * 1000).format('D MMM YYYY')
  const poolPeriod = getPoolPeriod(epochLength)
  const period = `${poolPeriod}, ${periodStart} - ${periodEnd}`

  return (
    <>
      <tr onClick={togglePopup}>
        <td>
          <div className="active-pool-name">{title}</div>
          <div className="period">{period}</div>
        </td>

        <td>
          <div className='ticker'>{ticker}</div>
        </td>

        <td>
          <div className="minted-amount">
            {mintedAmount > 0
              ? `${ mintedAmount.toLocaleString('en')} ${marginTitle}`
              : '-'
            } 
          </div>
        </td>

        <td>{id === 'PRODUCT_PEER_2_POOL_OPTION_OPIUM_V1' ? 'Optimistic' :'ChainLink'}</td>

        <td>
          <img className="chain-logo" src={renderNetworkIcon(network) || ''} alt={title} />
        </td>
      </tr>

      <Popup
        theme={ETheme.DARK}
        title={title}
        subtitle={period}
        component={<ActivePoolPopupContent activePool={activePool} />}
        closePopup={togglePopup}
        popupIsOpen={isPopupOpen}
        className="active-pools-popup"
        size="xl"
      />
    </>
  )
}

export default ActivePoolsTableRow
