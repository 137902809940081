import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { LineChart as OpiumLineChart, ETheme, Tooltip, Button } from '@opiumteam/react-opium-components'
import numeral from 'numeral'

import InfoLogo from '../../Images/Info.svg'

import './styles.scss'



type TChartDataItem = {
  label: string,
  lineData: number,
}

type TProps = {
  chartData: TChartDataItem[],
  title: string,
  height: string
  domainY: number[]
  tooltipText: string
  tickFormatterX?: (value: any) => string
  intervalX?: number
  dontShowLabel?: boolean
}

const LineChart: FC<TProps> = (props: TProps) => {


  return (
    <div className='line-chart-wrapper'>
      <div className='line-chart-upper-section'>
        <div className='line-chart-title'>
          {props.title}
          <Tooltip
            theme={ETheme.DARK}
            label={'!'}
            trigger='hover'
            placement='bottom'
            content={props.tooltipText}
            html={true}
            component={<img style={{ marginLeft: '0.5rem', marginBottom: '-2px', cursor: 'pointer' }} src={InfoLogo} />}
          />
        </div>
        <div className='line-chart-source'>Source: <a href='https://www.deribit.com/' target='_blank' rel='noreferrer'>Deribit</a></div>
      </div>
      <div className='line-chart-line' />
      <OpiumLineChart
        theme={ETheme.DARK}
        height={props.height}
        data={props.chartData}
        domainY={props.domainY}
        tickFormatterY={label => {
          return `${numeral(label).format('0[.]0 ')}%`
        }}
        tickFormatterX={props.tickFormatterX}
        intervalX={props.intervalX}
        dontShowLabel={props.dontShowLabel}
        increaseDomainY={0.1}
      />
    </div>
  )
}

export default observer(LineChart)
