import { ETheme } from '@opiumteam/react-opium-components'
import React, { FC } from 'react'

type TProps = {}

const PutOption: FC<TProps> = () => {
  return (
    <>
      <div className={`KnowledgeBase__block-title bottom-line color-scheme-${ETheme.DARK}`}>
        Put Option
      </div>
      <div className="KnowledgeBase__block-content">
        Put option is a Derivative contract between two counterparties, where one party (Option buyer, Long Put) has the <strong>right, but not an obligation</strong> to sell an Underlying asset at Expiration at a specific price (called Strike) to the other party (Option seller, Short Put). Option seller has an <strong>obligation</strong> to buy the Underlying at Strike price. Option buyer pays to Option seller a specific sum, which is called Premium, to enter into the Option contract.
      </div>
      <div className="KnowledgeBase__block-content">
        Payoff of the Put option at Expiration (received by Option buyer and paid by Option seller) can be formalized in a simple formula:
      </div>
      <div className="KnowledgeBase__block-content">
        Payoff = Notional*max(0; Strike - Fixing). 
      </div>
      <div className="KnowledgeBase__block-content">
        This is easily visualized on the Payoff diagram (see above).
      </div>
      <div className="KnowledgeBase__block-content">
        Put Option buyer can hedge the risk of falling Underlying price (if he owns the Underlying) or participate in the decline in Underlying price without taking a risk of price increase (the only thing he is risking is Premium).
      </div>
      <div className="KnowledgeBase__block-content">
        Put Option seller receives Premium and can enjoy it as long as Underlying price does not drop below the Strike.
      </div>
    </>
  )
}

export default PutOption
