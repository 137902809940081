import { observable, action, computed, autorun } from 'mobx'
import constructorApi from '../Services/constructorApi'
import { EIdentifierType, 
  TDerivativeExplorerResponse,
  TDerivative,
  TPayoutChartPoint, 
  TAllDerivativesResponse,
  TDerivativesCheckbox,
  EDerivativesFilterTimes,
  EDerivativesFilterOptions, 
  TDerivativesFilterButton,
  EDerivativesFilterType, 
  EPosition } from '../Constants/Types/constructor'
import { ELoadingStatuses } from '../Constants/Types'
import { convertFromBN } from '../Utils/bn'
import moment from 'moment'
import OpiumV2SDK from '@opiumteam/opium-sdk-v2'
import { getValue } from '../Utils/sorting'

export class ConstructorStore {

  @observable constructorLoading: ELoadingStatuses = ELoadingStatuses.IDLE
  @observable derivativesDataLoading: ELoadingStatuses = ELoadingStatuses.IDLE
  @observable public oraclePrice: number = 0
  @observable public hasError: boolean = false
  @observable public showIdentifierType: boolean = false
  @observable public identifier: string = ''
  @observable public buyerMargin: number = 0
  @observable public sellerMargin: number = 0

  @observable public derivativesData: TAllDerivativesResponse = []

  @observable timesCheckboxList: TDerivativesCheckbox[] = []
  @observable optionsCheckboxList: TDerivativesCheckbox[] = []
  @observable arrayAllFilters: TDerivativesFilterButton[] = []
  @observable timesFilters: string[] = []
  @observable optionsFilters: string[] = []

  @observable showBidPopup: boolean = false
  @observable showAskPopup: boolean = false

  @observable filter: string = ''

  private readonly _arrFilterKeys: string[] = ['derivativeType', 'ticker', 'derivativeHash', 'longPosition.address', 'shortPosition.address']

  @observable public constructorConfig: TDerivativeExplorerResponse = {
    identifierType: EIdentifierType.DERIVATIVE,
    derivativeHash: '0x3a1aff13e0da3cf8dd8ee18d008704903061ebe574e895aec37f93cc55f95716',
    longPosition: {
      address: '',
      totalSupply: ''
    },
    shortPosition: {
      address: '',
      totalSupply: ''
    },
    margin: '',
    endTime: 0,
    params: [],
    oracle: {
      id: '', 
      title: 'ETH/USD (Chainlink)', 
      address: '',
      ticker: '',
      underlyingAsset: '',
      referenceAsset: '',
      show: true,
      underlyingImage: '',
      referenceImage: ''
    },
    token: {
      id: '',
      title: 'WETH',
      address: '',
      decimals: 18,
      image: ''
    },
    synthetic: {
      id: '', 
      title: 'Option Call', 
      address: '',
      ticker: '',
      authorFee: 0,
      authorAddress: ''
    },
    ask: null,
    bid: null,
    bidSize: null,
    askSize: null,
    askPosition: EPosition.LONG,
    bidPosition: EPosition.LONG,
    helpers: {
      strikePrice: 0,
      calculatedStrikePrice: 0
    }
  }

  @observable public payoutChart: { [index: string]: any; } = {
    data: [
      { data1: 0.5, data2: 0, price: 2000 },
      { data1: 0.5, data2: 0, price: 2175 },
      { data1: 0.5, data2: 0, price: 2350 },
      { data1: 0.49, data2: 0.01, price: 2525 },
      { data1: 0.43, data2: 0.07, price: 2700 },
      { data1: 0.02, data2: 0.48, price: 4800 },
      { data1: 0, data2: 0.5, price: 4975 },
      { data1: 0, data2: 0.5, price: 5150 },
      { data1: 0, data2: 0.5, price: 5325 },
      { data1: 0, data2: 0.5, price: 5500 }
    ],
    maxData1: 0.5,
    maxData2: 0.5,
    maxPrice: 5500,
    maxYTotal: 0.5,
    minData1: 0,
    minData2: 0,
    minPrice: 2000,
    minYTotal: 0,
  }

  constructor () {
    autorun(this._generateCheckboxList)
  }

  private _generateCheckboxList = () => {
    this.timesFilters.length
      ? this.timesFilters?.forEach((title: string) => {
        this.timesCheckboxList.forEach((item: TDerivativesCheckbox) => (item.value = item.title?.toLowerCase() === title.toLowerCase()))
      })
      : this.timesCheckboxList = Object.values(EDerivativesFilterTimes).map((el: any) => ({ title: el, value: false }))

    this.optionsFilters.length
      ? this.optionsFilters?.forEach((title: string) => {
        this.optionsCheckboxList.forEach((item: TDerivativesCheckbox) => (item.value = item.title?.toLowerCase() === title.toLowerCase()))
      })
      : this.optionsCheckboxList = Array.from(new Set(this.derivativesData.map((el: any) => el.derivativeType))).map((item: string | undefined) => ({ title: EDerivativesFilterOptions[item as keyof typeof EDerivativesFilterOptions], value: false }))
  }

  @action
  setAllFilters = (item: TDerivativesFilterButton) => {
    const { title, type } = item
    const i = this.arrayAllFilters.findIndex((item: TDerivativesFilterButton) => (item.title === title && item.type === type))
    if (i !== -1) {
      this.arrayAllFilters.splice(i, 1)
    } else {
      this.arrayAllFilters.push(item)
    }
  }

  @action setTimesFilters = (item: string) => {
    const i = this.timesFilters.indexOf(item)
    if (i !== -1) {
      this.timesFilters.splice(i, 1)
    } else {
      this.timesFilters.push(item)
    }
    this.setAllFilters({ title: item, type: EDerivativesFilterType.Times })
    this.setCheckedValueOfOracles(this.timesCheckboxList, item)
  }

  @action setOptionsFilters = (item: string) => {
    const i = this.optionsFilters.indexOf(item)
    if (i !== -1) {
      this.optionsFilters.splice(i, 1)
    } else {
      this.optionsFilters.push(item)
    }
    this.setAllFilters({ title: item, type: EDerivativesFilterType.Options })
    this.setCheckedValueOfOracles(this.timesCheckboxList, item)
  }

  @action
  setCheckedValueOfOracles = (array: TDerivativesCheckbox[], title: string) => {
    array.forEach((item: TDerivativesCheckbox) => ((item.title?.toLowerCase() === title.toLowerCase() ? item.value = !item.value : item.value )))
  }

  @action fetchConstructorData: (identifier: string) => Promise<void> = async (identifier: string): Promise<void> => {
    if (
      this.constructorLoading === ELoadingStatuses.PENDING ||
      this.constructorLoading === ELoadingStatuses.SUCCEEDED
    ) {
      return
    }
    
    try {
      this.constructorLoading = ELoadingStatuses.PENDING
      const data = await constructorApi.getConstructorData(identifier)

      this.constructorLoading = ELoadingStatuses.SUCCEEDED
      this.constructorConfig = data
      this.showIdentifierType = true
      this.hasError = false
    } catch (err: any) {
      this.hasError = true
      this.constructorLoading = ELoadingStatuses.FAILED
    }
  }

  @action fetchDerivativesData: () => Promise<void> = async (): Promise<void> => {
    try {
      const derivatives = await constructorApi.getDerivativesData()
      this.derivativesData = derivatives?.map((el: TAllDerivativesResponse[0]) => ({ ...el, chain: 'arbitrum', protocol: 'OFI' }))

      this.derivativesDataLoading = ELoadingStatuses.SUCCEEDED
    } catch (err: any) {
      console.error(err)
      this.derivativesDataLoading = ELoadingStatuses.FAILED
    }
  }


  @action fetchPayoutChart: () => void = async (): Promise<void> => {
    const derivative: TDerivative = {
      margin: this.constructorConfig.margin,
      endTime: this.constructorConfig.endTime,
      params: this.constructorConfig.params,
      oracleId: this.constructorConfig.oracle.address,
      token: this.constructorConfig.token.address,
      syntheticId: this.constructorConfig.synthetic.address
    }
    
    const chartRes: Array<TPayoutChartPoint> = await constructorApi.getPayoutChart(derivative)


    this.payoutChart = chartRes.reduce((acc: {[index: string]: any}, elem: TPayoutChartPoint) => {
      const mappingData = {
        data1: +elem.sellerPayout.toFixed(2),
        data2: +elem.buyerPayout.toFixed(2),
        price: (elem.price)
      }


      if (acc.minPrice === null || mappingData.price < acc.minPrice) {
        acc.minPrice = mappingData.price
      }

      if (acc.maxPrice === null || mappingData.price > acc.maxPrice) {
        acc.maxPrice = mappingData.price
      }

      if (acc.minData1 === null || mappingData.data1 < acc.minData1) {
        acc.minData1 = mappingData.data1
      }

      if (acc.maxData1 === null || mappingData.data1 > acc.maxData1) {
        acc.maxData1 = mappingData.data1
      }

      if (acc.minData2 === null || mappingData.data2 < acc.minData2) {
        acc.minData2 = mappingData.data2
      }

      if (acc.maxData2 === null || mappingData.data2 > acc.maxData2) {
        acc.maxData2 = mappingData.data2
      }


      acc.minYTotal = acc.minData1
      if (acc.minData2 < acc.minData1) {
        acc.minYTotal = acc.minData2
      }

      acc.maxYTotal = acc.maxData2
      if (acc.maxData1 > acc.maxData2) {
        acc.maxYTotal = acc.maxData1
      }


      acc.minYTotal = (acc.minYTotal === 0 ? acc.minYTotal-(acc.maxYTotal * 0.1) : -acc.minYTotal * 1.1)
      acc.maxYTotal = (acc.maxYTotal === 0 ? acc.maxYTotal+(acc.minYTotal * 0.1) : acc.maxYTotal * 1.1)

      acc.data.push(mappingData)
      return acc
    }, { data: [], minPrice: null, maxPrice: null, minData1: null, maxData1: null, minData2: null, maxData2: null, minYTotal: 0, maxYTotal: 0 })
  }

  @action getOraclePrice = async () => {
    const sdk = new OpiumV2SDK.Sdk.OpiumV2SDK({
      rpcUrl: 'https://arb1.arbitrum.io/rpc',
      // chainId: networkId,
      chainId: 42161,
      // override: this.blockchain.getProvider()
    })

    await sdk.setup()

    const derivative: TDerivative = {
      margin: this.constructorConfig.margin,
      endTime: this.constructorConfig.endTime,
      params: this.constructorConfig.params,
      oracleId: this.constructorConfig.oracle.address,
      token: this.constructorConfig.token.address,
      syntheticId: this.constructorConfig.synthetic.address
    }
    
    const margin = await sdk.coreInstance?.getBuyerAndSellerMargin(derivative) 
    
    this.sellerMargin = +convertFromBN(String(margin?.sellerMargin || 0), this.constructorConfig.token.decimals)
    this.buyerMargin = +convertFromBN(String(margin?.buyerMargin || 0), this.constructorConfig.token.decimals)

    this.oraclePrice = +convertFromBN(String(await sdk.derivativeLensFactory.getOracleIdResult(this.constructorConfig.oracle.address)), 18)
    this.constructorLoading = ELoadingStatuses.IDLE
  }

  @action setDefaultOraclesValue = () => {
    this.oraclePrice = 0
  }

  @action setIdentifierValue = (value: string) => {
    this.identifier = value
  }

  @action setError = (value: boolean) => {
    this.hasError = value
  }

  @action setShowIdentifierType = (flag: boolean) => {
    this.showIdentifierType = flag
  }

  @action setShowBidPopup = (flag: boolean) => {
    this.showBidPopup = flag
  }

  @action setShowAskPopup = (flag: boolean) => {
    this.showAskPopup = flag
  }
  
  @action setDerivativesDataLoading = (status: ELoadingStatuses) => {
    this.derivativesDataLoading = status
  }

  @action setFilterSearch = (f: string) => {
    this.filter = f
  }

  @computed
  public get totalCollateral(): number {
    return +convertFromBN(this.constructorConfig.margin || '0', 18)
  }

  @computed
  public get collateralization(): string {
    return `${parseFloat((+convertFromBN(this.constructorConfig.params[1] || '0', 18) * 100).toFixed(2))}%`
  }

  @computed
  public get strikePrice(): number {
    if (this.isSpread) {
      const a = this.constructorConfig.params.length 
        ? +convertFromBN(this.constructorConfig.params[0] || '0', 18) * (1 - +convertFromBN(this.constructorConfig.params[1] || '0', 18)) 
        : 0
      const b = a % this.constructorConfig.helpers.calculatedStrikePrice
      return a - b
    }
    return this.constructorConfig.params.length ? +convertFromBN(this.constructorConfig.params[0] || '0', 18) : 0
  }

  @computed
  public get isSpread(): boolean {
    return this.constructorConfig.params.length ? +convertFromBN(this.constructorConfig.params[1] || '0', 18) < 0.5 : false
  }

  @computed
  public get strikePriceHigh(): number {
    if (this.isSpread) {
      return +convertFromBN(this.constructorConfig.params[0] || '0', 18)
    }
    const a = this.strikePrice / (1 - +convertFromBN(this.constructorConfig.params[1] || '0', 18))
    const b = a % this.constructorConfig.helpers.calculatedStrikePrice
    return a - b
  }


  @computed
  public get ticker(): string {
    const ticker = this.isSpread ? this.constructorConfig.synthetic.ticker.replace('OPT', 'SPRD'): this.constructorConfig.synthetic.ticker
    const strike = this.isSpread ? `${this.strikePrice}/${this.strikePriceHigh}`: this.strikePrice
    return `OFI-${ticker}-${this.constructorConfig.oracle.ticker}-${moment.unix(this.constructorConfig.endTime).utc().locale('en').format('DDMMM').toUpperCase()}-${strike}`
  }

  @computed
  public get expiry(): string {
    return this.constructorConfig.endTime 
      ? moment.unix(this.constructorConfig.endTime).utc().format('DD.MM.YYYY HH:mm UTC')
      : moment().format('DD.MM.YYYY HH:mm UTC')
  }

  @computed
  public get derivativeType(): string {
    let type = this.constructorConfig.synthetic.title
    if (this.isSpread) {
      type = this.constructorConfig.synthetic.ticker === 'OPT-P' ? 'Put Spread' : 'Call Spread'
    }
    return type
  }


  @computed
  public get amount(): number {
    return +convertFromBN(this.constructorConfig.longPosition.totalSupply, 18)
  }

  @computed 
  public get derivativesList(): TAllDerivativesResponse {
    const data = this.derivativesData
    let filteredDerivatives = data

    if (this.timesFilters.length) {
      this.timesFilters.length === 2 
        ? filteredDerivatives
        : this.timesFilters.forEach((el: string) => {
          return el === EDerivativesFilterTimes.Active 
            ? filteredDerivatives = filteredDerivatives.filter(d => d.endTime > moment().unix())
            :filteredDerivatives = filteredDerivatives.filter(d => d.endTime < moment().unix())
        })
    }

    if (this.optionsFilters.length) {
      const filterKeys = Object.keys(EDerivativesFilterOptions).filter((el) => {
        return this.optionsFilters.includes(EDerivativesFilterOptions[el as keyof typeof EDerivativesFilterOptions]) ? el : null
      })

      filteredDerivatives = filteredDerivatives.filter(derivative => { 
        return filterKeys.includes(derivative.derivativeType || '') 
      })
    }

    filteredDerivatives = this.filter ? filteredDerivatives.filter((item: TAllDerivativesResponse[0]) => {
      return this._arrFilterKeys.some(key => {
        return String(key.includes('.') ? getValue(item, key.split('.')) : item[key as keyof typeof item])?.toLowerCase().includes(this.filter.toLowerCase())
      }) 
    }) : filteredDerivatives

    return filteredDerivatives.slice().sort((a, b) => a.endTime < b.endTime ? 1 : -1)
  }

}

export default new ConstructorStore()
