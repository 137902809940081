import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { Button } from '@opiumteam/react-opium-components'

import './styles.scss'

type TProps = {
  onAcceptClick: Function
  onCancelClick: Function
}

const TermsPopup: FC<TProps> = ({ onAcceptClick, onCancelClick }: TProps) => {

  const onAccept = (): void => {
    localStorage.setItem('isAgreeWithTermsConditions', 'true')
    onAcceptClick()
  }

  return (
    <div className='terms-popup-wrapper'>
      <div className='terms-popup-header'>
        Terms & Conditions
      </div>
      <div className='terms-popup-links'>
        By proceeding to the platform You accept our{' '}
        <a
          href='https://app.opium.finance/polygon/terms-of-service'
          target='_blank'
          rel='noreferrer'
        >
          Terms of service
        </a>
        ,{' '}
        <a href='https://app.opium.finance/polygon/privacy-policy' target='_blank' rel='noreferrer'>
          Privacy policy
        </a>{' '}
        and{' '}
        <a href='https://app.opium.finance/polygon/disclaimer' target='_blank' rel='noreferrer'>
          Disclaimer
        </a>
        .
      </div>
      <div>
        Also, a condition to accessing the services or opium.finance you acknowledge that:
      </div>
      <ul>
        <li>
          You declare that You are not a US person (as defined in the Securities Act of 1933, as amended); or a citizen or resident of Canada; or a citizen or resident of Japan
        </li>
        <li>
          You do not and will not use VPN software or any other privacy or anonymization tool to circumvent any restrictions that apply to the service and the use of opium.finance
        </li>
        <li>
          If you are entering into these terms as an individual then you are of legal age (as applicable in your jurisdiction in which you reside)
        </li>
        <li>
          Your access to the services and opium.finance does not violate any foreign rule, law, regulation or directive
        </li>
      </ul>
      <div className='terms-popup-buttons-wrapper'>
        <Button style={{ marginTop: '15px' }} label='accept' onClick={onAccept}/>
        <Button
          style={{ marginTop: '15px' }}
          label='decline'
          variant='secondary'
          onClick={onCancelClick}
        />
      </div>

    </div>
  )
}


export default observer(TermsPopup)
