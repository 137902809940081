import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, CardSlider, ETheme, Loading, Tooltip, SelectMultiple, Pagination } from '@opiumteam/react-opium-components'

import oraclesStore from '../../Stores/OraclesStore'
import { ELoadingStatuses } from '../../Constants/Types'
import { formatDate, networkLogos, renderOraclesIcon } from '../../Utils/helpers'
import { convertFromBN } from '../../Utils/bn'

import { EOraclesFilterTab, EOraclesFilterType, TOracle, TOraclesFilterButton } from '../../Constants/Types/oracles'
import { useMobile } from '../../Utils/hooks'
import { EDirection } from '../../Utils/sorting'
import SortHeaderTable from '../SortHeaderTable'

import './styles.scss'

interface IProps {}

const Oracles: FC<IProps> = ({}: IProps) => {
  const { width } = useMobile() 
  const [removedItem, setRemovedItem] = useState<TOraclesFilterButton | null>(null)
  const pageSize: number = 11

  const requestSortOracles = (key: string) => {
    let direction = EDirection.ascending
    if (
      oraclesStore.sortConfig &&
      oraclesStore.sortConfig.key === key &&
      oraclesStore.sortConfig.direction === EDirection.ascending
    ) {
      direction = EDirection.descending
    }
    oraclesStore.setSortConfigOracles({ key, direction })
  }


  const headers = [
    { name: 'asset', label: 'Asset', sort: false },
    { name: 'reportedTimestamp', label: 'Date', sort: true, class: 'oracles-th' },
    { name: 'price', label: 'Price', sort: true, class: 'oracles-th' },
    { name: 'reportedTimestamp', label: 'Timestamp', sort: false },
    { name: 'source', label: 'Source', sort: false },
    { name: 'chain', label: 'Chain', sort: false },
  ]

  useEffect(() => {
    oraclesStore.getTokenPrices()
  }, [])

  useEffect(() => {
    oraclesStore.getNewOracles(oraclesStore.currentPage)
  }, [oraclesStore.currentPage, oraclesStore.sortConfig])
    
  useEffect(() => {
    if (oraclesStore.oracles.length === 0 && oraclesStore.totalCount !== 0) {
      const page = Math.ceil(oraclesStore.totalCount / pageSize)
      oraclesStore.setCurrentPage(page)
    }
  }, [oraclesStore.oracles, oraclesStore.totalCount, oraclesStore.currentPage])

  const tokenName: {[x in string]: string} = {
    'matic-network': 'MATIC',
    'tether': 'USDT',
    'bitcoin': 'Bitcoin',
    'aave': 'AAVE',
    'opium': 'OPIUM',
    'ethereum': 'Ethereum',
    '1inch': '1INCH',
    'sushi': 'SUSHI',
    'curve-dao-token': 'CRV',
  }

  const tabs: {
    id: EOraclesFilterTab
    title: string
  }[] = Object.values(EOraclesFilterTab).map((el: any) => {
    return { id: el, title: EOraclesFilterTab[el as keyof typeof EOraclesFilterTab] }
  })

  const switchFilterTab = (newTabId: string) => {
    const tab: EOraclesFilterTab = newTabId as EOraclesFilterTab
    oraclesStore.filteredOraclesList(tab)
  }

  const countSlides: number = width > 1440 ? 7 : (width >= 1200 && width <= 1440) ? 6 : width == 1024 ? 5 : width == 768 ? 4 : width < 426 ? 2 : 4

  const removeFilter = (filter: TOraclesFilterButton) => {
    filter.type === EOraclesFilterType.Asset ? oraclesStore.setAssetFilters(filter.title ? filter.title.toUpperCase() : '')
      : filter.type === EOraclesFilterType.Source ? oraclesStore.setSourceFilters(filter.title ? filter.title : '') 
        : oraclesStore.setChainFilters(filter.title ? filter.title : '')
    setRemovedItem(filter)
  }
  
  return (
    <div className='oracles-wrapper page-wrapper'>
      <div className='oracles-top-wrapper'>
        <div className='page-title'>Oracles</div>

        <div className='page-text'>
          In Opium ChainLink and Optimistic Oracles are used to settle options
        </div>

        <div className='oracles-top-prices-wrapper'>
          {(() => {
            switch (oraclesStore.tokenPricesLoading) {
              case ELoadingStatuses.FAILED:
                return (
                  <div className="error">Something went wrong, please refresh the page.</div>
                )
              case ELoadingStatuses.SUCCEEDED:
                if (!Object.keys(oraclesStore.tokenPrices).length) {
                  return <div className="message">No data</div>
                }
                return <CardSlider
                  dots={width < 1025}
                  infinite={true}
                  autoplay={true}
                  speed={1000}
                  slidesToShow={countSlides}
                  slidesToScroll={1}
                  sliderType="box"
                  theme={ETheme.DARK}
                  className="custom-slider"
                  hasNumberOfSliders={true}
                >
                  {Object.keys(oraclesStore.tokenPrices).map((key: any) => {
                    return (
                      <div className='slider-oracles-top-prices-item' key={key}>
                        <div className='slider-oracles-top-prices-item-title'>
                          <img src={renderOraclesIcon(key)} alt={tokenName[key]} />
                          {tokenName[key]}
                        </div>
                        <div className='slider-oracles-top-prices-item-value'>
                              ${oraclesStore.tokenPrices[key].usd.toFixed(2)}
                        </div>
                      </div>
                    )
                  })}
                </CardSlider>
              default:
                return null
            }
          })()}
        </div>
      </div> 

      {/* <div className='page-subtitle'>ETH Oracles</div> */}

      <div className='page-text oracles-description'>
        The prices for underlying assets are received via{' '}
        <a href='https://chain.link/' target='_blank' rel='noreferrer'>
          ChainLink
        </a>{' '}
        oracles 
        and reflected using{' '}
        <a href='https://thegraph.com/hosted-service/subgraph/opiumprotocol/opium-v2-arbitrum-one' target='_blank' rel='noreferrer'>
          Opium
        </a>{' '}
        and {' '}
        <a href='https://gammaportal.xyz/#/protocol/oracle/' target='_blank' rel='noreferrer'>
          Opyn
        </a> subgraphs
      
      </div>

      <div className='oracles-filters-buttons'>
        {oraclesStore.arrayAllFilters?.map((filter: TOraclesFilterButton, key: number) => {
          return (
            <div className='oracles-button' key={filter.title + key}>
              <Button 
                theme={ETheme.DARK}
                variant={'minimal'}
                label={<div className='oracles-remove-btn'><div className='label'>{filter.title}</div>
                  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="1.06066" y1="1.05609" x2="14.9438" y2="14.9393" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                    <line x1="1.43799" y1="14.8832" x2="15.3212" y2="1.00001" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                  </svg></div>
                }
                className="filters-remove-button"
                onClick={() => removeFilter(filter)}
              />
            </div>
          )
        })}
      </div>

      <div className='oracles-filters-wrapper'>
        {(oraclesStore.filtersList) && (
          <>
            <div className='filter-wrapper'>
              <SelectMultiple 
                items={oraclesStore.assetCheckboxList}
                onSelect={(title: string) => oraclesStore.setAssetFilters(title ? title.toUpperCase() : '')}
                placeholder={'Asset'}
                removedItem={removedItem?.type === EOraclesFilterType.Asset ? removedItem.title : null}
              />
            </div>

            <div className='filter-wrapper'>
              <SelectMultiple 
                items={oraclesStore.sourceCheckboxList}
                onSelect={(title: string) => oraclesStore.setSourceFilters(title ? title : '')}
                placeholder={'Source'}
                removedItem={removedItem?.type === EOraclesFilterType.Source ? removedItem.title : null}
              />
            </div>

            <div className='filter-wrapper'>
              <SelectMultiple 
                items={oraclesStore.chainCheckboxList}
                onSelect={(title: string) => oraclesStore.setChainFilters(title ? title : '')}
                placeholder={'Chain'}
                removedItem={removedItem?.type === EOraclesFilterType.Chain ? removedItem.title : null}
              />
            </div>
          </>
        )}
      </div>

      {/* <div className="oracle-tabs-section">
        {tabs ? (
          <ControlledTabs
            theme={ETheme.DARK}
            tabs={tabs}
            activeTabId={oraclesStore.activeTabOraclesId}
            size={EControlledTabsSizes.S}
            switchTab={switchFilterTab}
          /> 
        ) :
          <div style={{ height: 30 }}></div>
        }
      </div> */}

      <div className='oracles-table-wrapper'>
        {(() => {
          switch (oraclesStore.oraclesLoading) {
            case ELoadingStatuses.PENDING:
              return (
                <div className="oracles-loading-wrapper">
                  <Loading />
                </div>
              )
            case ELoadingStatuses.FAILED:
              return (
                <div className="error">Something went wrong, please refresh the page.</div>
              )
            case ELoadingStatuses.SUCCEEDED:
              return oraclesStore.oracles.length ? (
                <table className='transparent-table oracles-table'>
                  <SortHeaderTable headers={headers} requestSort={requestSortOracles} sortConfig={oraclesStore.sortConfig} />
      
                  <tbody>
                    {oraclesStore.oracles?.map(oracle => (
                      <tr className='oracle-item-wrapper' key={`${oracle.expiry || oracle.reportedTimestamp}-${oracle.price}`}>
                        <td>
                          <Tooltip
                            theme={ETheme.DARK}
                            label={''}
                            trigger='hover'
                            placement='top'
                            content={oracle.asset}
                            html={true}
                            component={<img className="oracle-item-icon oracle-icon-circle" src={networkLogos(oracle.iconAsset ? oracle.iconAsset === 'arbitrum' ? '' : oracle.iconAsset : oracle.source)}/>}
                          />
                        </td>
                        <td>{formatDate(+oracle.reportedTimestamp, 'DD MMM YYYY HH:mm UTC')}</td>
                        <td>${(+convertFromBN(oracle.price, oracle.source === 'ChainLink ETH/USD (Opyn)' ? 8 : 18)).toFixed(2)}</td>
                        <td>{oracle.reportedTimestamp}</td>
                        <td>{oracle.source === 'ChainLink ETH/USD (Opyn)' ? oracle.source : oracle.source + ' (Opium)'}</td>
                        <td>
                          <Tooltip
                            theme={ETheme.DARK}
                            label={''}
                            trigger='hover'
                            placement='top'
                            content={oracle.chain && (oracle.chain[0].toUpperCase() + oracle.chain.slice(1))}
                            html={true}
                            component={<img className="oracle-item-icon" src={networkLogos(oracle.chain)}/>}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : <div className="no-data-table">There are no Oracles based on the selected filters</div>
            default:
              return null
          }
        })()}</div>

      {oraclesStore.oraclesLoading === ELoadingStatuses.SUCCEEDED && <Pagination 
        theme={ETheme.DARK}
        currentPage={oraclesStore.currentPage}
        totalCount={oraclesStore?.totalCount}
        siblingCount={1}
        pageSize={pageSize}
        onPageChange={(page: number) => oraclesStore.setCurrentPage(page)}
      />}
    </div>
  )
}

export default observer(Oracles)
