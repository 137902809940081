import React, { FC, ReactNode, useState } from 'react'
import { observer } from 'mobx-react'
import numeral from 'numeral'
import moment from 'moment'

import { EPosition } from '../../../../Constants/Types/constructor'

import constructorStore from '../../../../Stores/ConstructorStore'

import { Button, ETheme, Tooltip } from '@opiumteam/react-opium-components'
import DerivativeBidAskPopup from '../DerivativeBidAskPopup'

import info from '../../../../Images/Info.svg'

import './styles.scss'

const ReceiveTabContent: FC = () => {

  const [activePrice, setActivePrice] = useState<ReactNode>(null)
  const now = moment().unix()

  const openBidPopup = (e: any, link: string) => {
    e.stopPropagation()
    setActivePrice(renderBidPrice())
    constructorStore.setShowBidPopup(true)
  }

  const openAskPopup = (e: any, link: string) => {
    e.stopPropagation()
    setActivePrice(renderAskPrice())
    constructorStore.setShowAskPopup(true)
  }

  const formatPrice = (price: number) => {
    return parseFloat(numeral((price)).format('0[.]00'))
  } 

  const renderAskPrice = () => {
    const difference: number = (constructorStore.constructorConfig.ask ? constructorStore.constructorConfig.ask : 0) - constructorStore.buyerMargin

    if ( constructorStore.buyerMargin === 0 ) {
      return (
        <>
          {constructorStore.constructorConfig.ask ? <>
            <p className={`tooltip-bold ${difference > 0 ? 'red-box' : 'green-box'}`}>{difference > 0 ? 'To pay:' : 'To receive:'}</p>
            <div className={`${difference > 0 ? 'tooltip-red-border' : 'tooltip-green-border'}`}>{formatPrice(difference)} {constructorStore.constructorConfig.token.title}</div>
            <div className={'tooltip-total tooltip-indent'}><span>{difference > 0 ? 'Total to pay' : 'Total to receive'}</span> <span className={difference > 0 ? 'red' : 'green'}>{formatPrice(constructorStore.constructorConfig.ask)} {constructorStore.constructorConfig.token.title}</span></div>
          </> : ''} 
        </>
      )
    }

    return (
      <>
        {constructorStore.constructorConfig.ask ? <>
          <p className='tooltip-bold red-box'>To pay:</p>
          <div className='tooltip-red-border'>{formatPrice(constructorStore.buyerMargin)} {constructorStore.constructorConfig.token.title} Locked collateral*</div>
          <div className='tooltip-red-border'>{formatPrice(constructorStore.constructorConfig.ask)} {constructorStore.constructorConfig.token.title} Locked collateral*</div>
          {difference > 0 && <div className='tooltip-red-border'>{formatPrice(difference)} {constructorStore.constructorConfig.token.title}</div>}

          <p className='tooltip-bold green-box tooltip-indent'>To receive:</p>
          {difference < 0 && <div className='tooltip-green-border'>{formatPrice(difference)} {constructorStore.constructorConfig.token.title}</div>}

          <div className={'tooltip-total tooltip-indent'}><span>{difference < 0 ? 'Total to pay' : 'Total to receive'}</span> <span className={difference < 0 ? 'red' : 'green'}>{formatPrice(constructorStore.constructorConfig.ask)} {constructorStore.constructorConfig.token.title}</span></div>
          {constructorStore.buyerMargin !== 0 && <p><p className='tooltip-note'>*with this position you lock collateral until maturity <br/> on the maturity you will receive unlocked collateral ± profit <br/> and loss on the position</p></p>}          
        </> : ''} 
      </>
    )
  }

  const renderBidPrice = () => {
    const difference: number = constructorStore.sellerMargin - (constructorStore.constructorConfig.bid ? constructorStore.constructorConfig.bid : 0)

    if ( constructorStore.sellerMargin === 0 ) {
      return (
        <>
          {constructorStore.constructorConfig.bid ? <>
            <p className={`tooltip-bold ${difference < 0 ? 'red-box' : 'green-box'}`}>{difference < 0 ? 'To pay:' : 'To receive:'}</p>
            <div className={`${difference > 0 ? 'tooltip-red-border' : 'tooltip-green-border'}`}>{formatPrice(difference)} {constructorStore.constructorConfig.token.title}</div>
            <div className={'tooltip-total tooltip-indent'}><span>{difference > 0 ? 'Total to pay' : 'Total to receive'}</span> <span className={difference > 0 ? 'red' : 'green'}>{formatPrice(constructorStore.constructorConfig.bid)} {constructorStore.constructorConfig.token.title}</span></div>
          </> : ''} 
        </>
      )
    }

    return (
      <>
        {constructorStore.constructorConfig.bid ? <>
          <p className='tooltip-bold red-box'>To pay:</p>
          {difference < 0 && <div className='tooltip-red-border'>{formatPrice(constructorStore.constructorConfig.bid)} {constructorStore.constructorConfig.token.title}</div>}
          <div className='tooltip-red-border'>{formatPrice(constructorStore.sellerMargin)} {constructorStore.constructorConfig.token.title} Locked collateral*</div>

          <p className='tooltip-bold green-box tooltip-indent'>To receive:</p>
          {difference > 0 && <div className='tooltip-green-border'>{formatPrice(difference)} {constructorStore.constructorConfig.token.title}</div>}

          <div className={'tooltip-total tooltip-indent'}><span>{difference > 0 ? 'Total to pay' : 'Total to receive'}</span> <span className={difference > 0 ? 'red' : 'green'}>{formatPrice(constructorStore.constructorConfig.bid)} {constructorStore.constructorConfig.token.title}</span></div>
          {constructorStore.sellerMargin !== 0 && <p><p className='tooltip-note'>*with this position you lock collateral until maturity <br/> on the maturity you will receive unlocked collateral ± profit <br/> and loss on the position</p></p>}          
        </> : ''} 
      </>
    )
  }

  return (
    <div className="ConstructorForm__footer">
      <DerivativeBidAskPopup priceText={activePrice}/>
      <div className="constructor-data-recieve">
        <div className="recieve-block">
          <div className='constructor-bid-ask'>
            <div className='bid-ask-item'>
              <div className='bid-ask-item-title'>
                Bid size
              </div>
              <div className='bid-ask-item-size'>
                {constructorStore.constructorConfig.endTime < now ? '-' : constructorStore.constructorConfig.bidSize ? +constructorStore.constructorConfig.bidSize.toFixed(5) : '-'}
              </div>
            </div>


            <div className='bid-ask-items'>

              <div className='bid-ask-titles'>
                <div className='bid-ask-item-title'>Bid</div>
                <div className='bid-ask-item-title'>Ask</div>
              </div>
              <div className='bid-ask-content'>
                { constructorStore.constructorConfig.endTime < now 
                  ? <div className={'bid-ask-expired'}>expired</div>
                  : <>
                    <div className='bid-ask-item mr-2'>
                      <div className={'bid-ask-item-size'}>
                        <Tooltip
                          theme={ETheme.DARK}
                          label={''}
                          trigger='hover'
                          placement='top'
                          className='derivatives'
                          content={
                            <div className={`derivatives-btn-tooltip color-scheme-${ETheme.DARK}`}>
                              <div className='tooltip-title'>Short position</div>
                              <div className='tooltip-content'>{renderBidPrice()}</div>
                            </div>
                          }
                          component={
                            <Button
                              theme={ETheme.DARK}
                              variant={'secondary'}
                              label={constructorStore.constructorConfig.bid !== null ? `${formatPrice(constructorStore.constructorConfig.bidPosition === EPosition.LONG ? constructorStore.constructorConfig.bid - constructorStore.buyerMargin : constructorStore.sellerMargin - constructorStore.constructorConfig.bid)} ${constructorStore.constructorConfig.token.title}` : '-'}
                              className="item-btn item-green"
                              onClick={(e: any ) => openBidPopup(e, `https://app.1inch.io/#/42161/classic/limit-order/${constructorStore.constructorConfig.token.address}/${constructorStore.constructorConfig.shortPosition.address}?sourceTokenAmount=${( constructorStore.constructorConfig.bid !== null && constructorStore.constructorConfig.bidSize !== null) ? (constructorStore.constructorConfig.bid * constructorStore.constructorConfig.bidSize) : 0}&destinationTokenAmount=${ constructorStore.constructorConfig.bidSize}`) }
                              size="sm"
                              newTab
                            />
                          }
                        />
                      
                      </div>
                    </div>
                    <div className='bid-ask-item ml-2'>
                      <div className={'bid-ask-item-size'}>
                        <Tooltip
                          theme={ETheme.DARK}
                          label={''}
                          trigger='hover'
                          placement='top'
                          className='derivatives'
                          content={
                            <div className={`derivatives-btn-tooltip color-scheme-${ETheme.DARK}`}>
                              <div className='tooltip-title'>Long position</div>
                              <div className='tooltip-content'>{renderAskPrice()}</div>
                            </div>
                          }
                          component={
                            <Button
                              theme={ETheme.DARK}
                              variant={'secondary'}
                              label={constructorStore.constructorConfig.ask !== null ? `${formatPrice(constructorStore.constructorConfig.askPosition === EPosition.LONG ? constructorStore.constructorConfig.ask - constructorStore.buyerMargin : constructorStore.sellerMargin - constructorStore.constructorConfig.ask)} ${constructorStore.constructorConfig.token.title}` : '-'}
                              className="item-btn item-red"
                              onClick={(e: any) => openAskPopup(e, `https://app.1inch.io/#/42161/classic/limit-order/${constructorStore.constructorConfig.token.address}/${constructorStore.constructorConfig.longPosition.address}?sourceTokenAmount=${( constructorStore.constructorConfig.ask !== null && constructorStore.constructorConfig.askSize !== null)? (constructorStore.constructorConfig.ask * constructorStore.constructorConfig.askSize) : 0}&destinationTokenAmount=${ constructorStore.constructorConfig.askSize}` )}
                              size="sm"
                              newTab
                            />
                          }
                        />
                      </div>
                    </div>
                  </>}
              </div>
            </div>

            <div className='bid-ask-item'>
              <div className='bid-ask-item-title'>
                Ask size
              </div>
              <div className='bid-ask-item-size'>
                {constructorStore.constructorConfig.askSize || '-'}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default observer(ReceiveTabContent)
