import { ReactNode } from 'react'

export type TConstructorSynthetic = {
    id: string, 
    title: string, 
    address: string
    ticker: string
    authorFee: number,
    authorAddress: string
  }
  
export type TConstructorOracle = {
    id: string, 
    title: string, 
    address: string
    ticker: string,
    underlyingAsset: string,
    referenceAsset: string,
    show: boolean
    underlyingImage: string
    referenceImage: string
  }

export type TConstructorStrikePrice = {
    [synthId: string]: {
      [oracleId: string]: number[]
    }
  }
  
export type TToken = {
    address: string
    decimals: number
    id: string
    title: string
    image: string
  }
export interface IStrategyDescription {
    children?: ReactNode
    header: string,
    positionClass?: EColor,
    positionText: string
}

export enum EColor {
  SUCCESS = 'success',
  DANGER = 'danger'
}

export enum EIdentifierType {
  DERIVATIVE = 'DERIVATIVE',
  LONG_POSITION = 'LONG_POSITION',
  SHORT_POSITION = 'SHORT_POSITION',
}

// export type TSyntheticId = {
//   id: string, 
//   title: string, 
//   address: string
//   ticker: string
//   authorFee: number
// }


export enum EPosition {
  SHORT = 'SHORT',
  LONG = 'LONG'
}


export type TDerivativeExplorerResponse = {
  identifierType: EIdentifierType
  derivativeHash: string
  longPosition: {
    address: string
    totalSupply: string
  }
  shortPosition: {
    address: string
    totalSupply: string
  }
  margin: string
  endTime: number
  params: string[]
  oracle: TConstructorOracle
  token: TToken
  synthetic: TConstructorSynthetic
  ask: number | null
  bid: number | null
  bidSize: number | null
  askSize: number | null
  bidPosition: EPosition | null,
  askPosition: EPosition | null 
  helpers: {
    strikePrice: number
    calculatedStrikePrice: number
  }
}


export type TDerivative = {
  margin: string;
  endTime: number;
  params: string[];
  oracleId: string;
  token: string;
  syntheticId: string;
}


export type TPayoutChartPoint = {
  buyerPayout: number,
  sellerPayout: number,
  price: number
}


enum EDerivativeType {
  OPTION_CALL = 'OPTION_CALL',
  OPTION_PUT = 'OPTION_PUT',
  CALL_SPREAD = 'CALL_SPREAD',
  PUT_SPREAD = 'PUT_SPREAD',
  SYNTH = 'SYNTH',
}

export type TAllDerivativesResponse = {
  derivativeHash: string
  ticker: string
  derivativeType: EDerivativeType
  endTime: number
  token: TToken
  collateralization: number
  underlying: {
    title: string
    image: string
  }

  longPosition: {
    address: string
    collateral: number
  }
  shortPosition: {
    address: string
    collateral: number
  }
  totalSupply: number
  bid: number | null
  bidSize: number | null
  bidPosition: EPosition | null,
  ask: number | null
  askSize: number | null
  askPosition: EPosition | null 
  chain?: string,
  protocol: string
}[]

export type TDerivativesCheckbox = {
  title: string | undefined,
  value: boolean
}

export enum EDerivativesFilterTimes {
  Active = 'Active',
  Expired = 'Expired'
 }

export enum EDerivativesFilterOptions {
  OPTION_CALL = 'Option call',
  OPTION_PUT = 'Option put',
  CALL_SPREAD = 'Call spread',
  PUT_SPREAD = 'Put spread'
 }

export type TDerivativesFilterButton = {
  title: string,
  type: EDerivativesFilterType
}

export enum EDerivativesFilterType {
  Times = 'Maturity',
  Options = 'Derivative types'
 }
