import React, { FC, useState } from 'react'
import { observer } from 'mobx-react'
import iconCopyLink from '../../../../Images/Icons/opium-copy-link.svg'
import iconCopyCheck from '../../../../Images/Icons/icon-copy-check.svg'


import './styles.scss'

const CopyIconText: FC<{text: string}> = ({ text }) => {
  const [iconPath, setIconPath] = useState<string>(iconCopyLink)
  const [isActive, setIsActive] = useState<boolean>(false)

  const onCopyClick = (text: string) => {
    navigator.clipboard.writeText(text)
    setIsActive(true)
    setIconPath(iconCopyCheck)
    setTimeout(() => {
      setIconPath(iconCopyLink)
    }, 5000)
  }

  return (
    <img src={iconPath}
      className={isActive ? 'animate-fade-out' : ''}
      onClick={() => onCopyClick(text)} />
  )
}

export default observer(CopyIconText)
