import { observable, action } from 'mobx'
import { ELoadingStatuses } from '../Constants/Types'
import { TRFQ, ERFQComponentType } from '../Constants/Types/defiRFQ'
import defiRFQApi from '../Services/defiRFQApi'

export class DefiRFQStore {

    @observable rfqTableDataLoading: ELoadingStatuses = ELoadingStatuses.IDLE
    @observable public rfqTableData: TRFQ = []

    // actions 

    @action fetchRFQDataForTable: () => Promise<void> = async (): Promise<void> => {
      try {
        const rfq = await defiRFQApi.getRFQData()
        this.rfqTableData = rfq?.map((el: TRFQ[0]) => ({ ...el, chain: 'arbitrum' }))

        // Example, remove in the future
        const exampleQuote: TRFQ[0] = {
          bid: 0.01,
          ask: 0.02,
          bidSize: 10,
          askSize: 10,
          chain: 'arbitrum',
          token: {
            title: 'ETH',
            decimals: 18,
            image: '',
            address: '',
            id: ''
          },
          requestedComponents: [
            { title: 'OFI-OPT-C-ETH/USD-22APR-3000', quantity: 1, type: ERFQComponentType.LONG },
            { title: 'OFI-OPT-C-ETH/USD-22APR-3100', quantity: 1, type: ERFQComponentType.SHORT }
          ],
          offeredComponents: [
            { title: 'OFI-SPRD-C-ETH/USD-22APR-3000/3100', quantity: 1, type: ERFQComponentType.LONG }
          ]
        }
        this.rfqTableData.unshift(exampleQuote)

        this.rfqTableDataLoading = ELoadingStatuses.SUCCEEDED
      } catch (err: any) {
        console.error(err)
        this.rfqTableDataLoading = ELoadingStatuses.FAILED
      }
    }

    @action setRFQDataLoading = (status: ELoadingStatuses) => {
      this.rfqTableDataLoading = status
    }

}

export default new DefiRFQStore()

