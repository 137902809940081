import React, { FC } from 'react'
import { observer } from 'mobx-react'
import {
  ETheme,
  BarChart,
  Tooltip,
  ControlledTabs,
  EControlledTabsSizes
} from '@opiumteam/react-opium-components'
import numeral from 'numeral'

import { TVolumesChartDataItem } from '../../Constants/Types/volumes'
import InfoLogo from '../../Images/Info.svg'

import './styles.scss'

type TProps = {
  chartData: TVolumesChartDataItem[],
  title: string,
  tabs?: {
    id: string,
    title: string,
  }[],
  activeTabId?: string,
  switchTab?: (newTabId: string) => any,
  hideSecondBar?: boolean
  hideExpectedBar?: boolean
  barSize?: number
  source: {
    title: string
    link: string
  }
}

const Chart: FC<TProps> = ({
  chartData,
  title,
  tabs,
  activeTabId = '',
  switchTab = () => {},
  hideSecondBar,
  hideExpectedBar,
  barSize,
  source
}: TProps) => {


  return (
    <div className='bar-chart-wrapper'>
      <div className='bar-chart-upper-section'>
        <div className='bar-chart-title'>
          {title}
          <Tooltip
            theme={ETheme.DARK}
            label={'!'}
            trigger='hover'
            placement='bottom'
            content={'The total value of derivatives bought/sold over a specific period of time, expressed in US dollars'}
            html={true}
            component={<img style={{ marginLeft: '0.5rem', marginBottom: '-2px', cursor: 'pointer' }} src={InfoLogo} />}
          />
        </div>
        <div>
          <div className="bar-chart-bottom-section">
            {tabs ? (
              <ControlledTabs
                theme={ETheme.DARK}
                tabs={tabs}
                activeTabId={activeTabId}
                size={EControlledTabsSizes.S}
                switchTab={switchTab}
              /> 
            ) :
              <div style={{ height: 30 }}></div>
            }

            <div className='bar-chart-source'>Source: <a href={source.link} target='_blank' rel='noreferrer'>{source.title}</a></div>
          </div>
        </div>
      </div>
      <div className='bar-chart-line' />
      <BarChart
        theme={ETheme.DARK}
        height='300px'
        width='100%'
        data={chartData}
        // labelX={{ value: '', position: 'insideBottom' }}
        // labelY={{ value: '', angle: -90, position: 'insideLeft', offset: -15 }}
        tickFormatterY={(label) => {
          return `$${numeral(label).format('0[.]0a')}`
        }}
        barSize={barSize || 30}
        hideSecondBar={hideSecondBar}
        hideExpectedBar={hideExpectedBar}
      />
    </div>
  )
}

export default observer(Chart)
