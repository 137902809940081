import React, { FC } from 'react'
import { observer } from 'mobx-react'


import './styles.scss'
import { EDirectionType, ISelectWithLabel } from '../../../../Constants/Types/component'

const SelectWithLabel: FC<ISelectWithLabel> = ({ text, direction = EDirectionType.COLUMN, children }: ISelectWithLabel) => {
  return (
    <div className={`select-with-label ${direction === EDirectionType.ROW ? 'horizontal' : ''}`}>
      <p className='select-with-label__text'>{text}</p>
      <div>{children}</div>
    </div>
  )

}

export default observer(SelectWithLabel)




