import axios, { AxiosInstance } from 'axios'
import config from '../Constants/Config/opiumApi'
import { TRFQ } from '../Constants/Types/defiRFQ'

const rfqEndpoint = `${config.opiumWatchEndpoint}`

export class defiRFQApi {
    private readonly _client: AxiosInstance

    public constructor() {
      this._client = axios.create({
        baseURL: rfqEndpoint,
        headers: {
          'Content-Type': 'application/json',
        },
      })
    }

    getRFQData = async (): Promise<TRFQ> => { 
      const data = await this._client.get('rfq?network=ARBITRUM')
      return data.data
    }
}

export default new defiRFQApi()
