import { ENetwork } from '@opiumteam/mobx-web3'
import { TProduct } from './product'

export enum EStakingType {
  PEER_2_POOL_POOLED = 'PEER_2_POOL_POOLED',
  PEER_2_POOL_POOLED_V2 = 'PEER_2_POOL_POOLED_V2',
}

export enum EPhase {
  STAKING = 'STAKING',
  TRADING = 'TRADING',
  IDLE = 'IDLE',
  NOT_INITIALIZED = 'NOT_INITIALIZED',
}

export enum EStakingRewardsPeriod {
  WEEKLY = 'WEEKLY',
}

type TStakingRewards = {
  amount: number
  period: EStakingRewardsPeriod
  distribution?: string
}

export type TItemLink = {
  [index: string]: Array<string>
}

export type TStaking = {
  id: string
  type: EStakingType
  title: string
  logo: string | null
  descriptionHTML: string
  productId: string
  rewards: TStakingRewards | null
  rewardsApr: number
  rewardsText: string
  isSuspended: boolean
  itemLinks: TItemLink
  totalRewardsAmount: number
}

export type TStakingResponse = TStaking & {
  userReward: number
  userStaked: number
  params: {
    epochLength: number
    stakingPhaseLength: number
    tradingPhaseLength: number
    currentEpochTimestamp: number
    poolSize: number
    poolUtilization: number
    hardcap: number
    yieldToDate: number
    yieldToDateAnnualized: number
    mintedAmount: number
  }
  network: ENetwork
}

export type TPopulatedStaking = TStakingResponse & {
  product: TProduct
}
