import React, { FC } from 'react'
import { TPopulatedStaking } from '../../../Constants/Types/staking'
import { useSortableData } from '../../../Utils/sorting'
import SortHeaderTable from '../../SortHeaderTable'
import CoveredCallTableRow from './CoveredCallTableRow'
import './styles.scss'

interface IProps {
  coveredCalls: TPopulatedStaking[],
}

const CoveredCallTable: FC<IProps> = ({ coveredCalls }: IProps) => {
  const columns = [
    { name: 'title', label: 'Name', sort: false, class: 'nowrap covered-call-name' },
    { name: 'product.params.ticker', label: 'Components', sort: false, class: 'nowrap covered-call-components' },
    { name: 'profit-loss', label: 'Profit/Loss', sort: false, class: 'nowrap covered-call-profit-loss' },
    { name: 'params.yieldToDateAnnualized', label: 'APR', sort: true, class: 'nowrap covered-call-apr' },
    { name: 'risk', label: 'Risk', sort: false, class: 'nowrap covered-call-risk' },
  ]

  const { items, requestSort, sortConfig } = useSortableData(coveredCalls)

  return (
    <table className="transparent-table clickable-rows covered-call-table">
      <SortHeaderTable headers={columns} requestSort={requestSort} sortConfig={sortConfig} />

      <tbody>
        {items.map((coveredCall, index) => (
          <CoveredCallTableRow key={index} coveredCall={coveredCall} />
        ))}
      </tbody>
    </table>
  )
}

export default CoveredCallTable
