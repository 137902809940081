import React, { FC } from 'react'
import { observer } from 'mobx-react'
import Logo from '../../Images/logo.svg'
import './styles.scss'

import MobileMenu from '../MobileMenu'
import { ETheme } from '@opiumteam/react-opium-components'
import { IPage } from '../../Constants/Types/pages'


import optionsIcon from '../../Images/Icons/options.svg'
import oraclesIcon from '../../Images/Icons/oracles.svg'
import volumesIcon from '../../Images/Icons/volumes.svg'
import knowledgeBaseIcon from '../../Images/Icons/knowledgeBase.svg'
import overviewIcon from '../../Images/Icons/overview.svg'
import explorerIcon from '../../Images/Icons/explorer.svg'
import auctionIcon from '../../Images/Icons/auction.svg'
import auctionIcon1 from '../../Images/Icons/auction1.svg'
import ConstructorDarkIcon from '../../Images/Icons/constructor-dark.svg'
import rfqIcon from '../../Images/Icons/rfq.svg'

const pages: Array<IPage> = [
  {
    classSelector: 'volumes',
    idLabel: 'volumes',
    title: 'Overview',
    to: '/overview',
    icon: overviewIcon,
    disabled: false
  },
  // {
  //   classSelector: 'prices',
  //   idLabel: 'prices',
  //   title: 'Prices',
  //   to: '/prices',
  //   icon: '',
  //   disabled: false
  // },
  {
    classSelector: 'explorer',
    idLabel: 'explorer',
    title: 'Explorer',
    to: '/derivative',
    icon: explorerIcon,
    disabled: false
  },
  {
    classSelector: 'options',
    idLabel: 'options',
    title: 'Implied volatility',
    to: '/implied-volatility',
    icon: optionsIcon,
    disabled: false
  },
  {
    classSelector: 'oracles',
    idLabel: 'oracles',
    title: 'Oracles',
    to: '/oracles',
    icon: oraclesIcon,
    disabled: false
  },
  {
    classSelector: 'defi-rfq',
    idLabel: 'defi-rfq',
    title: 'DeFi RFQ',
    to: '/rfq',
    icon: rfqIcon,
    disabled: false
  },
  // {
  //   classSelector: 'knowledge',
  //   idLabel: 'knowledge',
  //   title: 'Knowledge base',
  //   to: '/knowledge-base',
  //   icon: knowledgeBaseIcon,
  //   disabled: true
  // },
  {
    classSelector: 'auction',
    idLabel: 'auction',
    title: 'Auction data',
    to: '/auction-data',
    icon: auctionIcon,
    disabled: true
  },
] 

const Header: FC<{}> = () => {

  return (
    <div className='header'>
      <img className='header-desktop-logo' src={Logo} alt='logo'/>
      <MobileMenu
        theme={ETheme.DARK}
        menuItems={pages}
        isLinkEnabled={false}
        onLinkClick={() => {}}
      />
    </div>
  )
}

export default observer(Header)
