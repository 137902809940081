import React, { FC } from 'react'
import { observer } from 'mobx-react'


import './styles.scss'


const Footer: FC<{}> = () => {

  return (
    <div className='footer-wrapper'>
      This website is independent of other websites (or any organizations) and aggregates data from several sources. It displays data for information purposes only. The Opium team, community, or third parties are not liable for the inaccuracies in data — use at your own risk, “as it is” for informational purposes only.
    </div>
  )
}

export default observer(Footer)
