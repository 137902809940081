import React, { FC } from 'react'
import moment from 'moment'

import { TPopulatedStaking } from '../../../Constants/Types/staking'
import { convertAvgCost, getStakingPhase } from '../../../Utils/staking'
import { convertDecimals } from '../../../Utils/helpers'

import CoveredCallCharts from './CoveredCallCharts'

interface IProps {
  coveredCall: TPopulatedStaking;
}

const CoveredCallStrategyPopupContent: FC<IProps> = ({ coveredCall }: IProps) => {
  const {
    params: {
      currentEpochTimestamp,
      yieldToDate,
      yieldToDateAnnualized,
      epochLength,
      poolSize,
      poolUtilization,
      stakingPhaseLength,
      tradingPhaseLength,
    },
    product: {
      avgCost,
      avgCostFrequency,
      params: {
        withdrawalFee,
        marginTitle,
        collateralization,
      }
    }
  } = coveredCall

  const totalStaked = `${convertDecimals(poolSize).toLocaleString('en')} ${marginTitle}`

  const currentPoolUtilization = `${(poolUtilization * 100).toFixed(0)}%`

  const benchmark = convertAvgCost(avgCost, avgCostFrequency, collateralization)

  const collateralizationPercent = `${collateralization * 100}%`

  const { nextStakingPhaseTimestamp } = getStakingPhase({
    epochLength,
    stakingPhaseLength,
    tradingPhaseLength,
    maturity: currentEpochTimestamp,
  })
  const nextRebalancing = moment(nextStakingPhaseTimestamp * 1000).format('DD MMM YYYY')

  return (
    <div className="popup-wrapper">
      <div className="info">
        <ul>
          <li>
            <div className="info-label">Total staked in pool</div>
            <div className="info-value">{totalStaked}</div>
          </li>

          <li>
            <div className="info-label">Withdrawal fee</div>
            <div className="info-value">{withdrawalFee}%</div>
          </li>

          <li>
            <div className="info-label">Profit fee</div>
            <div className="info-value">5%</div>
          </li>

          <li>
            <div className="info-label">Current pool&#39;s utilization</div>
            <div className="info-value">{currentPoolUtilization}</div>
          </li>

          {yieldToDate !== 0 ? (
            <li>
              <div className="info-label">Return since inception</div>
              <div className="info-value">{yieldToDate.toFixed(2)}%</div>
            </li>
          ) : (
            <li>
              <div className="info-label">Benchmark return</div>
              <div className="info-value">{benchmark}</div>
            </li>
          )}

          {yieldToDate !== 0 && (
            <li>
              <div className="info-label">Projected APR</div>
              <div className="info-value">{yieldToDateAnnualized.toFixed(2)}%</div>
            </li>
          )}

          <li>
            <div className="info-label">Collateral</div>
            <div className="info-value">{marginTitle}</div>
          </li>

          <li>
            <div className="info-label">Collateralization ratio</div>
            <div className="info-value">{collateralizationPercent}</div>
          </li>

          <li>
            <div className="info-label">Next rebalancing (roll)</div>
            <div className="info-value">{nextRebalancing}</div>
          </li>
        </ul>
      </div>

      <div className="charts">
        <CoveredCallCharts coveredCall={coveredCall} />
      </div>
    </div>
  )
}

export default CoveredCallStrategyPopupContent
