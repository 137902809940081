import { EAvgCostFrequency } from '../Constants/Types/component'
import { EPhase } from '../Constants/Types/staking'

export const getPoolPeriod = (epochLength: number) => {
  const days = epochLength / 60 / 60 / 24
  switch (days) {
    case 1:
      return 'Daily'
    case 3:
      return '3 days'
    case 7:
      return 'Weekly'
    case 30:
      return 'Monthly'
    default:
      return ''
  }
}

export const getStakingPhase = ({
  epochLength,
  stakingPhaseLength,
  tradingPhaseLength,
  maturity,
}: {
  epochLength: number
  stakingPhaseLength: number
  tradingPhaseLength: number
  maturity: number
}) => {
  const now = ~~(Date.now() / 1000)
  const TIME_DELTA = 60

  const stakingPhaseStart = maturity - epochLength
  const stakingPhaseEnd = stakingPhaseStart + stakingPhaseLength
  const tradingPhaseEnd = stakingPhaseEnd + tradingPhaseLength

  const isStakingPhase = stakingPhaseStart + TIME_DELTA < now && now < stakingPhaseEnd - TIME_DELTA
  const isTradingPhase = stakingPhaseEnd + TIME_DELTA < now && now < tradingPhaseEnd - TIME_DELTA
  const isIdlePhase =
    (stakingPhaseStart < now && now < stakingPhaseStart + TIME_DELTA) ||
    (stakingPhaseEnd - TIME_DELTA < now && now < stakingPhaseEnd + TIME_DELTA) ||
    (tradingPhaseEnd - TIME_DELTA < now && now < tradingPhaseEnd) ||
    (tradingPhaseEnd < now && now < maturity)

  const phase: EPhase = isStakingPhase
    ? EPhase.STAKING
    : isTradingPhase
      ? EPhase.TRADING
      : isIdlePhase
        ? EPhase.IDLE
        : EPhase.NOT_INITIALIZED

  const nextTradingPhaseTimestamp: number =
    now < stakingPhaseEnd + TIME_DELTA
      ? stakingPhaseEnd + TIME_DELTA
      : maturity + stakingPhaseLength + TIME_DELTA
  const nextStakingPhaseTimestamp: number = maturity + TIME_DELTA
  const nextIdlePhase: number = tradingPhaseEnd

  return {
    phase,
    stakingPhaseEnd: stakingPhaseEnd - TIME_DELTA,
    nextTradingPhaseTimestamp,
    nextStakingPhaseTimestamp,
    nextIdlePhase,
    stakingPhaseStart,
  }
}

export const convertAvgCost = (avgCost: number, avgCostFrequency: EAvgCostFrequency | null, collateralization: number) => {
  let pow = 1

  switch (avgCostFrequency) {
    case EAvgCostFrequency.ANNUAL:
      pow = 1
      break
    case EAvgCostFrequency.DAILY:
      pow = 365
      break
    case EAvgCostFrequency.MONTHLY:
      pow = 12
      break
    case EAvgCostFrequency.PER_3_DAYS:
      pow = 120
      break
    case EAvgCostFrequency.WEEKLY:
      pow = 52
      break
  }

  return `${(((1 + (avgCost / 100 / collateralization)) ** pow - 1) * 100).toFixed(1)}%`
}
