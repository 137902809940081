import axios, { AxiosInstance } from 'axios'
import numeral from 'numeral'
import moment from 'moment'
import { formatDate } from '../Utils/helpers'
import { TVolumesChartDataItem } from '../Constants/Types/volumes'

const deribitEndpoint = 'https://www.deribit.com/api/v2'

export class DeribitApi {
  private readonly _client: AxiosInstance
  private _instruments: any[] = []

  public constructor() {
    this._client = axios.create({
      baseURL: deribitEndpoint,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  getTradeVolumes = async () => {
    const response = await this._client.get('/public/get_trade_volumes?extended=true')
    const [btcVolumes, ethVolumes] = response.data.result
    const ethPrice = await this.getIndexPrice('eth_usd')
    const btcPrice = await this.getIndexPrice('btc_usd')

    const chartDataDaily: TVolumesChartDataItem[] = [
      {
        label: 'Put Options',
        barData0: btcVolumes.puts_volume * btcPrice,
        barData2: ethVolumes.puts_volume * ethPrice,
        barLabel0: 'BTC',
        barLabel2: 'ETH',
        barData1: 0,
        barLabel1: '',
      },
      {
        label: 'Futures',
        barData0: btcVolumes.futures_volume * btcPrice,
        barData2: ethVolumes.futures_volume * ethPrice,
        barLabel0: 'BTC',
        barLabel2: 'ETH',
        barData1: 0,
        barLabel1: '',
      },
      {
        label: 'Call Options',
        barData0: btcVolumes.calls_volume * btcPrice,
        barData2: ethVolumes.calls_volume * ethPrice,
        barLabel0: 'BTC',
        barLabel2: 'ETH',
        barData1: 0,
        barLabel1: '',
      },
    ]

    const chartDataWeekly: TVolumesChartDataItem[] = [
      {
        label: 'Put Options',
        barData0: btcVolumes.puts_volume_7d * btcPrice,
        barData2: ethVolumes.puts_volume_7d * ethPrice,
        barLabel0: 'BTC',
        barLabel2: 'ETH',
        barData1: 0,
        barLabel1: '',
      },
      {
        label: 'Futures',
        barData0: btcVolumes.futures_volume_7d * btcPrice,
        barData2: ethVolumes.futures_volume_7d * ethPrice,
        barLabel0: 'BTC',
        barLabel2: 'ETH',
        barData1: 0,
        barLabel1: '',
      },
      {
        label: 'Call Options',
        barData0: btcVolumes.calls_volume_7d * btcPrice,
        barData2: ethVolumes.calls_volume_7d * ethPrice,
        barLabel0: 'BTC',
        barLabel2: 'ETH',
        barData1: 0,
        barLabel1: '',
      },
    ]

    const chartDataMonthly: TVolumesChartDataItem[] = [
      {
        label: 'Put Options',
        barData0: btcVolumes.puts_volume_30d * btcPrice,
        barData2: ethVolumes.puts_volume_30d * ethPrice,
        barLabel0: 'BTC',
        barLabel2: 'ETH',
        barData1: 0,
        barLabel1: '',
      },
      {
        label: 'Futures',
        barData0: btcVolumes.futures_volume_30d * btcPrice,
        barData2: ethVolumes.futures_volume_30d * ethPrice,
        barLabel0: 'BTC',
        barLabel2: 'ETH',
        barData1: 0,
        barLabel1: '',
      },
      {
        label: 'Call Options',
        barData0: btcVolumes.calls_volume_30d * btcPrice,
        barData2: ethVolumes.calls_volume_30d * ethPrice,
        barLabel0: 'BTC',
        barLabel2: 'ETH',
        barData1: 0,
        barLabel1: '',
      },
    ]

    return {
      chartDataDaily,
      chartDataWeekly,
      chartDataMonthly,
    }
  }

  getIndexPrice = async (index: string) => {
    const response = await this._client.get(`public/get_index_price?index_name=${index}`)
    const price: number = response.data.result.index_price
    return price
  }

  getHistoricalVolatility = async (currency: string) => {
    const response = await this._client.get(`public/get_historical_volatility?currency=${currency}`)
    const volatilityData = response.data.result

    const parsedData = volatilityData.map((item: any) => {
      const [date, vol] = item
      return {
        label: date,
        tooltipLabel: moment(date).format('DD MMM YYYY HH:mm'),
        lineData: numeral(vol).format('0[.]00'),
        valueMeaning: 'Volatility',
      }
    })
    parsedData.shift()

    const min = parsedData.reduce((prev: any, curr: any) => {
      return +prev.lineData < +curr.lineData ? prev : curr
    })

    const max = parsedData.reduce((prev: any, curr: any) => {
      return +prev.lineData > +curr.lineData ? prev : curr
    })

    return {
      data: parsedData,
      domainY: [+min.lineData - 5, +max.lineData + 5],
    }
  }

  getTicker = async ({
    currency,
    date,
    strikePrice,
    optionKind,
  }: {
    currency: string
    date: string
    strikePrice: number
    optionKind: string
  }) => {
    const instrumentName = `${currency}-${date}-${strikePrice}-${optionKind}` // ETH-24DEC21-6000-P
    const response = await this._client.get(`public/ticker?instrument_name=${instrumentName}`)
    return response.data.result
  }

  getInstruments = async (currency: string): Promise<any> => {
    const response = await this._client.get(`public/get_instruments?currency=${currency}`)
    return response.data.result
  }

  getSkewForDate = async ({ currency, date }: { currency: string; date: number }) => {
    try {
      let currencyPrice = await this.getIndexPrice(`${currency.toLocaleLowerCase()}_usd`)
      currencyPrice = Math.round(currencyPrice / 100) * 100

      const formattedDate = formatDate(date, 'DMMMYY').toLocaleUpperCase()

      const strikePrices = [
        currencyPrice - 500,
        currencyPrice - 400,
        currencyPrice - 300,
        currencyPrice - 200,
        currencyPrice - 100,
        currencyPrice,
        currencyPrice + 100,
        currencyPrice + 200,
        currencyPrice + 300,
        currencyPrice + 400,
        currencyPrice + 500,
      ]

      const tickers: Array<any> = []
      let data: Array<any> = []

      for (let strikePrice of strikePrices) {
        const optionKind = strikePrice < currencyPrice ? 'C' : 'P'

        try {
          const ticker = await this.getTicker({
            currency,
            date: formattedDate,
            strikePrice,
            optionKind,
          })

          tickers.push({ strikePrice, date, markIV: ticker.mark_iv })
        } catch (err) {}
      }

      data = tickers
        .sort((a, b) => a.strikePrice - b.strikePrice)
        .map((ticker) => ({
          label: ticker.strikePrice,
          tooltipLabel: `Strike price: ${ticker.strikePrice}`,
          lineData: ticker.markIV,
          valueMeaning: 'Implied volatility',
        }))

      const min = tickers.reduce((prev: any, curr: any) =>
        prev.markIV < curr.markIV ? prev : curr
      )
      const max = tickers.reduce((prev: any, curr: any) =>
        prev.markIV > curr.markIV ? prev : curr
      )

      const domainY = [min.markIV - 1, max.markIV + 1]

      return { data, domainY }
    } catch (err) {
      return { data: [], domainY: [] }
    }
  }

  getSkewForStrikePrice = async ({
    currency,
    strikePrice,
  }: {
    currency: string
    strikePrice: number
  }) => {
    try {
      let currencyPrice = await this.getIndexPrice(`${currency.toLocaleLowerCase()}_usd`)
      currencyPrice = Math.round(currencyPrice / 100) * 100

      const tickers: Array<any> = []
      let data: Array<any> = []

      const optionType = strikePrice < currencyPrice ? 'call' : 'put'
      const optionKind = strikePrice < currencyPrice ? 'C' : 'P'

      if (!this._instruments.length) {
        this._instruments = await this.getInstruments(currency)
      }

      const filteredInstruments = this._instruments.filter(
        (i: any) => i.strike === strikePrice && i.option_type === optionType
      )

      const dates: number[] = filteredInstruments.map((i: any) => i.expiration_timestamp / 1000)

      for (let date of dates) {
        const formattedDate = formatDate(date, 'DMMMYY').toLocaleUpperCase()

        try {
          const ticker = await this.getTicker({
            currency,
            date: formattedDate,
            strikePrice,
            optionKind,
          })

          tickers.push({
            strikePrice,
            date: date * 1000,
            markIV: ticker.mark_iv,
          })
        } catch (err) {}
      }

      data = tickers
        .sort((a, b) => moment(a.date).unix() - moment(b.date).unix())
        .map((ticker) => ({
          label: ticker.date,
          tooltipLabel: moment(ticker.date).format('DD MMM YYYY HH:mm'),
          lineData: ticker.markIV,
          valueMeaning: 'Implied volatility',
        }))

      const min = tickers.reduce((prev: any, curr: any) =>
        prev.markIV < curr.markIV ? prev : curr
      )
      const max = tickers.reduce((prev: any, curr: any) =>
        prev.markIV > curr.markIV ? prev : curr
      )

      const domainY = [min.markIV - 1, max.markIV + 1]

      return { data, domainY }
    } catch (err) {
      return { data: [], domainY: [] }
    }
  }
}

export default new DeribitApi()
