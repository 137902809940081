import { ETheme } from '@opiumteam/react-opium-components'
import React, { FC } from 'react'

type TProps = {}

const CallOption: FC<TProps> = () => {
  return (
    <>
      <div className={`KnowledgeBase__block-title bottom-line color-scheme-${ETheme.DARK}`}>
        Call Option
      </div>
      <div className="KnowledgeBase__block-content">
        Call option is a Derivative contract between two counterparties, where one party (Option buyer, Long Call) has the <strong>right, but not an obligation</strong> to buy an Underlying asset at Expiration at a specific price (called Strike) from the other party (Option seller, Short Call). Option seller has an <strong>obligation</strong> to sell the Underlying at Strike price. Option buyer pays to Option seller a specific sum, which is called Premium, to enter into the Option contract.
      </div>
      <div className="KnowledgeBase__block-content">
        Payoff of the Call option at Expiration (received by Option buyer and paid by Option seller) can be formalized in a simple formula:
      </div>
      <div className="KnowledgeBase__block-content">
        Payoff = Notional*max(0; Fixing – Strike).
      </div>
      <div className="KnowledgeBase__block-content">
          This is easily visualized on the Payoff diagram (see above).
      </div>
      <div className="KnowledgeBase__block-content">
          Call Option buyer can hedge the risk of rising Underlying price (maybe he is borrowing the Underlying) or participate in the growth of Underlying price without taking a risk of price drop (the only thing he is risking is Premium).
      </div>
      <div className="KnowledgeBase__block-content">
          Call Option seller receives Premium and can enjoy it as long as Underlying price does not exceed the Strike.
      </div>

    </>
  )
}

export default CallOption
