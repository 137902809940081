import { ETheme } from '@opiumteam/react-opium-components'
import React, { FC } from 'react'

type TProps = {}

const Collar: FC<TProps> = () => {
  return (
    <>
      <div className={`KnowledgeBase__block-title bottom-line color-scheme-${ETheme.DARK}`}>
        Covered Collar
      </div>
      <div className="KnowledgeBase__block-content">
        Collar is a combination of three components: long position in Underlying, long (bought) Put option with lower Strike and short (sold) Call option with higher Strike. By employing this strategy, investor obtains the right to sell Underlying at lower Strike and has an obligation to sell it at higher Strike. 
      </div>
      <div className="KnowledgeBase__block-content">
        Thus, investor is exposed to price movement of the Underlying between the two Strikes and hedged outside of this range: he foregoes extra return in case of Underlying appreciation above higher Strike but is protected from price decline below lower Strike. 
      </div>
      <div className="KnowledgeBase__block-content">
        This strategy can be structured to have zero cost at start (no Premium is paid) by choosing appropriate Strikes.
      </div>
    </>
  )
}

export default Collar
