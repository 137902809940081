import React, { useEffect, FC } from 'react'
import { observer } from 'mobx-react'
import { Tabs, ComposedChart, ETheme, Chart, MultipleLineChart, Tooltip } from '@opiumteam/react-opium-components'
import moment from 'moment'

import volumesStore from '../../../Stores/VolumesStore'
import { ELoadingStatuses } from '../../../Constants/Types'
import { TPopulatedStaking } from '../../../Constants/Types/staking'
import { EPeer2PoolInsuranceProductParamsSubtype } from '../../../Constants/Types/product'
import { useWindowSize } from '../../../Utils/hooks'
import InfoLogo from '../../../Images/Info.svg'

interface IProps {
  coveredCall: TPopulatedStaking;
}

const CoveredCallCharts: FC<IProps> = ({ coveredCall }: IProps) => {
  const {
    title,
    network,
    params: {
      currentEpochTimestamp,
    },
    product: {
      id: productId,
      params: {
        marginTitle,
        poolAddress,
        subtype,
        inverseTrigger,
        referenceTitle,
        chartInfo,
        underlyingTitle
      }
    }
  } = coveredCall

  const { width } = useWindowSize()

  let chartWidth
  let chartHeight = '290px'

  if (width >= 1200) {
    chartWidth = '650px'
  } else if (width < 1200 && width >= 992) {
    chartWidth = '450px'
  }

  useEffect(() => {
    volumesStore.getPastPerfomanceChartData(poolAddress, network)
    volumesStore.getProductChartData(productId, network)
    volumesStore.getTokenAnalyticsChartData(poolAddress, marginTitle, network)
  }, [productId])

  const errMessage = (
    <div className="error error-wrapper tab-wrapper">
      Something went wrong. Please, refresh the page.
    </div>
  )

  let pastPerfomanceContent
  switch (volumesStore.pastPerfomanceChartLoading) {
    case ELoadingStatuses.FAILED:
      pastPerfomanceContent = errMessage
      break
    case ELoadingStatuses.SUCCEEDED:
      pastPerfomanceContent = (
        <div className="tab-wrapper">
          <ComposedChart
            theme={ETheme.DARK}
            height={chartHeight}
            width={chartWidth}
            data={volumesStore.pastPerfomanceChart.data}
            labelY={{
              value: 'performance, %',
              angle: -90,
              position: 'insideLeft',
            }}
            domainY={[
              volumesStore.pastPerfomanceChart.minYTotal,
              volumesStore.pastPerfomanceChart.maxYTotal,
            ]}
            tickFormatterX={(label) => label ? moment(label).format('D MMM') : label}
            increaseDomainY={0.1}
          />
        </div>
      )
      break
    default:
      pastPerfomanceContent = <></>
      break
  }

  let plOpiumContent
  switch (volumesStore.productChartLoading) {
    case ELoadingStatuses.FAILED:
      plOpiumContent = errMessage
      break
    case ELoadingStatuses.SUCCEEDED:
      if (volumesStore.productChart.data && volumesStore.productChart.id === productId) {
        plOpiumContent = (
          <div className="tab-wrapper">
            <Chart
              theme={ETheme.DARK}
              height={chartHeight}
              width={chartWidth}
              data={volumesStore.productChart.data.items}
              domainX={[
                volumesStore.productChart.data.minPrice,
                volumesStore.productChart.data.maxPrice,
              ]}
              domainY={[
                volumesStore.productChart.data.minData1,
                volumesStore.productChart.data.maxData1,
              ]}
              labelX={{
                value: chartInfo?.PNL_CHART?.xLabel,
                position: 'insideBottom',
              }}
              labelY={{
                value: chartInfo?.PNL_CHART?.yLabel,
                position: 'insideLeft',
                angle: -90,
              }} 
              chartData1={{ tooltipSuffix: '%', tooltipTitle: 'Pool' }}
              increaseDomainY={0.1}
            />
            <div className="pl-1inch-footnote">
              * at 100% utilisation
            </div>
          </div>
        )
      } else {
        plOpiumContent = <></>
      }
      break
    default:
      plOpiumContent = <></>
      break
  }

  let holdingVsStakingContent
  switch (volumesStore.tokenAnalyticsChartDataLoading) {
    case ELoadingStatuses.FAILED:
      holdingVsStakingContent = errMessage
      break
    case ELoadingStatuses.SUCCEEDED:
      const tickChanger = (dataIndex: number) => {
        return moment.unix(volumesStore.tokenAnalyticsChartData.items[dataIndex].label).format('DD MMM YYYY')
      }

      const chartData = volumesStore.tokenAnalyticsChartData.items.map(item => ({
        ...item,
        customTooltip: (
          <div className="token-analytics-chart-tooltip">
            <div className="chart-tooltip-field lp-price">
              <div className="chart-tooltip-label">LP token</div>
              <div className="chart-tooltip-value">${item.data2.toFixed(2)}</div>
            </div>
            <div className="chart-tooltip-field token-price">
              <div className="chart-tooltip-label">{marginTitle}</div>
              <div className="chart-tooltip-value">${item.data1.toFixed(2)}</div>
            </div>
          </div>
        ),
      }))
    
      holdingVsStakingContent = (
        <div className="tab-wrapper token-analytics">
          <div className="tooltip-wrapper">
            <Tooltip
              theme={ETheme.DARK}
              label={'!'}
              trigger='hover'
              placement='bottom'
              content={'"Holding vs Staking" shows the difference between price of the underlying token and token staked in the pool'}
              component={(
                <img
                  style={{ marginLeft: '0.5rem', marginBottom: '-2px', cursor: 'pointer' }}
                  src={InfoLogo} 
                />
              )}
            />
          </div>

          <MultipleLineChart
            theme={ETheme.DARK}
            height={chartHeight}
            width={chartWidth}
            data={chartData}
            domainY={[
              volumesStore.tokenAnalyticsChartData.minYTotal,
              volumesStore.tokenAnalyticsChartData.maxYTotal,
            ]}
            labelY={{
              value: 'price, $',
              position: 'insideLeft',
              angle: -90,
            }} 
            tickFormatterY={el => el.toFixed(2)}
            tickFormatterX={tickChanger}
            chartData1={{ tooltipSuffix: '$', tooltipTitle: `${marginTitle}` }}
            chartData2={{ tooltipSuffix: '$', tooltipTitle: 'LP token' }}
            increaseDomainY={0.1}
          />
        </div>
      )
      break
    default:
      holdingVsStakingContent = <></>
      break
  }

  let riskDisclosureContent = (
    <div className="staking-risk-disclosure-text tab-wrapper">
      <div>Acting as a liquidity provider (LP) for staking pools is an inherently risky undertaking</div>
      <br/>
      <div><b>Please do not transact with more funds than you can afford to lose.</b></div>
      <br/>
      <div>
        Once you stake any amount into a pool, you become a liquidity provider. Please note that you will be able to withdraw your funds during the Rebalancing phase only, during which no products can be bought by counterparties.
      </div>
      <br/>
      <div>
        The staking pool earns on fees from{' '}
        <b>{title}</b>{' '}
        program buyers. However, there is a risk of loss if the price of the underlying will be{' '}
        <b>
          {subtype === EPeer2PoolInsuranceProductParamsSubtype.OPTION_PUT_REAL ? 'bellow' : 'above'}{' '}
          {inverseTrigger.toFixed(2)}{' '}
          {referenceTitle}{' '}
        </b>{' '}
        by the time of maturity -{' '}
        <b>
          {moment.unix(currentEpochTimestamp).format('D MMM YYYY HH:mm UTC').toUpperCase()}
        </b>{' '}
        . So the pool will have to pay compensation to the buyers of{' '}
        <b>{title}</b>{' '}
        .{' '}
        <br/>

      </div>
      <div>Profit/Loss can be found in the P&L chart.</div>
    </div>
  )

  return (
    <Tabs
      items={[
        {
          title: 'Past perfomance',
          eventKey: 'past-perfomance',
          content: pastPerfomanceContent,
        },
        {
          title: `P&L, $${underlyingTitle}`,
          eventKey: 'pl-opium',
          content: plOpiumContent,
        },
        {
          title: 'Holding vs Staking',
          eventKey: 'holding-vs-staking',
          content: holdingVsStakingContent,
        },
        {
          title: 'Risk Disclosure',
          eventKey: 'risk-dislosure',
          content: riskDisclosureContent,
        },
      ]}
    />
  )
}

export default observer(CoveredCallCharts)
