import { TToken } from './constructor'

export enum ERFQComponentType {
  LONG = 'LONG',
  SHORT = 'SHORT',
  COLLATERAL = 'COLLATERAL'
}


export type TRFQComponent = {
    title: string
    quantity: number
    type: ERFQComponentType
  }

export type TRFQ = {
    requestedComponents: TRFQComponent[]
    offeredComponents: TRFQComponent[]
    bid: number | null
    bidSize: number | null
    ask: number | null
    askSize: number | null
    token: TToken
    chain: string
  }[]
