import React, { FC } from 'react'
import { observer } from 'mobx-react'
import auctionStore from '../../Stores/AuctionStore'
import { useRefreshAnimation } from '../../Utils/hooks'
import { useSortableData } from '../../Utils/sorting'
import SortHeaderTable from '../SortHeaderTable'
import { formatDate, networkLogos, renderOraclesIcon } from '../../Utils/helpers'
import { ETheme, Tooltip } from '@opiumteam/react-opium-components'

import './styles.scss'
import { TAuctionItem } from '../../Constants/Types/auctions'

const AuctionData: FC<{}> = ({}) => {
  const headers = [
    { name: 'underlying', label: 'Underlying', sort: false },
    { name: 'phase', label: 'Phase', sort: false },
    { name: 'ticker', label: 'Ticker', sort: false },
    { name: 'totalSize', label: 'Size', sort: false },
    { name: 'startDate', label: 'Start date', sort: false },
    { name: 'endDate', label: 'Finish date', sort: false },
    { name: 'bid', label: 'Latest bid', sort: false },
    { name: 'chain', label: 'Chain', sort: false }
  ]

  const { animation, updateTable, refreshDataTable } = useRefreshAnimation(auctionStore.fetchAuctionsDataForTable, auctionStore.setAuctionsDataLoading, auctionStore.auctionTableDataLoading, 5000)
  const { items, requestSort, sortConfig } = useSortableData(auctionStore.auctionTableData)
  return (
    <div className='AuctionsPage__wrapper'>
      <div className='page-title'>Auctions</div>

      <div className="derivatives-tab-section actions-refresh-section">

        <button className='derivatives-tabs-anim-svg' onClick={refreshDataTable}>
          {updateTable ? 'Refreshing...' : 'Refresh'}
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ visibility: !updateTable ? 'visible': 'hidden' }}>
            <circle cx="7.5" cy="7.5" r="6.5" stroke="#5D5F7C" strokeWidth="1.5"/>
            <circle cx="7.5" cy="7.5" r="6.5" stroke="white" strokeWidth="1.5" className={`${animation ? 'auction-dash': undefined}`}/>
          </svg>

          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ visibility: updateTable ? 'visible': 'hidden' }} className="circle-svg">
            <circle cx="7.5" cy="7.5" r="6.5" stroke="#5D5F7C" strokeWidth="1.5"/>
            <circle cx="7.5" cy="7.5" r="6.5" stroke="white" strokeWidth="1.5" className={ `${updateTable ? 'derivatives-circle': undefined}`} />
          </svg>
        </button>

      </div>

      {(() => {
        return (
          <>
            <div className='AuctionTable'>
              <table className='transparent-table auctions-table'>
                <SortHeaderTable headers={headers} requestSort={requestSort} sortConfig={sortConfig} />

                <tbody>
                  {items.map((item: TAuctionItem, i) => (
                    <tr className={`${item.phase === 'Active' ? 'row-green' : ''} auctions-item-wrapper`} key={i} >
                      <td>
                        <Tooltip
                          theme={ETheme.DARK}
                          label={''}
                          trigger='hover'
                          placement='top'
                          content={item.asset}
                          html={true}
                          component={<img className="auctions-item-icon oracle-icon-circle" src={renderOraclesIcon(item.asset)}/>}
                        />
                      </td>
                      <td className={`${item.phase === 'Active' ? 'text-green' : item.phase === 'Upcoming' ? 'text-yellow' : 'auctions-item-bold'}`}>{item.phase}</td>
                      <td className="auctions-item-bold">{item.ticker}</td>
                      <td>{item.totalSize.toLocaleString()} {item.token.title}</td>
                      <td>{item.startDate ? formatDate(+item.startDate, 'DD MMM YYYY HH:mm:ss UTC') : '-'}</td>
                      <td>{item.endDate ? formatDate(+item.endDate, 'DD MMM YYYY HH:mm:ss UTC') : '-'}</td>
                      <td className="auctions-item-bid">
                        <div>{item.bid} {item.token.title}</div>
                      </td>
                      <td>
                        <Tooltip
                          theme={ETheme.DARK}
                          label={''}
                          trigger='hover'
                          placement='top'
                          content={item.chain && (item.chain[0].toUpperCase() + item.chain.slice(1))}
                          html={true}
                          component={<img className="auctions-item-icon" src={networkLogos(item.chain)}/>}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )
      })()}
    </div>
  )
}

export default observer(AuctionData)
