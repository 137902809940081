import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { ETheme, Chart, Tooltip, Button, Tabs, Loading } from '@opiumteam/react-opium-components'
import numeral from 'numeral'

import './styles.scss'
import SelectWithLabel from './Components/SelectWithLabel'
import { useMobile } from '../../Utils/hooks'
import ReceiveTabContent from './Components/ReceiveTabContent'
import CopyIconText from './Components/CopyIconText'
import DerivativeTable from './Components/DerivativeTable'
import { shortenDerivativeHash, prepareIdentifierText } from './Helpers/helpers'
import StrategyDescription from './Components/StrategyDescription'
import constructorStore from '../../Stores/ConstructorStore'
import { EColor } from '../../Constants/Types/constructor'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { ELoadingStatuses } from '../../Constants/Types'
import info from './../../Images/Info.svg'
import openLink from './../../Images/Icons/opium-open-link.svg'
import iconBack from './../../Images/Icons/icon-back.svg'
import ArbitrumLogo from './../../Images/networks/arbitrum.svg'

interface IMatchParams {
  identifier: string
}

interface IProps extends RouteComponentProps<IMatchParams> {}

const Constructor: React.FC<IProps> = ({ match }) => {
  const history = useHistory()
  const identifierType = constructorStore.constructorConfig?.identifierType

  const getDataChartAndPrice = () => {
    constructorStore.fetchPayoutChart()
    constructorStore.getOraclePrice()
  }

  const [locationKeys, setLocationKeys] = useState<(string | undefined)[]>([])
  useEffect(() => {
    history.listen(() => {
      if (history.action === 'POP') {
        history.push(history.location.pathname)
      }
    })
  }, [locationKeys])

  useEffect(() => { 
    const identifier = match.params?.identifier

    if (!identifier) {
      constructorStore.setIdentifierValue('')
      constructorStore.setShowIdentifierType(false)
    }

    if (identifier && (identifier !== constructorStore.identifier)) {
      const identifier = match.params.identifier
      constructorStore.fetchConstructorData(identifier).then(() => {
        if (constructorStore.constructorLoading === ELoadingStatuses.SUCCEEDED) {
          getDataChartAndPrice()
          constructorStore.setIdentifierValue(identifier)
        }
      })
    }
  }, [match.params.identifier])

  const { isMobile, width } = useMobile()

  const referenceLines = [constructorStore.strikePrice, +numeral(constructorStore.oraclePrice).format('0[.]0')]

  const handlerSearchIdentifier = (identifier: string) => {
    constructorStore.setError(false)
    constructorStore.setDefaultOraclesValue()
    identifier && constructorStore.fetchConstructorData(identifier).then(() => {
      if (constructorStore.constructorLoading === ELoadingStatuses.SUCCEEDED) {
        history.push(`/derivative/${identifier}`)
      } else {
        constructorStore.setIdentifierValue(identifier)
      }
    })
  }

  const backToList = () => {
    constructorStore.setShowIdentifierType(false)
    constructorStore.setIdentifierValue('')
    history.replace('/derivative')
  }

  const renderPayoutChart = useMemo(() => {
    return (
      <>
        <div className="constructor-data-charts-wrapper">
          {!constructorStore.oraclePrice ? (
            <div className="oracles-price-loading">
              <Loading />
            </div>
          ) : (
            constructorStore.payoutChart?.data.length ? 
              <Chart
                theme={ETheme.DARK}
                data={constructorStore.payoutChart.data}
                height={'256px'}
                width={isMobile ? '400px' : '100%'}
                labelX={{ value: `Underlying price, ${constructorStore.constructorConfig.oracle.referenceAsset}`, position: 'insideBottom' }}
                labelY={{ value: `Gain/Loss, ${constructorStore.constructorConfig?.token?.title}`, angle: -90, position: 'insideLeft' }}
                // tickFormatterX={tickChanger}
                chartData2={(identifierType === 'LONG_POSITION' || identifierType === 'DERIVATIVE') ? { tooltipTitle: 'Long positions', tooltipSuffix: constructorStore.constructorConfig?.token?.title } : undefined}
                chartData1={(identifierType === 'SHORT_POSITION' || identifierType === 'DERIVATIVE') ? { tooltipTitle: 'Short position', tooltipSuffix: constructorStore.constructorConfig?.token?.title } : undefined}
                domainX={[constructorStore.payoutChart.minPrice, constructorStore.payoutChart.maxPrice]}
                domainY={[constructorStore.payoutChart.minYTotal, constructorStore.payoutChart.maxYTotal]}
                referenceLines={referenceLines}
                increaseDomainY={0.1}
              />
              : <div className={`constructor-data-charts-no-data color-scheme-${ETheme.DARK}`}>No data</div>
          )}
        </div>
      </>
    )
  }, [constructorStore.oraclePrice])

  const renderStrategyBlock = () => {
    return (
      <>
        { (identifierType === 'LONG_POSITION' || identifierType === 'DERIVATIVE') && 
      <StrategyDescription header={constructorStore.constructorConfig.synthetic.ticker === 'OPT-C' ? 'Option CALL buyer strategy' : 'Option PUT buyer strategy'} positionClass={EColor.SUCCESS} positionText="Long position">
        {constructorStore.constructorConfig.synthetic.ticker === 'OPT-C' 
          ? <div>
            <p>The long call option strategy presents an appealing way to gain long exposure in an asset for a fraction of the price of actually buying the asset itself. The long call option strategy is a bullish options trading strategy with a theoretical unlimited profit and a limited loss.</p> 
            <p>The maximum loss is always limited by option price and typically less than buying the underlying asset outright. Long calls have a theoretically unlimited profit.</p>
          </div>
          :<div>
            <p>The long put option is a strategy that capitalizes on increases in volatility and downward moves in the underlying asset. In addition to speculation, purchasing put options are a common way to hedge existing long positions from drastic declines. The main reason to buy puts in an underlying asset is speculation that the underlying asset is going to decrease in value.</p> 
            <p>Long put option strategy has a limited loss, which consists of option price and limited profit, which is capped at the underlying asset going to zero.</p>
          </div>
        }
        <p>Read more on <a href={constructorStore.constructorConfig.synthetic.ticker === 'OPT-C' ? 'https://www.investopedia.com/terms/c/calloption.asp' : 'https://www.investopedia.com/terms/p/putoption.asp'} target='_blank' rel="noreferrer" className={`text-${ETheme.DARK}`}>Investopedia.</a></p>
      </StrategyDescription> }

        { (identifierType === 'SHORT_POSITION' || identifierType === 'DERIVATIVE') && 
        <StrategyDescription header={constructorStore.constructorConfig.synthetic.ticker === 'OPT-C' ? 'Option CALL seller strategy' : 'Option PUT seller strategy'} positionClass={EColor.DANGER} positionText="Short position">
          {constructorStore.constructorConfig.synthetic.ticker === 'OPT-C' 
            ? <div>
              <p>The short call option strategy is primarily a trading strategy that capitalizes on premium decay, downward moves in volatility, and downward moves in the underlying asset.</p> 
              <p>Selling call options involved theoretically undefined risk. The maximum profit is limited and consists of premium.</p>
            </div>
            : <div>
              <p>Short call options are mainly used for covered calls by the option seller, or call options in which the seller already owns the underlying stock for their options. The call helps contain the losses that they might suffer if the trade does not go their way.</p> 
              <p>Short put option strategy has a limited profit, which consists of premium and limited losses, which is capped at the underlying asset going to zero.</p>
            </div>
          }
          <p>Read more on <a href={constructorStore.constructorConfig.synthetic.ticker === 'OPT-C' ? 'https://www.investopedia.com/terms/c/calloption.asp' : 'https://www.investopedia.com/terms/p/putoption.asp'} target='_blank' rel="noreferrer" className={`text-${ETheme.DARK}`}>Investopedia.</a></p>
        </StrategyDescription> }
      </>
    
    )
  }

  const getStrategyChartTabs = [
    {
      title: 'Payout chart',
      eventKey: 'payout-chart',
      content: renderPayoutChart
    },
    {
      title: 'Strategy',
      eventKey: 'strategy',
      content: renderStrategyBlock()
    }
  ]

  const renderConstructorBlock = () => {
    return (
      <div>
        <div className="ConstructorForm__wrapper constructor-form-bottom">
          <form>
            {/* Ticker */}
            <div className="ticker">
              <p className="ticker-title">Ticker:</p>
              <p className={`ticker-text color-scheme-${ETheme.DARK}`}>{constructorStore.ticker}</p>
            </div>

            <div className="selectors-wrapper__divided selectors-wrapper__row block-with-line">
              {/* Derivative type */}
              <SelectWithLabel text="Derivative type:">
                {constructorStore.derivativeType}
              </SelectWithLabel>
              {/* Chain */}
              <SelectWithLabel text="Chain:">
                <div className='selector-label'>
                  <div>
                    <img src={ArbitrumLogo} alt="opium" />
                  </div>
                  Arbitrum
                </div>
              </SelectWithLabel>
            </div>

            <div className="selectors-wrapper__divided selectors-wrapper__row block-with-line mt-3 pb-2">
              {/* Underlying */}
              <SelectWithLabel text="Underlying:">
                <div className="divided-block">
                  <div className='labels-wrapper'>
                    <Tooltip
                      theme={ETheme.DARK}
                      trigger='click'
                      label={''}
                      disabledBtn
                      className='address'
                      rootClose={true}
                      placement='bottom'
                      style={{ width: '281px' }}
                      component={
                        <div className='label-item label-wide'>
                          <div className='label-wrapper'>
  
                            <img src={constructorStore.constructorConfig.oracle.underlyingImage} alt="opium" />
                            {constructorStore.constructorConfig.oracle.underlyingAsset}</div>&nbsp;&nbsp;/ 
                          <div className='label-wrapper label-right'>
                            <img src={constructorStore.constructorConfig.oracle.referenceImage} alt="opium" />
                            {constructorStore.constructorConfig.oracle.referenceAsset} </div>
                        </div>
                      }
                      content={
                        <div className={`address-wrapper color-scheme-${ETheme.DARK}`}>
                          <div className="popover-title">Oracle address</div>
                          <div className='popover-content'>
                            <div className='popover-field'>
                              <input className={`color-scheme-${ETheme.DARK}`} value={shortenDerivativeHash(constructorStore.constructorConfig.oracle.address, 9, 8)} disabled />
                            </div>
                            <div className="popover-images-wrapper">
                              <a href={`https://arbiscan.io/address/${constructorStore.constructorConfig.oracle.address}`} target="_blank" rel="noreferrer"><img src={openLink} alt="opium" /></a>
                              <CopyIconText text={constructorStore.constructorConfig.oracle.address}/>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
              </SelectWithLabel>

              {/* Collateral */}
              <SelectWithLabel text="Collateral:">
                <div className="divided-block">
                  <div className='labels-wrapper'>
                    <Tooltip
                      theme={ETheme.DARK}
                      trigger='click'
                      label={''}
                      disabledBtn
                      rootClose={true}
                      className='address'
                      placement='bottom'
                      style={{ width: '281px' }}
                      component={
                        <div className='label-item'>
                          <div className='label-wrapper'>
                            <img src={constructorStore.constructorConfig.token.image} alt="opium" />
                            {constructorStore.constructorConfig.token.title}
                          </div>
                        </div>}
                      content={
                        <div className={`address-wrapper color-scheme-${ETheme.DARK}`}>
                          <div className="popover-title">Address</div>
                          <div className='popover-content'>
                            <div className='popover-field'>
                              <img className="popover-image-circle" src={constructorStore.constructorConfig.token.image} alt="Collateral" />
                              <input className={`color-scheme-${ETheme.DARK}`} value={shortenDerivativeHash(constructorStore.constructorConfig.token.address, 9, 8)} disabled />
                            </div>

                            <div className="popover-images-wrapper">
                              <a href={`https://arbiscan.io/address/${constructorStore.constructorConfig.token.address}`} target="_blank" rel="noreferrer"><img src={openLink} alt="opium" /></a>
                              <CopyIconText text={constructorStore.constructorConfig.token.address}/>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
              </SelectWithLabel>
            </div>

            <div className="block-with-line mt-3 pb-2">
              {/* Expiry date and time */}
              <SelectWithLabel text="Maturity:" direction="row">
                {constructorStore.expiry}
              </SelectWithLabel>

              <div className={`${constructorStore.isSpread ? '' : 'full-width'} selectors-wrapper__row`}>
                <SelectWithLabel text={`Strike price${constructorStore.isSpread ? ' low' : ''}:`} direction="row">
                  {Math.abs(+numeral(constructorStore.strikePrice).format('0[.]'))}
                </SelectWithLabel>
                {constructorStore.isSpread && <SelectWithLabel text="Strike price high:" direction="row">
                  {Math.abs(+numeral(constructorStore.strikePriceHigh).format('0[.]'))}
                </SelectWithLabel>}
              </div>

              <SelectWithLabel text="Collateralization:" direction="row">
                {constructorStore.collateralization}
              </SelectWithLabel>
            </div>


            <div className="selectors-wrapper__divided selectors-wrapper__row mt-3">
              <SelectWithLabel text="Total supply:">
                <div className='recieve-block'>
                  <div className='positions-address-wrapper'>
                    {constructorStore.amount} <div className='red-tokens tokens'>Long tokens</div>
                  </div>
                  <div className='positions-address-wrapper'>
                    {constructorStore.amount} <div className='green-tokens tokens'>Short tokens</div>
                  </div>
                </div>
              </SelectWithLabel>
              <SelectWithLabel text="Collateral:">
                <div className='collateral-block'>
                  {+constructorStore.buyerMargin.toFixed(2)} {constructorStore.constructorConfig.token.title}
                </div>
                
                <div className='collateral-block'>
                  {+constructorStore.sellerMargin.toFixed(2)} {constructorStore.constructorConfig.token.title}
                </div>

              </SelectWithLabel>
            </div>

            <div className="constructor-data-fee constructor-data-fee__top block-with-line ">
              <div className="constructor-data-fee-title">Derivative author fee (profit fee):</div>
              <div className="constructor-data-fee-text">
                {constructorStore.constructorConfig.synthetic.authorFee}%
                <Tooltip
                  theme={ETheme.DARK}
                  label={''}
                  disabledBtn
                  trigger='hover'
                  placement={isMobile ? 'auto' : 'top'}
                  content="Fee will be applied on position execution in case of positive payout"
                  component={<img src={info} alt="profit fee" />}
                />
              </div>
            </div>

            <ReceiveTabContent />

          </form>
        </div>
      </div>
    )
  }

  const constructorPage = () => {
    return (
      <div className="ConstructorPage__wrapper">
        <div className="ConstructorPage__left">

          {renderConstructorBlock()}
        </div>

        <div className="ConstructorPage__right">
          <div className="ConstructorData__wrapper">
            <div className='constructor-payout-chart-tooltip'>
              <Tooltip
                theme={ETheme.DARK}
                label={''}
                disabledBtn
                trigger='hover'
                placement={isMobile ? 'auto' : 'left'}
                content="The amount of payout to position/token holder on maturity. Depends on underlying price at that time"
                component={<img src={info} alt="Payout chart" />}
              />
            </div>
            
            <Tabs defaultActiveKey={'payout-chart'} id="opium-tabs-component" theme={ETheme.DARK} items={getStrategyChartTabs} />   

            <div className="constructor-data-prices">
              <div className="constructor-data-prices-item">
                <div className="constructor-data-prices-item-title">Current {constructorStore.constructorConfig.oracle.underlyingAsset} price:</div>
                <div className={`constructor-data-prices-item-text color-scheme-${ETheme.DARK}`}>{numeral(constructorStore.oraclePrice).format('0[.]0')} {constructorStore.constructorConfig.oracle.referenceAsset}</div>
              </div>
              <div className="constructor-data-prices-item">
                <div className="constructor-data-prices-item-title">Strike price:</div>
                <div className={`constructor-data-prices-item-text color-scheme-${ETheme.DARK}`}>{constructorStore.strikePrice} {constructorStore.isSpread && ` | ${constructorStore.strikePriceHigh}`} {constructorStore.constructorConfig.oracle.referenceAsset}</div>
              </div>
            </div>

            <div className='derivative-hash__wrapper'>
              <div className="derivative-hash">
                <p className="derivative-hash-title">Derivative hash / Blockchain ticker / Unique ID:</p>
                <div className="derivative-hash-content">
                  <p className={`derivative-hash-text color-scheme-${ETheme.DARK}`}>{shortenDerivativeHash(constructorStore.constructorConfig.derivativeHash, isMobile ? 14: 20, isMobile ? width < 1537 ? 8 : 12 : width < 1537 ? 8 : 17)}</p>
                  <CopyIconText text={constructorStore.constructorConfig.derivativeHash}/>
                </div>
              </div>

              <div className="derivative-hash derivaive-hash-bottom">
                <p className="derivative-hash-title">Derivative author:</p>
                <div className="derivative-hash-content">
                  <p className={`derivative-hash-text color-scheme-${ETheme.DARK}`}>{shortenDerivativeHash(constructorStore.constructorConfig.synthetic.authorAddress, isMobile ? 14: 20, isMobile ? width < 1537 ? 8 : 12 : width < 1537 ? 8 : 17)}</p>
                  <div className='derivative-hash-icon-wrap'>
                    <a href={`https://arbiscan.io/address/${constructorStore.constructorConfig.synthetic.authorAddress}`} target="_blank" rel="noreferrer" className="derivative-hash-link"><img src={openLink} alt="opium" /></a>
                    <CopyIconText text={constructorStore.constructorConfig.synthetic.authorAddress}/>
                  </div>
                </div>
              </div>
            </div> 

            <div className='derivative-hash__wrapper mt-2'>
              <div className="derivative-hash">
                <p className="derivative-hash-title">Long position address:</p>
                <div className="derivative-hash-content">
                  <p className={`derivative-hash-text green-bg color-scheme-${ETheme.DARK}`}>{shortenDerivativeHash(constructorStore.constructorConfig.longPosition.address, isMobile ? 14: 20, isMobile ? width < 1537 ? 8 : 12 : width < 1537 ? 8 : 17)}</p>
                  <a href={`https://arbiscan.io/address/${constructorStore.constructorConfig.longPosition.address}`} target="_blank" rel="noreferrer" className="derivative-hash-link"><img src={openLink} alt="opium" /></a>
                  <CopyIconText text={constructorStore.constructorConfig.longPosition.address}/>
                </div>
              </div>


              <div className="derivative-hash mt-2">
                <p className="derivative-hash-title">Short position address:</p>
                <div className="derivative-hash-content">
                  <p className={`derivative-hash-text red-bg color-scheme-${ETheme.DARK}`}>{shortenDerivativeHash(constructorStore.constructorConfig.shortPosition.address, isMobile ? 14: 20, isMobile ? width < 1537 ? 8 : 12 : width < 1537 ? 8 : 17)}</p>
                  <div className='derivative-hash-icon-wrap'>
                    <a href={`https://arbiscan.io/address/${constructorStore.constructorConfig.shortPosition.address}`} target="_blank" rel="noreferrer" className="derivative-hash-link"><img src={openLink} alt="opium" /></a>
                    <CopyIconText text={constructorStore.constructorConfig.shortPosition.address}/>
                  </div>
                </div>
              </div>
            </div>          

            <div className="mint-link-wrapper">
              <Button 
                theme={ETheme.DARK}
                variant={'secondary'}
                label={'Mint a new derivative '}
                className="mint-btn"
                onClick={(e: any) => e.stopPropagation()}
                size="sm"
                newTab
                href={'https://app.opium.finance/arb/constructor'}
              />
            </div>

          </div>
          {/* <div>
          <Chart
            theme={ETheme.DARK}
            height="235px"
            data={constructorStore.payoutChart.data}
            domainX={[constructorStore.payoutChart.minPrice, constructorStore.payoutChart.maxPrice]}
            domainY={[constructorStore.payoutChart.minYTotal, constructorStore.payoutChart.maxYTotal]}
            // labelX={{value: peerPoolProductParams.chartInfo?.PNL_CHART?.xLabel || peerPoolProductParams.referenceTitle, position: 'insideBottom'}}
            // labelY={{ value: peerPoolProductParams.chartInfo?.PNL_CHART?.yLabel || t('charts.labelY'), angle: -90, position: 'insideLeft' }}
            chartData1={{tooltipSuffix: '%', tooltipTitle: 'Tooltip 1'}}
            chartData2={{tooltipSuffix: '%', tooltipTitle: 'Tooltip 2'}}
          /> 
        </div> */}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='ConstructorPage__block-search'>      
        <div className="ConstructorPage__search">
          <div className='ConstructorPage__search-top'>
            {(match.params?.identifier && constructorStore.identifier) && <div className="directives-back-link" onClick={backToList}>
              Back to list
              <img src={iconBack} alt="" />
            </div>}
          </div>

        </div>
        {constructorStore.showIdentifierType ? constructorPage() : <DerivativeTable handlerSearchIdentifier={handlerSearchIdentifier} />}
      </div>
    </>
  )
}
export default observer(Constructor)
