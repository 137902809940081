import { ENetwork } from '@opiumteam/mobx-web3'
import axios, { AxiosInstance } from 'axios'

import opiumApiConfig from '../Constants/Config/opiumApi'
import { TStakingResponse } from '../Constants/Types/staking'
import {
  TChartPoint,
  TProduct,
  TAnalyticsChartPointResponse,
  TTokenAnalyticsChartResponse
} from '../Constants/Types/product'

import { EOraclesNetwork } from '../Constants/Types/oracles'
import { TOpiumVolume } from '../Constants/Types/volumes'
import { EDirection } from '../Utils/sorting'

const { ethEndpoint, binanceEndpoint, maticEndpoint, opiumWatchEndpoint } = opiumApiConfig

export class OpiumApi {
  private readonly _client: {
    [ENetwork.ETHEREUM]: AxiosInstance
    [ENetwork.BINANCE]: AxiosInstance
    [ENetwork.MATIC]: AxiosInstance
    [ENetwork.ARBITRUM]: AxiosInstance
  }
  private readonly _opiumWatchClient: AxiosInstance

  public constructor() {
    this._client = {
      [ENetwork.ETHEREUM]: axios.create({
        baseURL: ethEndpoint,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      [ENetwork.BINANCE]: axios.create({
        baseURL: binanceEndpoint,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      [ENetwork.MATIC]: axios.create({
        baseURL: maticEndpoint,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      [ENetwork.ARBITRUM]: axios.create({
        baseURL: maticEndpoint,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }
    this._opiumWatchClient = axios.create({
      baseURL: opiumWatchEndpoint,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  private _fetchFromAllPlatforms = (endpoint: string) =>
    Promise.allSettled([
      this._client[ENetwork.ETHEREUM].get(endpoint),
      this._client[ENetwork.BINANCE].get(endpoint),
      this._client[ENetwork.MATIC].get(endpoint),
    ])

  getStakings = async (): Promise<TStakingResponse[]> => {
    const results = await this._fetchFromAllPlatforms('/stakings')
    let stakings: TStakingResponse[] = []

    results.forEach((result) => {
      if (result.status === 'fulfilled') {
        const api = result.value.config.baseURL
        let network: ENetwork

        switch (api) {
          case ethEndpoint:
            network = ENetwork.ETHEREUM
            break
          case maticEndpoint:
            network = ENetwork.MATIC
            break
          case binanceEndpoint:
            network = ENetwork.BINANCE
            break
        }

        const stakingsResponse = result.value.data
        stakingsResponse.forEach((staking: TStakingResponse) => {
          stakings.push({ ...staking, network })
        })
      }
    })
    return stakings
  }

  getProducts = async (): Promise<TProduct[]> => {
    const results = await this._fetchFromAllPlatforms('/products')
    let products: TProduct[] = []

    results.forEach((result) => {
      if (result.status === 'fulfilled') {
        products = products.concat(result.value.data)
      }
    })
    return products
  }

  getProductChartDataById = async (
    productId: string,
    network: ENetwork
  ): Promise<TChartPoint[]> => {
    const response = await this._client[network].get(`/products/${productId}/payoutChart`)
    return response.data
  }

  getOpiumVolumes = async (): Promise<TOpiumVolume[]> => {
    const response = await this._opiumWatchClient.get('/opium/volumes')
    return response.data
  }

  getAnalyticsChart = async (
    poolAddress: string,
    network: ENetwork
  ): Promise<TAnalyticsChartPointResponse[]> => {
    const response = await this._client[network].get(`/products/${poolAddress}/analyticsChart`)
    return response.data
  }

  getTokenAnalytics = async (poolAddress: string, marginTitle: string, network: ENetwork): Promise<TTokenAnalyticsChartResponse> => {
    const response = await this._opiumWatchClient.get(`/opium/analytics/${poolAddress}?network=${network}&marginToken=${marginTitle}`)
    return response.data
  }

  getOracles = async (page: number, asset?: string, source?: string, chain?: string, sortKey?: string, direction?: EDirection): Promise<any> => {
    const response = await this._opiumWatchClient.get(`/oracles?page=${page ? page : 1}${asset ? '&asset=' + asset : ''}${source ? '&source=' + source : ''}${chain ? '&chain=' + chain : ''}${sortKey ? '&sort=' + sortKey : ''}${direction ? '&direction=' + direction : ''}`)
    return response.data
  }
}

export default new OpiumApi()
